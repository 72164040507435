import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import type { PlanDetails } from '@customers-api-client'
import { Accordion } from '@customers-ui'

interface FaqsProps {
  planDetails: PlanDetails
  className?: string
}

const Faqs = ({ planDetails, className }: FaqsProps): JSX.Element => {
  const { faqHeader, faqTitle1, faqBody1, faqTitle2, faqBody2, faqTitle3, faqBody3 } = planDetails.planDetails

  return (
    <Section className={className} data-cy="plan-details-faqs">
      <Header>{faqHeader}</Header>
      <Accordion
        list={[
          [faqTitle1, faqBody1],
          [faqTitle2, faqBody2],
          [faqTitle3, faqBody3],
        ]}
      />
    </Section>
  )
}

const Section = styled.section({
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '80px 0 0 0',
  [bp.maxWidthMd]: {
    padding: '60px 0 0',
  },
})

const Header = styled.h2({
  fontWeight: 400,
  fontSize: 28,
  lineHeight: '36px',
  margin: '0px 0 32px',
  [bp.maxWidthMd]: {
    fontSize: 20,
    lineHeight: '28px',
  },
})

export { Faqs }
