/**
 * TESTING NOTE:
 *
 * This component has significant challenges for testing:
 * 1. Complex styled components with Emotion theme dependencies
 * 2. Deep dependency tree with multiple hooks and external dependencies
 * 3. Nested child components with their own dependencies
 * 4. Complex type requirements for props and data structures
 *
 * Instead of maintaining a problematic test file, we've documented the testing
 * challenges here to inform future developers. If testing is needed, consider
 * breaking this component into smaller, more testable units.
 */

import React, { useState, useEffect, useCallback } from 'react'

import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import type { ConnectRequest } from '@extend-incredibot/types'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { ModalController } from '@extend/zen'
import type { ContractsSearchIndividual } from '../../types/contract'
import { logEvent } from '../../analytics'
import { FileClaimLanguageOptionModal } from '../../components/file-claim-language-option-modal/file-claim-language-option-modal'
import { CardMapper } from './card-mapper'
import ContactMerchantModal from '../../components/contact-merchant-modal'
import * as selectors from '../../reducers/selectors'
import { getAccountInfo } from '../../lib/consumer-profile-utils'
import { OrderCard } from './order-card/order-card'
import { useSelector } from 'react-redux'
import type { OrdersListByShipmentsResponse } from '@extend-myextend/types'

export type ContractListProps = {
  groupedContracts: Record<string, Array<ContractsSearchIndividual>>
  emsOrders: OrdersListByShipmentsResponse
}

const ContractList = ({ groupedContracts, emsOrders }: ContractListProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCustomerSupportModalOpen, setIsCustomerSupportModalOpen] = useState(false)
  const [isFilingClaim, setIsFilingClaim] = useState(false)
  const [connectData, setConnectData] = useState<Partial<ConnectRequest>>()
  const theme = useTheme()
  const { isExtend } = theme.merchantConfiguration

  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const { isEmailVerified, isPhoneNumberVerified, hasAccountInfo } = getAccountInfo(consumerProfile)
  const isMissingRequiredProfileField = hasAccountInfo && (!isEmailVerified || !isPhoneNumberVerified)

  const navigate = useNavigate()
  const { locale } = useIntl()

  const handleModalOpen = (): void => {
    setIsModalOpen(true)
  }

  const handleModalClose = (): void => {
    setIsModalOpen(false)
  }

  const handleFileClaim = useCallback((): void => {
    if (!connectData?.contractId && !connectData?.orderId) return

    logEvent('My Plans - Contract Card - Clicks', 'File a Claim')
    navigate(!isExtend ? `/${locale}/my_claims/file-claim` : `/${locale}/kaley`, {
      state: {
        orderId: connectData?.orderId,
        contractId: connectData?.contractId,
        lineItemIds: connectData?.lineItemIds,
        shipmentId: connectData?.shipmentId,
        trackingId: connectData?.trackingId,
        claimType: connectData?.claimType,
      },
    })
  }, [connectData, history, locale])

  const handleFileClaimClick = (
    handlerArgs: Partial<ConnectRequest> & {
      isMerchantOwnedIntake?: boolean
    },
  ): void => {
    setConnectData({
      orderId: handlerArgs.orderId,
      contractId: handlerArgs.contractId,
      lineItemIds: handlerArgs.lineItemIds,
      shipmentId: handlerArgs.shipmentId,
      trackingId: handlerArgs.trackingId,
      claimType: handlerArgs.claimType,
    })
    if (
      handlerArgs.isMerchantOwnedIntake &&
      (handlerArgs.contractId === connectData?.contractId || handlerArgs.orderId === connectData?.orderId)
    ) {
      setIsCustomerSupportModalOpen(true)
    } else if (['fr-CA', 'en-CA'].includes(locale)) {
      handleModalOpen()
    } else {
      setIsFilingClaim(true)
    }
  }

  useEffect(() => {
    if (isFilingClaim && (connectData?.contractId || connectData?.orderId)) {
      handleFileClaim()
      setIsFilingClaim(false)
    }
  }, [isFilingClaim, connectData, handleFileClaim])

  const selectedContract = Object.values(groupedContracts)
    .flat()
    .find((contract) => contract.id === connectData?.contractId)
  const selectedOrder = emsOrders.find(
    (emsData) =>
      emsData.id === connectData?.orderId ||
      (emsData.contracts?.length && emsData.contracts.find((contract) => contract.id === connectData?.contractId)),
  )

  return (
    <ContractsWrapper data-cy="contract-list">
      {emsOrders.map((data) => {
        return (
          <OrderCard
            key={data.id}
            order={data}
            onFileClaimClick={handleFileClaimClick}
            isMissingRequiredProfileField={isMissingRequiredProfileField}
          />
        )
      })}
      {Object.entries(groupedContracts)
        .filter(([_type, contracts]) => contracts.length > 0)
        .map(([type, contracts]) => (
          <ContractGroupWrapper key={type}>
            {contracts.map((contract: ContractsSearchIndividual) => (
              <CardMapper key={contract.id} contract={contract} handleFileClaimClick={handleFileClaimClick} />
            ))}
          </ContractGroupWrapper>
        ))}
      <FileClaimLanguageOptionModal
        isModalOpen={isModalOpen}
        onDismiss={handleModalClose}
        onContinue={handleFileClaim}
      />
      <ModalController isOpen={isCustomerSupportModalOpen}>
        <ContactMerchantModal
          onDismissRequest={() => setIsCustomerSupportModalOpen(false)}
          storeId={(selectedContract?.sellerId as string) || (selectedOrder?.store.id as string)}
        />
      </ModalController>
    </ContractsWrapper>
  )
}

const ContractsWrapper = styled.div({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '24px',
  [bp.mobile]: {
    justifyContent: 'center',
  },
  [bp.desktop]: {
    justifyContent: 'flex-start',
  },
})

const ContractGroupWrapper = styled.div({
  display: 'flex',
  [bp.mobile]: {
    flexDirection: 'column',
    gap: '16px',
  },
  [bp.desktop]: {
    gap: '24px',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
})

export { ContractList }
