import { useGetSessionLogsListQuery, getRTKQueryErrorMessage } from '@customers-api-rtk-query'
import { transformSessionLogs } from '../lib/helper-functions'
import type { TransformedLog } from '../lib/helper-functions'

interface UseClaimSessionLogsResult {
  logs: TransformedLog[]
  isLoading: boolean
  isError: boolean
  error?: string
}

export function useClaimSessionLogs(sessionId: string): UseClaimSessionLogsResult {
  const {
    data: sessionLogs,
    isLoading,
    isError,
    error,
  } = useGetSessionLogsListQuery(sessionId, {
    selectFromResult: (response) => {
      const sortedData = response.data ? [...response.data].sort((a, b) => a.createdAt - b.createdAt) : []

      return {
        ...response,
        data: transformSessionLogs(sortedData),
      }
    },
  })

  return {
    logs: sessionLogs || [],
    isLoading,
    isError,
    error: error ? getRTKQueryErrorMessage(error) : undefined,
  }
} 