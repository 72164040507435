import React from 'react'
import styled from '@emotion/styled'
import { COLOR, Text } from '@customers-ui'
import { bp } from '@extend/client-helpers'
import type { PlanDetails, Plan } from '@customers-api-client'
import { svgs } from '../../../lib/assets'
import { useGetPlanQuery } from '@customers-api-rtk-query'
import { usePlanTermsData } from '../../../hooks'
import { PlanTermsList } from '../plan-terms/plan-terms-list'

interface FinePrintBannerProps {
  planDetails: PlanDetails
}

const FinePrintBanner = ({ planDetails }: FinePrintBannerProps): JSX.Element => {
  const { data: plan } = useGetPlanQuery({ planId: planDetails.planId })

  const { terms } = usePlanTermsData({ plan: plan as Plan })
  return (
    <Banner>
      <TermsAndConditionsHeader>{planDetails.planDetails.finePrintHeader}</TermsAndConditionsHeader>
      <TermsAndConditionsWrapper>
        <TermsAndConditions>
          {Array.isArray(terms) && terms.length > 0 && <PlanTermsList terms={terms} />}
          {!Array.isArray(terms) && (
            <StyledButton onClick={() => terms && window.open(terms, '_blank')} type="button">
              {planDetails.planDetails.finePrintTermsAndConditions}
              <svgs.External />
            </StyledButton>
          )}
        </TermsAndConditions>
      </TermsAndConditionsWrapper>
    </Banner>
  )
}

const StyledButton = styled.button({
  display: 'flex',
  color: COLOR.BLUE[800],
  flexDirection: 'row',
  fontSize: 16,
  background: 'none',
  border: 'none',
  padding: 0,
  [bp.md]: {
    fontSize: 20,
  },
  '&:visited': {
    color: COLOR.BLUE[800],
  },
  cursor: 'pointer',
  '& svg': {
    display: 'inline',
    height: 11,
    width: 11,
    marginTop: 6,
    transform: 'translateX(6px)',
    [bp.md]: {
      marginTop: 11,
    },
  },
})

const Banner = styled.section({
  backgroundColor: COLOR.NEUTRAL[100],
  display: 'block',
  margin: 0,
  marginBottom: 32,
  marginLeft: -32,
  padding: 32,
  textAlign: 'center',
  width: '100vw',
  [bp.lg]: {
    marginBottom: 95,
    marginLeft: -40,
  },
  [bp.xl]: {
    marginLeft: -112,
  },
  [bp.maxWidth]: {
    width: '100vw',
    marginLeft: 0,
  },
})

const TermsAndConditionsHeader = styled.h4({
  fontSize: 18,
  margin: '0 0 8px 0',
  [bp.md]: {
    margin: '0 0 16px 0',
    fontSize: 26,
  },
})

const TermsAndConditionsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})

const TermsAndConditions = styled(Text)({
  display: 'flex',
  margin: 0,
  fontSize: 16,
  [bp.md]: {
    fontSize: 20,
  },
})

export { FinePrintBanner }
