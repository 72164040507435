import { useSubmitClaimMutation, getRTKQueryErrorMessage } from '@customers-api-rtk-query'
import type { Claim } from '../types/claim'

interface UseClaimSubmissionResult {
  submitClaim: (claimId: string) => Promise<void>
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  error?: string
  claim?: Claim
}

export function useClaimSubmission(): UseClaimSubmissionResult {
  const [
    submit,
    { isLoading, isSuccess, isError, error, data: updatedClaim },
  ] = useSubmitClaimMutation()

  const submitClaim = async (claimId: string): Promise<void> => {
    await submit({ claimId })
  }

  return {
    submitClaim,
    isLoading,
    isSuccess,
    isError,
    error: error ? getRTKQueryErrorMessage(error) : undefined,
    claim: updatedClaim as Claim | undefined,
  }
} 