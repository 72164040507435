import { useState, useEffect } from 'react'
import type { ClaimPhotosGetResponse } from '@customers-api-client'
import type { Claim } from '../../../types/claim'
import { hasUnmetPhotoRequirements } from '../../../lib/helper-functions'
import { useTimer } from '../../../hooks/use-timer'

interface UseClaimResultTimerConfig {
  isModalOpen: boolean
  claim?: Claim
  isPollingClaim?: boolean
  photoData?: ClaimPhotosGetResponse
  onTimerComplete: () => void
}

interface UseClaimResultTimerResult {
  secondsLeft: number
  isTimerRunning: boolean
  showPhotoUploadReview: boolean
  shouldRunAnalyzingClaimTimer: boolean
}

const ANALYZING_TIMER = 5
const POLLING_TIMER = 1
const RESULT_TIMER = 5

export function useClaimResultTimer({
  isModalOpen,
  claim,
  isPollingClaim = false,
  photoData,
  onTimerComplete,
}: UseClaimResultTimerConfig): UseClaimResultTimerResult {
  const [showPhotoUploadReview, setShowPhotoUploadReview] = useState<boolean>(false)
  const [shouldRunAnalyzingClaimTimer, setShouldRunAnalyzingClaimTimer] = useState<boolean>(true)

  const doesClaimHaveUnmetPhotoRequirements = !isPollingClaim && claim && hasUnmetPhotoRequirements(claim, photoData)
  const hasAdjudicatedClaim = !!claim && claim?.status !== 'pending_adjudication'
  const isReturnResolution = claim?.type === 'return_resolution'

  const handleTimerComplete = () => {
    // if the claim has unmet photo requirements and we're not already displaying the review state
    if (!showPhotoUploadReview && doesClaimHaveUnmetPhotoRequirements) {
      setShouldRunAnalyzingClaimTimer(false)
      setShowPhotoUploadReview(true)
      timer.resetTimer(ANALYZING_TIMER)
      timer.startTimer()
    }
    // if the claim has not been adjudicated and we are still running the analyzing state
    else if (shouldRunAnalyzingClaimTimer && !hasAdjudicatedClaim) {
      timer.resetTimer(POLLING_TIMER)
      timer.startTimer()
    }
    // if we're still in analyzing state and it's a return resolution, redirect immediately
    else if (shouldRunAnalyzingClaimTimer && isReturnResolution) {
      onTimerComplete()
    }
    // if the claim has been adjudicated and we are showing the analyzing state
    else if (shouldRunAnalyzingClaimTimer) {
      timer.resetTimer(RESULT_TIMER)
      timer.startTimer()
      setShouldRunAnalyzingClaimTimer(false)
    }
    // once we reset the timer the final time, this countdown will determine when we redirect the user to the my claims page
    else {
      onTimerComplete()
    }
  }

  const timer = useTimer({
    initialSeconds: ANALYZING_TIMER,
    isRunning: isModalOpen,
    onComplete: handleTimerComplete,
  })

  // Start timer when modal opens
  useEffect(() => {
    if (isModalOpen) {
      timer.startTimer()
    }
  }, [isModalOpen])

  useEffect(() => {
    if (
      hasAdjudicatedClaim &&
      !doesClaimHaveUnmetPhotoRequirements &&
      shouldRunAnalyzingClaimTimer
    ) {
      timer.resetTimer(RESULT_TIMER)
      timer.startTimer()
      setShouldRunAnalyzingClaimTimer(false)
    }
  }, [claim, hasAdjudicatedClaim, doesClaimHaveUnmetPhotoRequirements, shouldRunAnalyzingClaimTimer])

  return {
    secondsLeft: timer.secondsLeft,
    isTimerRunning: timer.isTimerRunning,
    showPhotoUploadReview,
    shouldRunAnalyzingClaimTimer,
  }
}
