import React from 'react'
import { Dots, COLOR, Timeline, TimelineItem } from '@extend/zen'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import { getClaimPreparingDescription, getClaimPreparingStatusMessage } from '../../../types/constants'

interface ClaimPreparingStateProps {
  entityName?: 'claim' | 'return'
}

export const ClaimPreparingState = ({ entityName = 'claim' }: ClaimPreparingStateProps): JSX.Element => {
  return (
    <ClaimLoading data-cy="claim-preparing">
      <TimelineContainer>
        <Timeline>
          <TimelineItem
            data-cy="claim-preparing-state-description"
            label={getClaimPreparingDescription(entityName)}
            state="upcoming"
          />
        </Timeline>
      </TimelineContainer>

      <ClaimLoadingContainer>
        <Dots color={COLOR.BLUE[700]} size="sm" speed="slow" />
        <ClaimStateMessage data-cy="claim-preparing-state-message">
          {getClaimPreparingStatusMessage(entityName)}
        </ClaimStateMessage>
      </ClaimLoadingContainer>
    </ClaimLoading>
  )
}

const ClaimLoading = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [bp.mobile]: {
    alignItems: 'center',
  },
  [bp.desktop]: {
    alignItems: 'flex-start',
  },
  gap: 24,
  alignSelf: 'stretch',
})

const TimelineContainer = styled.div({
  display: 'block',
  alignItems: 'center',
  gap: 8,
  alignSelf: 'stretch',
})
const ClaimLoadingContainer = styled.button({
  padding: `8px var(--broderRadius_consumer, 20px) 8px 16px`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: `var(--badge-border-radius, 4px);`,
  borderRadius: `var(--button-border-radius, 36px);`,
})

const ClaimStateMessage = styled.div({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'center',
  color: COLOR.BLUE[700],
})
