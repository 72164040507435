import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@extend/zen'
import { useNavigate } from 'react-router-dom'
import { useContactSupport } from '../../../hooks/use-contact-support'

export const VirtualCardSupport = (): JSX.Element => {
  const navigate = useNavigate()
  const { contactSupport } = useContactSupport()

  return (
    <VCSupportContainer>
      <VCSupportContent>
        <div>
          <Header>Have questions?</Header>
          <h3>Contact support for any questions about the cardholder agreement or how to get your fulfillment.</h3>
        </div>
        <Button
          text="Take me back to the cardholder agreement"
          fillContainer
          data-cy="back-to-vc-btn"
          onClick={() => navigate(-1)}
        />
        <Button
          text="Contact Support"
          fillContainer
          data-cy="contact-support-btn"
          emphasis="medium"
          onClick={contactSupport}
        />
      </VCSupportContent>
    </VCSupportContainer>
  )
}

const VCSupportContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const VCSupportContent = styled.div({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  gap: '20px',
  maxWidth: '476px',
  padding: '0 8px',
})

const Header = styled.h1({
  fontSize: '44px',
  fontWeight: 600,
})
