import React from 'react'

export const DamagedPackage = (): JSX.Element => {
  return (
    <svg width="109" height="97" viewBox="0 0 109 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.9438 83.9952L44.5 85.5498V3.45023L83.0667 14.4684V32.95C83.0667 33.7688 83.7312 34.4333 84.55 34.4333C85.3688 34.4333 86.0333 33.7688 86.0333 32.95V13.35C86.0333 12.6884 85.5943 12.107 84.9564 11.923L43.4231 0.0563667C43.3816 0.0445 43.3371 0.0652667 43.2955 0.0563667C43.2036 0.0385667 43.1146 0 43.0167 0C42.9188 0 42.8298 0.0385667 42.7378 0.0563667C42.6933 0.0652667 42.6488 0.0445 42.6073 0.0563667L1.07393 11.923C0.439067 12.107 0 12.6884 0 13.35V75.65C0 76.3116 0.439067 76.893 1.07393 77.077L42.6073 88.9436C42.7408 88.9822 42.8802 89 43.0167 89C43.1531 89 43.2926 88.9822 43.4231 88.9466L50.7597 86.8492C51.5488 86.6237 52.0027 85.8019 51.7772 85.0158C51.5518 84.2296 50.7419 83.7727 49.9438 83.9952ZM14.8333 11.0775L26.697 7.68663V42.3996L25.2137 43.8829L21.8139 40.4831C21.2354 39.9046 20.295 39.9046 19.7165 40.4831L16.3167 43.8859L14.8333 42.4026V11.0775ZM41.5333 85.5498L2.96667 74.5316V14.4684L11.8667 11.926V43.0167C11.8667 43.4112 12.0239 43.788 12.3028 44.0639L15.2694 47.0306C15.8479 47.6091 16.7884 47.6091 17.3669 47.0306L20.7667 43.6308L24.1665 47.0335C24.4572 47.3213 24.8369 47.4667 25.2167 47.4667C25.5964 47.4667 25.9761 47.3213 26.2639 47.0335L29.2306 44.0669C29.5065 43.788 29.6637 43.4112 29.6637 43.0167V6.84113L41.5333 3.45023V85.5498Z"
        fill="#2146DE"
      />
      <path
        d="M82.4997 89.5719C81.2278 89.5719 80.1955 88.5373 80.1955 87.2676C80.1955 85.998 81.2278 84.9634 82.4997 84.9634C83.7717 84.9634 84.804 85.998 84.804 87.2676C84.804 88.5373 83.7717 89.5719 82.4997 89.5719ZM82.4997 82.6591C81.8637 82.6591 81.3476 82.143 81.3476 81.507V63.9947C81.3476 63.3587 81.8637 62.8425 82.4997 62.8425C83.1357 62.8425 83.6518 63.3587 83.6518 63.9947V81.507C83.6518 82.143 83.1357 82.6591 82.4997 82.6591Z"
        fill="#27AEE4"
      />
      <path
        d="M107.846 96.9454H57.1529C56.7543 96.9454 56.3833 96.738 56.1713 96.3993C55.9616 96.0606 55.9432 95.6343 56.1229 95.2794L81.4697 44.5859C81.8614 43.8047 83.1403 43.8047 83.532 44.5859L108.879 95.2794C109.056 95.6366 109.038 96.0606 108.83 96.3993C108.616 96.738 108.245 96.9454 107.846 96.9454ZM59.0171 94.6412H105.985L82.4997 47.6759L59.0171 94.6412Z"
        fill="#27AEE4"
      />
    </svg>
  )
}
