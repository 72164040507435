import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { getRTKQueryErrorMessage, useTrackingQuery } from '@customers-api-rtk-query'
import type { TrackingRequest } from '@extend-myextend/types'
import { ContentSection, PageWrapper, SplashScreen } from '../../components/common'
import WismoContent from './components/wismo-content'
import { customLogger } from '@extend/client-helpers'
import ErrorPage from '@src/components/common/error-page'

const Tracking = () => {
  const location = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const orderId = queryParams.get('orderId') || ''
  const trackingNumber = queryParams.get('trackingNumber') || ''
  const carrierCode: TrackingRequest['carrierCode'] = (queryParams.get('carrierCode') ||
    '') as TrackingRequest['carrierCode']
  const transactionId = queryParams.get('transactionId') || ''

  const {
    data: trackingData,
    isLoading: isTrackingLoading,
    isError,
    error,
  } = useTrackingQuery(
    {
      trackingNumber,
      carrierCode,
      orderId: orderId ? orderId : undefined,
      transactionId: transactionId ? transactionId : undefined,
    },
    { skip: !trackingNumber || !carrierCode || (!orderId && !transactionId) },
  )

  if (isTrackingLoading) return <SplashScreen data-cy="splash-screen" />

  if (isError) {
    const errorMessage = getRTKQueryErrorMessage(error)

    customLogger.warn(`[Err: Tracking Page] - ${errorMessage}`, {
      isError: isError || 'No RequestError',
    })

    return <ErrorPage />
  }

  return trackingData ? (
    <PageWrapper>
      <ContentSection>
        <WismoContent trackingData={trackingData} carrierCode={carrierCode} data-cy="wismo-content" />
      </ContentSection>
    </PageWrapper>
  ) : (
    <ErrorPage />
  )
}

export { Tracking }
