import React from 'react'
import { Stack, Icon, ArrowDropDown, ArrowDropDownClose, Accordion, COLOR } from '@extend/zen'
import { currency } from '@extend/client-helpers'
import type { Claim } from '@customers-api-client'
import styled from '@emotion/styled'

interface PaymentDetailsProps {
  claim: Claim
  displayPaymentDetails: boolean
  setDisplayPaymentDetails: (show: boolean) => void
}

export const ReturnPaymentDetails = ({
  claim,
  displayPaymentDetails,
  setDisplayPaymentDetails,
}: PaymentDetailsProps): JSX.Element => {
  const { totalValue, tax, returnCost } = claim
  const totalProductAmount = (totalValue?.amount ?? 0) - (tax?.amount ?? 0) + (returnCost?.amount ?? 0)

  return (
    <PaymentContainer>
      <PaymentHeader onClick={() => setDisplayPaymentDetails(!displayPaymentDetails)} data-cy="payment-header">
        <Stack isRow align="center">
          <Icon icon={displayPaymentDetails ? ArrowDropDownClose : ArrowDropDown} />
          <FieldNameText>Refund total:</FieldNameText> &nbsp;
          <span className="amount" data-cy="total-amount">
            {currency.format(totalValue?.amount ?? 0)}
          </span>
        </Stack>
      </PaymentHeader>
      <Accordion isExpanded={displayPaymentDetails}>
        <PaymentDetailsContainer>
          <p data-cy="product-price">
            Product price: {currency.format(totalProductAmount > 0 ? totalProductAmount : 0)}
          </p>
          <p data-cy="return-fee">Return fee: {currency.format(returnCost?.amount ?? 0)}</p>
        </PaymentDetailsContainer>
      </Accordion>
    </PaymentContainer>
  )
}

const PaymentContainer = styled.div({
  color: COLOR.NEUTRAL[600],
  fontWeight: 500,
  fontSize: 13,
})

const PaymentHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontWeight: 600,
  '.amount': {
    color: COLOR.NEUTRAL[1000],
    fontWeight: 500,
    fontSize: 15,
  },
  cursor: 'pointer',
})

const PaymentDetailsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  marginTop: 5,
  p: {
    paddingLeft: 24,
  },
})

const FieldNameText = styled.div({
  fontWeight: 800,
  fontSize: 14,
  color: COLOR.NEUTRAL[600],
})
