import React, { Suspense, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { useTheme } from '@emotion/react'
import { TrackingProvider } from '../lib/client-analytics'
import { SplashScreen } from '../components/common'
import { useAnalytics, AnalyticsWrapper } from '../analytics'
import { Routes as ConsumerRoutes } from './routes'
import type { SubRouterProps } from './types'
import { determineLocale } from './utils'
import frenchCanadaMessages from '../translations/compiled/fr-CA.json'
import englishCanadaMessages from '../translations/compiled/en-CA.json'
import englishUSmessages from '../translations/compiled/en-US.json'
import { MyExtendLoader } from '../devlink'
import '../devlink/global.css'
import { AppLayout } from '../components/common/layout/app-layout'
import { useLogOutExpiredTokens } from '../hooks'

const ANIMATION_TIMEOUT = 100

const Component = ({ isLoggedIn }: SubRouterProps): JSX.Element => {
  useLogOutExpiredTokens()
  const { initialized } = useAnalytics()
  const { pathname } = useLocation()
  const locale = determineLocale(pathname)
  const theme = useTheme()
  const { isExtend } = theme.merchantConfiguration

  const [messages, setMessages] = useState({})
  useEffect(() => {
    if (!Object.keys(messages).length) {
      const loadLocaleData = (): void => {
        switch (locale) {
          case 'fr-CA':
            setMessages(frenchCanadaMessages)
            break
          case 'en-CA':
            setMessages(englishCanadaMessages)
            break
          default:
            setMessages(englishUSmessages)
            break
        }
      }
      loadLocaleData()
    }
  }, [locale, messages])

  const isAuthRoute = pathname.includes('/authentication')
  const [shouldLoadSubRouter, setShouldLoadSubRouter] = useState<boolean>(!isAuthRoute)

  // Hides the router until the load animation starts to prevent flickering
  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldLoadSubRouter(true)
    }, ANIMATION_TIMEOUT)

    return () => clearTimeout(timer)
  }, [])

  const subRouter = (
    <TrackingProvider data-cy="tracking-provider">
      <IntlProvider locale={locale} defaultLocale="en-US" messages={messages} data-cy="intl-provider">
        <Suspense fallback={<SplashScreen data-cy="splash-screen" />} data-cy="suspense">
          <AppLayout isLoggedIn={isLoggedIn} locale={locale} data-cy="header">
            <AnalyticsWrapper initialized={initialized} data-cy="analytics-wrapper" data-locale={locale}>
              <ConsumerRoutes isLoggedIn={isLoggedIn} locale={locale} />
            </AnalyticsWrapper>
          </AppLayout>
        </Suspense>
      </IntlProvider>
    </TrackingProvider>
  )

  return (
    <>
      {isAuthRoute && isExtend && <MyExtendLoader data-cy="my-extend-loader" />}
      {shouldLoadSubRouter && subRouter}
    </>
  )
}

export { Component as SubRouter }
