import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import { animated, useTransition } from 'react-spring'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { MyExtendAuthForm } from './myextend-auth-form'
import { getItem, removeItem } from '../../store/persistence'
import { useTheme } from '@emotion/react'
import { useDispatch } from 'react-redux'
import { setAccessToken, setEmailToken, setPhoneToken } from '../../store/slices/my-extend'
import { LoginManagement } from './login-management'

export interface UserInfo {
  email: string
  phoneNumber: string
}

const MyExtendAuthentication: FC = () => {
  const ANIMATION_DURATION = 250
  const navigate = useNavigate()
  const [userInfo, setUserInfo] = useState<UserInfo>({ email: '', phoneNumber: '' })
  const [isReset, setIsReset] = useState<boolean>(false)
  const theme = useTheme()

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)

  const email = queryParams.get('email') || ''
  const phoneNumber = queryParams.get('mobilePhoneNumber') || ''
  const rawLoginMethods = queryParams.get('loginMethods') || ''
  const loginMethods = rawLoginMethods ? rawLoginMethods.split(',') : []
  const isRestrictedLogin = loginMethods.length === 1 || !!email || !!phoneNumber

  /**
   * WARNING: ugly code ahead!
   * The 'position: absolute' of the animation was breaking the page layout.
   * Our hack here is to conditionally inject 'position: absolute' if the animation should be displayed, otherwise inject 'position: relative'.
   * We use this state var to control which position style should be used based on whether the submit button that triggers the animation is clicked.
   */
  const [shouldDisplayFormAnimation, setShouldDisplayFormAnimation] = useState<boolean>(false)

  const intl = useIntl()

  const dispatch = useDispatch()

  // Clear tokens on mount
  useEffect(() => {
    dispatch(setAccessToken(''))
    dispatch(setEmailToken(''))
    dispatch(setPhoneToken(''))
  }, [dispatch])

  const formTransitionProps = useTransition(!userInfo.email && !userInfo.phoneNumber, null, {
    config: { duration: ANIMATION_DURATION, mass: 5, friction: 120, tension: 120 },
    from: {
      transform: isReset ? 'translateX(50%)' : 'translateX(0%)',
      opacity: 1,
      position: 'absolute',
    },
    leave: { transform: 'translateX(-50%)', opacity: 0 },
    enter: { transform: 'translateX(0%)', opacity: 1 },
    update: { transform: 'translateX(0%)', opacity: 1 },
  })
  const confirmationTransitionProps = useTransition(userInfo.email || userInfo.phoneNumber, null, {
    config: { duration: ANIMATION_DURATION },
    from: { transform: 'translateX(50%)', opacity: 0, position: 'absolute' },
    enter: { transform: 'translateX(0)', opacity: 1 },
    leave: { transform: 'translateX(-50%)', opacity: 0 },
    reset: true,
    update: { transform: 'translateX(0)', opacity: 1 },
  })

  const handleSubmit = async (info: UserInfo): Promise<void> => {
    // ready to animate
    setShouldDisplayFormAnimation(true)

    setUserInfo(info)
    setIsReset(false)

    // A simple toggle false of state var here is too fast because the animation has a duration of 250 ms.
    setTimeout(() => setShouldDisplayFormAnimation(false), ANIMATION_DURATION + 250)
  }

  const handleResetForm = async (): Promise<void> => {
    setUserInfo({ email: '', phoneNumber: '' })
    setIsReset(true)
  }

  const handleRedirect = (): void => {
    const prevLocation = getItem('prevLocation')
    if (prevLocation) {
      navigate(`${prevLocation}`)
      removeItem('prevLocation')
    } else {
      navigate(`/${intl.locale}/my_plans`)
    }
  }

  const form = formTransitionProps.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          key={key}
          // if you're confused, see comment on state var above
          style={{ ...props, position: shouldDisplayFormAnimation ? 'absolute' : 'relative' }}
          data-cy="animation-form"
        >
          <AuthContent>
            <Container>
              <AuthFormWrapper>
                <MyExtendAuthForm
                  isRestrictedLogin={isRestrictedLogin}
                  isRestrictedEmail={isRestrictedLogin && (loginMethods[0] === 'email' || !!email)}
                  onSubmit={handleSubmit}
                />
              </AuthFormWrapper>
            </Container>
          </AuthContent>
        </animated.div>
      ),
  )

  const confirmation = confirmationTransitionProps.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={{ ...props, position: shouldDisplayFormAnimation ? 'absolute' : 'relative' }}>
          <AuthContent>
            <LoginManagement initialUserInfo={userInfo} onSuccess={handleRedirect} onReset={handleResetForm} />
          </AuthContent>
        </animated.div>
      ),
  )

  return (
    <Wrapper loginBackgroundImage={theme.merchantConfiguration.brandingConfigurations.assetsUrls.loginBackground}>
      <TransitionContainer>
        {(!userInfo.email || !userInfo.phoneNumber) && form}
        {(userInfo.email || userInfo.phoneNumber) && confirmation}
      </TransitionContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ loginBackgroundImage: string }>(({ loginBackgroundImage }) => ({
  position: 'relative',
  width: '100%',
  height: 'calc(100vh - var(--header-height, 72px))',
  overflow: 'hidden',
  [bp.desktop]: {
    backgroundImage: `url(${loginBackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
  },
}))

const AuthContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  minHeight: 'calc(100vh - var(--header-height, 72px))',
  [bp.mobile]: {
    margin: 0,
    padding: '0 20px',
  },
  [bp.desktop]: {
    position: 'relative',
    padding: 0,
    justifyContent: 'center',
  },
})

const AuthFormWrapper = styled.div({
  maxWidth: 488,
})

const Container = styled.div<{ isPasscodeInput?: boolean }>(({ isPasscodeInput }) => ({
  display: 'flex',
  alignItems: 'center',
  [bp.mobile]: {
    justifyContent: 'center',
    flexDirection: isPasscodeInput ? 'column' : 'column-reverse',
    padding: 0,
    gap: 50,
    width: '100%',
  },
  [bp.desktop]: {
    width: '100%',
    gap: isPasscodeInput ? 0 : 100,
    padding: 0,
    justifyContent: 'center',
    flexDirection: isPasscodeInput ? 'column' : 'row',
  },
}))

const TransitionContainer = styled.div({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { MyExtendAuthentication }
