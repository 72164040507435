import React from 'react'

export const Polaroid = (): JSX.Element => {
  return (
    <svg height="63" viewBox="0 0 59 63" width="59" xmlns="http://www.w3.org/2000/svg">
      <g fill="none">
        <path
          d="m17 22c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-8.0952381c-1.6507937 0-3.0952381 1.3333333-3.0952381 2.8571429 0 1.5238095 1.4444444 2.8571428 3.0952381 2.8571428s3.0952381-1.3333333 3.0952381-2.8571428c0-1.5238096-1.4444444-2.8571429-3.0952381-2.8571429z"
          fill="#224bc5"
        />
        <path
          d="m50.0975711 45c-.412539 0-.8250779-.205298-1.0313474-.615894l-11.3448208-26.8940398-8.2507788 14.576159c-.2062694.205298-.4125389.410596-.6188084.410596s-.6188084 0-.8250779-.205298l-6.3943535-5.3377484-11.75735977 17.4503312c-.41253894.410596-1.03134735.615894-1.44388628.205298-.41253894 0-.61880841-.6158941-.20626947-1.2317881l12.37616812-18.4768212c.2062695-.205298.412539-.410596.6188085-.410596.2062694 0 .6188084 0 .8250778.205298l6.1880841 5.3377483 8.6633177-15.397351c.2062695-.410596.6188084-.615894 1.0313474-.615894.4125389 0 .8250779.205298.8250779.615894l12.1698986 28.7417219c.2062695.615894 0 1.0264901-.6188084 1.4370861 0 .205298 0 .205298-.2062694.205298z"
          fill="#27aee4"
        />
        <path
          d="m57.9464286 0h-56.89285717c-.58157143 0-1.05357143.4704-1.05357143 1.05v60.9c0 .5796.472 1.05 1.05357143 1.05h56.89285717c.5815714 0 1.0535714-.4704 1.0535714-1.05v-60.9c0-.5796-.472-1.05-1.0535714-1.05zm-1.0464286 60.9055556h-54.75v-58.8055556h54.75zm-49.525-14.7055556h44.25c.5815714 0 1.0535714-.4704 1.0535714-1.05v-37.8c0-.5796-.472-1.05-1.0535714-1.05h-44.25c-.58157143 0-1.05357143.4704-1.05357143 1.05v37.8c0 .5796.472 1.05 1.05357143 1.05zm.975-37.9h42.25v35.7907627h-42.25z"
          fill="#224bc5"
        />
      </g>
    </svg>
  )
}
