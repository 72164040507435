import styled from '@emotion/styled'
import { COLOR, DataProperty } from '@extend/zen'
import { bp } from '@customers-ui'
import React from 'react'
import type { ContractsSearchIndividual } from '../../types/contract'
import { currency } from '@extend/client-helpers'
import { MissingDataCard } from './missing-data-card'
import { useContactSupport } from '../../hooks/use-contact-support'
import dayjs from 'dayjs'

interface OrderDetailsSectionProps {
  contract?: ContractsSearchIndividual
}

export const OrderDetailsSection = ({ contract }: OrderDetailsSectionProps): JSX.Element => {
  const { contactSupport } = useContactSupport()

  return (
    <OrderDetailsSectionContainer data-cy="order-details-section">
      <SectionHeaderText data-cy="order-details-header">Order Details</SectionHeaderText>
      {contract ? (
        <OrderDetails>
          <DataProperty data-cy="seller-name" label="Selling Retailer" value={contract.sellerName} />
          <DataProperty data-cy="customer-name" label="Customer Name" value={contract.customer.name} />
          <DataProperty
            data-cy="purchase-date"
            label="Purchase Date"
            value={dayjs(contract.purchaseDate).format('MMMM D, YYYY')}
          />
          <DataProperty
            data-cy="purchase-price"
            label="Purchase Price"
            value={currency.format(contract.purchasePrice)}
          />
        </OrderDetails>
      ) : (
        <MissingDataCard
          headerText="Order Details not found."
          buttonProps={{ text: 'Contact Support', handleButtonClick: contactSupport }}
        >
          Please reach out to our customer support team for assistance.
        </MissingDataCard>
      )}
    </OrderDetailsSectionContainer>
  )
}

const OrderDetailsSectionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})

const SectionHeaderText = styled.div({
  fontSize: 24,
  fontWeight: 700,
})

const OrderDetails = styled.div({
  display: 'grid',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  gridTemplateColumns: 'repeat(4, 1fr)',
  borderRadius: 16,
  padding: 25,
  [bp.mobile]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  [bp.desktop]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
})
