import type { FC } from 'react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { PageWrapper, ContentSection } from '@src/components/common'
import image from '../../lib/images'
import { useTheme } from '@emotion/react'
import { Button } from '@extend/zen'

const ErrorPage: FC = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  const handleOnclick = (): void => {
    if (theme.merchantConfiguration.merchantSupportUrl) {
      window.open(theme.merchantConfiguration.merchantSupportUrl, '_blank')
    } else {
      navigate('/contact')
    }
  }

  return (
    <PageWrapper pageTitle="Error Page">
      <ContentSection>
        <HeaderError>
          <KaileyIcon src={image.kaleyLarge} alt="kailey_icon" />
          <HeaderText>That&apos;s our bad.</HeaderText>
        </HeaderError>
        <MessageBody>
          Please try again. {'\n'}
          If you&apos;re in need of immediate assistance, reach out.
        </MessageBody>
        <Button
          onClick={handleOnclick}
          text="Contact Support"
          color="neutral"
          emphasis="medium"
          size="large"
          data-cy="contact-button"
        />
      </ContentSection>
    </PageWrapper>
  )
}

const HeaderError = styled.div({
  [bp.md]: {
    width: '770px',
  },
  width: '300px',
  height: '52px',
  display: 'flex',
  flexDirection: 'row',
})

const HeaderText = styled.div({
  [bp.md]: {
    lineHeight: '52px',
    fontSize: '44px',
    fontWeight: 600,
  },
  lineHeight: '25px',
  fontSize: '22px',
  marginLeft: '15px',
  fontWeight: 300,
})

const MessageBody = styled.div({
  [bp.md]: {
    width: '770px',
    height: 28,
    fontSize: '20px',
    lineHeight: '28px',
  },
})

const KaileyIcon = styled.img({
  height: '52px',
  width: '52px',
  transform: 'scaleX(-1)',
})

export default ErrorPage
