import React from 'react'
import styled from '@emotion/styled'
import { COLOR, Stack } from '@extend/zen'
import { isMobile } from 'react-device-detect'
import { bp } from '@customers-ui'
import { useScreenSize } from '../../../../hooks'
import { images } from '../../../../lib/assets'

interface HowShippingProtectionWorksProps {
  processExplainedHeader?: string
  processExplainedImage?: string
  processExplainedStep1Body?: string
  processExplainedStep1Header?: string
  processExplainedStep1Label?: string
  processExplainedStep2Body?: string
  processExplainedStep2Header?: string
  processExplainedStep2Label?: string
  processExplainedStep3Body?: string
  processExplainedStep3Header?: string
  processExplainedStep3Label?: string
}

const HowShippingProtectionWorks = ({
  processExplainedHeader,
  processExplainedImage,
  processExplainedStep1Body,
  processExplainedStep1Header,
  processExplainedStep1Label,
  processExplainedStep2Body,
  processExplainedStep2Header,
  processExplainedStep2Label,
  processExplainedStep3Body,
  processExplainedStep3Header,
  processExplainedStep3Label,
}: HowShippingProtectionWorksProps): JSX.Element => {
  interface CardProps {
    title: string
    subtitle: string
    description: string
  }

  function drawCard(data: CardProps) {
    return (
      <Stack align="center" key={data.title}>
        <CardNumber>{data.title}</CardNumber>
        <CardSubtitle>{data.subtitle}</CardSubtitle>
        <CardBody>{data.description}</CardBody>
      </Stack>
    )
  }
  const spacing = 2

  const header = processExplainedHeader || 'How It Works'

  const card1Data: CardProps = {
    title: processExplainedStep1Label || 'STEP ONE',
    subtitle: processExplainedStep1Header || 'Purchase Protection.',
    description:
      processExplainedStep1Body ||
      `Make sure Shipping Protection by Extend is added to your purchase at checkout. You’ll receive an email confirmation with details and then you can sit back and relax!`,
  }

  const card2Data: CardProps = {
    title: processExplainedStep2Label || 'STEP TWO',
    subtitle: processExplainedStep2Header || 'File a claim.',
    description:
      processExplainedStep2Body ||
      `If your package is lost, stolen, or damaged, don’t worry. Visit customers.extend.com and file a claim with your email address or contract ID. No need to call your shipping carrier or the retailer!`,
  }

  const card3Data: CardProps = {
    title: processExplainedStep3Label || 'STEP THREE',
    subtitle: processExplainedStep3Header || 'Enjoy your replacement.',
    description:
      processExplainedStep3Body ||
      `Most often Extend will send you a virtual gift card to order a replacement product. In some cases, Extend will ship the replacement directly to you.`,
  }
  const screenSize = useScreenSize()
  const cards = [drawCard(card1Data), drawCard(card2Data), drawCard(card3Data)]

  if (isMobile || screenSize == 'medium' || screenSize == 'small') {
    return (
      <Stack align="center" spacing={spacing}>
        <HowTitle>{header}</HowTitle>
        {cards}
        <Img src={processExplainedImage || images.spWoman} alt="SP Woman" />
      </Stack>
    )
  } else {
    return (
      <Stack align="center" spacing={spacing}>
        <HowTitle>{header}</HowTitle>
        <Stack isRow align="center">
          <Img src={processExplainedImage || images.spWoman} alt="SP Woman" />
          <Stack spacing={spacing} align="center">
            {cards}
          </Stack>
        </Stack>
      </Stack>
    )
  }
}

const HowTitle = styled.div({
  textAlign: 'center',
  [bp.mobile]: {
    fontSize: 28,
    lineHeight: '38px',
    fontWeight: 800,
  },
  [bp.desktop]: {
    fontSize: 40,
    lineHeight: '48px',
  },
  fontWeight: 700,
})

const CardNumber = styled.div({
  fontSize: 16,
  color: COLOR.BLUE[800],
  fontWeight: 600,

  [bp.mobile]: {
    fontSize: 16,
    lineHeight: '36px',
    fontWeight: 700,
  },
  [bp.desktop]: {
    fontSize: 16,
  },
})

const CardSubtitle = styled.div({
  textAlign: 'center',
  color: COLOR.NEUTRAL[1000],
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: 20,
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: 36,
    lineHeight: '32px',
  },
})

const CardBody = styled.div({
  textAlign: 'center',

  [bp.mobile]: {
    fontSize: 15,
    lineHeight: '20px',
    textAlign: 'center',
  },
  [bp.desktop]: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: 400,
    width: '80%',
  },
  color: COLOR.NEUTRAL[700],
  marginTop: 10,
})

const Img = styled.img({
  maxWidth: '500px',
  maxHeight: '800px',
  borderRadius: '16px',
  transform: 'scale',
})

export { HowShippingProtectionWorks }
