import React from 'react'
import styled from '@emotion/styled'
import { COLOR, DataProperty } from '@extend/zen'
import { bp } from '@customers-ui'
import type { CategoryContract, ContractsSearchIndividual, ExtendedWarrantyContract } from '../../types/contract'
import { currency } from '@extend/client-helpers'
import { formatTermLength } from '../../utils/format-term-length'
import dayjs from 'dayjs'
import { MissingDataCard } from './missing-data-card'
import { useContactSupport } from '../../hooks/use-contact-support'

type ProtectionDetailsSectionProps = {
  contract?: ContractsSearchIndividual
}

export const isADH = (coverage: string): boolean => coverage === 'adh'
export const ProtectionDetailsSection = ({ contract: rawContract }: ProtectionDetailsSectionProps): JSX.Element => {
  const contract = rawContract as ExtendedWarrantyContract | CategoryContract | undefined
  const { contactSupport } = useContactSupport()

  const starts = contract?.coverage?.starts ?? 0
  const ends = contract?.coverage?.ends ?? 0
  const termLength = contract?.planDetails ? formatTermLength(contract?.planDetails.term_length) : ''
  const startDate = dayjs(starts).format('MMMM D, YYYY')
  const endDate = contract?.planDetails?.term_length === 999 ? 'Lifetime' : dayjs(ends).format('MMMM D, YYYY')
  const coverageDates = `${startDate} - ${endDate}`

  return (
    <ProtectionDetailsSectionContainer data-cy="protection-details-section">
      <SectionHeaderText data-cy="protection-details-header">Protection Details</SectionHeaderText>
      {contract ? (
        <ProtectionDetails>
          <DataProperty data-cy="contractId" label="Contract ID" value={contract.id} />
          <DataProperty data-cy="termLength" label="Contract Term" value={termLength} />
          <DataProperty data-cy="planPrice" label="Plan Price" value={currency.format(contract.purchasePrice)} />
          <DataProperty data-cy="coverageDates" label="Coverage Term" value={coverageDates} />
          <DataProperty
            data-cy="adh-coverage"
            label="Coverage Type"
            value={`Extend Protection${isADH(contract.planDetails?.coverage_includes ?? '') ? ' with ADH' : ''}`}
          />
          <DataProperty
            data-cy="deductible"
            label="Deductible"
            value={currency.format(contract.planDetails?.deductible)}
          />
        </ProtectionDetails>
      ) : (
        <MissingDataCard
          headerText="Terms and Conditions not found."
          buttonProps={{ text: 'Contact Support', handleButtonClick: contactSupport }}
        >
          Please reach out to our customer support team for assistance.
        </MissingDataCard>
      )}
    </ProtectionDetailsSectionContainer>
  )
}

const ProtectionDetailsSectionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})

const SectionHeaderText = styled.div({
  fontSize: 24,
  fontWeight: 700,
})

const ProtectionDetails = styled.div({
  display: 'grid',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  gridTemplateColumns: 'repeat(3, 1fr)',
  borderRadius: 16,
  padding: 25,
  span: {
    wordWrap: 'break-word',
    whiteSpace: 'wrap',
  },
  gap: 10,
  [bp.mobile]: {
    flexDirection: 'column',
    gridTemplateColumns: 'repeat(1, 1fr)',
    justifyContent: 'flex-start',
  },
  [bp.desktop]: {
    justifyContent: 'space-between',
    gridTemplateColumns: 'repeat(3, 1fr)',
    flexDirection: 'row',
  },
})
