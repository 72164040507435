import React from 'react'
import styled from '@emotion/styled'
import { bp, COLOR, FormattedMessage } from '@customers-ui'
import { Button } from '@extend/zen'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

interface MyExtendAuthErrorsProps {
  error: string | null
  isAuth?: boolean
}

const MyExtendAuthErrors = ({ error, isAuth = true }: MyExtendAuthErrorsProps): JSX.Element => {
  const navigate = useNavigate()
  const { locale } = useIntl()

  const onClick = (): void => {
    navigate(`/${locale}/authentication`)
  }

  return (
    <ParentContainer>
      <ErrorWrapper isAuth={isAuth}>
        <Title>
          {error && error === 'max_number_of_challenge_requests' && (
            <div data-cy="excessive-request-error-title">
              <FormattedMessage
                id="EXCESSIVE_REQUESTS"
                defaultMessage="Excessive Requests"
                description="This is the title for excessive requests error"
              />
            </div>
          )}
          {error && error === 'authentication_failed' && (
            <div data-cy="auth-failed-error-title">
              <FormattedMessage
                id="AUTHENTICATION_FAILED"
                defaultMessage="Authentication Failed"
                description="This is the title for authentication failed error"
              />
            </div>
          )}
        </Title>
        <Content>
          {error && error === 'max_number_of_challenge_requests' && (
            <div data-cy="excessive-request-error-content">
              <FormattedMessage
                id="EXCESSIVE_REQUESTS_CONTENT"
                defaultMessage="You have requested too many passcodes. For security reasons, please try again later."
                description="This is the content for excessive requests error"
              />
            </div>
          )}
          {error && error === 'authentication_failed' && (
            <div data-cy="auth-failed-error-content">
              <FormattedMessage
                id="AUTHENTICATION_FAILED_CONTENT"
                defaultMessage="We're sorry, but our server is currently experiencing technical difficulties. Please try again later or contact our support team for further assistance."
                description="This is the content for authentication failed error"
              />
            </div>
          )}
        </Content>
        <HomepageButtonWrapper>
          <Button
            data-cy="back-to-homepage-button"
            text="Back to Homepage"
            emphasis="high"
            fillContainer
            onClick={onClick}
          />
        </HomepageButtonWrapper>
      </ErrorWrapper>
    </ParentContainer>
  )
}

const ParentContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
})

const ErrorWrapper = styled.div<{ isAuth?: boolean }>(({ isAuth = true }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: isAuth ? '100vh' : '100%',
  width: '100%',
  [bp.desktop]: {
    backgroundColor: COLOR.WHITE,
    borderRadius: '4px',
    width: '416px',
    height: '444px',
    display: 'flex',
    flexDirection: 'column',
  },
  [bp.mobile]: {
    padding: '16px',
    gap: '32px',
  },
}))

const Title = styled.div({
  color: COLOR.BLACK,
  textAlign: 'center',
  fontWeight: 500,
  maxWidth: 488,
  [bp.mobile]: {
    marginBottom: 16,
    fontSize: 24,
    lineHeight: '36px',
  },
  [bp.desktop]: {
    marginBottom: 24,
    fontSize: 36,
    lineHeight: '44px',
    position: 'relative',
  },
})

const Content = styled.div({
  color: COLOR.NEUTRAL[800],
  textAlign: 'center',
  maxWidth: 488,
  [bp.mobile]: {
    margin: '8px 0 16px',
    fontSize: 16,
    lineHeight: '20px',
  },
  [bp.desktop]: {
    margin: '16px 0 40px',
    fontSize: 20,
    lineHeight: '28px',
    position: 'relative',
  },
})

const HomepageButtonWrapper = styled.div({
  width: 345,
})

export { MyExtendAuthErrors }
