import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { bp } from '@customers-ui'

export const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  width: '100%',
  gap: '16px',
  marginTop: '40px',
  [bp.mobile]: {
    height: '60vh',
    flex: '1 0 0',
  },
  [bp.desktop]: {
    height: '304px',
  },
})

export const Image = styled.img({
  width: '40px',
  height: '40px',
})

export const StatusText = styled.div({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '28px',
  color: COLOR.NEUTRAL[1000],
  textAlign: 'center',
  wordWrap: 'break-word',
  maxWidth: '100%',
})

export const MessageBody = styled.div({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: COLOR.NEUTRAL[1000],
  textAlign: 'center',
}) 