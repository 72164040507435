import React, { type PropsWithChildren } from 'react'
import styled from '@emotion/styled'

export interface GridProps {
  direction?: 'row' | 'column'
  align?: string
  margin?: string
  gap?: number
  className?: string
  dataCy?: string
}

const Grid = ({
  children,
  direction = 'row',
  align = 'left',
  margin,
  gap,
  className,
  dataCy,
}: PropsWithChildren<GridProps>): JSX.Element => {
  return (
    <StyledGrid {...{ className, direction, align, margin, gap }} data-cy={dataCy}>
      {children}
    </StyledGrid>
  )
}

const StyledGrid = styled.div<{
  direction: 'row' | 'column'
  align: string
  margin?: string
  gap?: number
}>(({ direction, align, margin, gap }) => ({
  display: 'flex',
  flexDirection: direction,
  justifyContent: align,
  margin: margin ?? 0,
  gap,
}))

export { Grid }
