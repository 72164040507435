import type { OrdersListByShipmentsResponse } from '@src/lib/customers-api-rtk-query'
import { useGetMyClaimsQuery, useListOrdersQuery } from '@src/lib/customers-api-rtk-query'
import React from 'react'
import { ContentSection, EmptyPageContent, ErrorToast, PageWrapper } from '@src/components/common'
import { COLOR } from '@extend/zen'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import { logEvent } from '@src/analytics'
import { customLogger } from '@extend/client-helpers'
import { ReturnClaimCard } from '../claims-page/claim-card/return-claim-card'
import type { InsuranceClaim } from '../../lib/customers-api-client'

export const ReturnsPage = (): JSX.Element => {
  const {
    data: returns = [],
    isLoading: isLoadingClaims,
    error: claimsListError,
  } = useGetMyClaimsQuery(
    {
      type: 'return_resolution',
    },
    {
      selectFromResult: (res) => {
        return {
          ...res,
          data: [...(res.data ?? [])].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
        }
      },
    },
  )

  const {
    data: emsResponse,
    isLoading: isLoadingEmsData,
    error: listOrdersQueryError,
  } = useListOrdersQuery({
    viewBy: 'shipments',
  })

  const isLoading = isLoadingClaims || isLoadingEmsData
  const error = claimsListError || listOrdersQueryError

  if (error) {
    customLogger.error(`[Err: Returns Page - claims]: ${error}`)
    logEvent('Returns - Error', 'Returns Load Error on Page Load')

    return <ErrorToast isVisible />
  }

  return (
    <PageWrapper data-cy="returns-page-content-layout" pageTitle="Returns">
      <ContentSection data-cy="returns-empty-page-content-wrapper">
        {!isLoading && <ReturnsPageHeader>Returns</ReturnsPageHeader>}
        {!isLoading && !returns?.length && (
          <EmptyPageContent primaryButtonText="View My Orders" primaryButtonUrl="/my_plans" contentType="returns">
            To start a return, go to the <b>Orders</b> tab and select the order you need assistance with.
          </EmptyPageContent>
        )}
        {!isLoadingClaims && returns?.length > 0 && (
          <>
            {returns.map((claim) => {
              return (
                <ReturnClaimCard
                  key={claim.id}
                  claim={claim as InsuranceClaim}
                  order={emsResponse?.find((order) => order.id === claim.orderId) as OrdersListByShipmentsResponse[0]}
                />
              )
            })}
          </>
        )}
      </ContentSection>
    </PageWrapper>
  )
}

const ReturnsPageHeader = styled.div({
  fontWeight: 800,
  color: COLOR.NEUTRAL[1000],
  fontSize: '28px',
  [bp.desktop]: {
    fontSize: '44px',
  },
})
