import React from 'react'
import { Toast } from '@customers-ui'
import { Button } from '@extend/zen'

interface ErrorToastProps {
  isVisible: boolean
}

const ErrorToast = ({ isVisible }: ErrorToastProps): JSX.Element => {
  const handleReload = (): void => {
    window.location.reload()
  }

  return (
    <Toast message="We've encountered an error. Please refresh this page." type="danger" isVisible={isVisible}>
      <Button onClick={handleReload} emphasis="medium" text="Refresh" color="red" />
    </Toast>
  )
}

export { ErrorToast }
