import { useListInsuranceClaimsQuery, getRTKQueryErrorMessage } from '@customers-api-rtk-query'
import { customLogger } from '@extend/client-helpers'
import type { Claim } from '../types/claim'

interface UseClaimSearchConfig {
  email?: string
  phone?: string
  sessionId?: string
  claimId?: string
  pollingInterval?: number
  skip?: boolean
}

interface UseClaimSearchResult {
  claims: Claim[]
  isLoading: boolean
  isError: boolean
  error?: string
}

export const useClaimSearch = ({
  email,
  phone,
  sessionId,
  claimId,
  pollingInterval = 5000,
  skip = false,
}: UseClaimSearchConfig): UseClaimSearchResult => {
  const {
    data: { items: fetchedClaims } = {},
    isLoading,
    isError,
    error,
  } = useListInsuranceClaimsQuery(
    {
      containsCustomerEmail: email,
      containsCustomerPhone: phone,
      sessionId,
      minLimit: '500',
      ...(claimId && { containsClaimId: claimId }),
    },
    {
      pollingInterval,
      skip,
    },
  )

  const claims = (fetchedClaims || []) as Claim[]

  if (error) {
    const errorMessage = getRTKQueryErrorMessage(error)
    customLogger.warn(`[Err: useClaimSearch]: ${errorMessage}`, {
      isError,
      claimId,
      sessionId,
    })
  }

  return {
    claims,
    isLoading,
    isError,
    error: error ? getRTKQueryErrorMessage(error) : undefined,
  }
} 