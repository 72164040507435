import React from 'react'
import type { FC } from 'react'
import { images } from '../../../lib/assets'
import type { BaseStateProps } from './types'
import { Image, StatusText, MessageBody } from './styles'

export const PhotoRequirementsState: FC<BaseStateProps> = () => {
  return (
    <>
      <Image src={images.reviewIcon2} data-cy="review-claim-icon" />
      <StatusText data-cy="photos-required-claim-status-text">We need more information</StatusText>
      <MessageBody data-cy="photos-required-claim-message-body">
        Photos are required for this damage.
      </MessageBody>
    </>
  )
} 