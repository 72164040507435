import React from 'react'
import { useNavigate } from 'react-router-dom'
import type {
  EmsProduct,
  EmsProductWithShipmentInfo,
  OrdersListByShipmentsResponse,
  PrecheckAllResponse,
} from '@customers-api-rtk-query'
import { useGetMerchantServicingSettingsQuery, useLazyGetStoreConsumerQuery } from '@customers-api-rtk-query'
import { logEvent } from '@src/analytics'
import { images } from '../lib/assets'
import { getDisplayDetails, getValidationErrorMessages } from '@src/pages/plans-page/utils'
import { getIsClaimInProgress, missingProfileFieldTooltip } from '@src/lib/helper-functions'
import { getCanTransfer } from '@src/pages/contract-transfer/contract-transfer.utils'
import type { CardListItemProps } from '@src/components/card'
import { ProductProtectionInlineBadge } from '@src/pages/plans-page/order-card/product-protection-inline-badge'
import { useIntl } from 'react-intl'

export const useGenerateOrderCardListItems = (
  order: OrdersListByShipmentsResponse[0],
  isMissingRequiredProfileField: boolean,
  onFileClaimClick: (handlerArgs: {
    contractId?: string
    isMerchantOwnedIntake?: boolean
    lineItemIds?: string[]
  }) => void,
  precheckData?: PrecheckAllResponse,
  isLoading = true,
) => {
  const { locale } = useIntl()
  const navigate = useNavigate()
  const { id: sellerId } = order.store

  const { data: merchantServicingSettings } = useGetMerchantServicingSettingsQuery({
    sellerId,
  })

  const [fetchStore] = useLazyGetStoreConsumerQuery()

  const handleContactSeller = async (): Promise<void> => {
    logEvent('My Orders - Order Card - Clicks', 'Contact Seller')
    const store = await fetchStore({
      storeId: sellerId,
      version: 'latest',
    }).unwrap()

    window.open(`https://${store.domain}`, '_blank')
  }

  const handleReviewTerms = (id: string): void => {
    logEvent('My Orders - Order Card - Clicks', 'Review Terms')
    navigate(`/my_plans/${id}`)
  }

  const handleTransferPlan = (id: string): void => {
    logEvent('My Orders - Order Card - Clicks', 'Transfer Plan')
    navigate(`/my_plans/${id}/transfer`)
  }

  const generateListItems = (products: EmsProductWithShipmentInfo[] | EmsProduct[]) =>
    products.map((product) => {
      const contractId = product.entitlements?.find(
        // filter out shipping protection because that will be on the group level
        (entitlement) => !!entitlement.contractId && entitlement.type !== 'shipping_protection',
      )?.contractId
      const precheckMatch = contractId && precheckData ? precheckData[contractId] : undefined
      const displayDetails = getDisplayDetails({
        precheck: precheckMatch?.precheckResponse,
        status: precheckMatch?.contract?.status,
        type: precheckMatch?.contract?.type,
      })
      const hasActiveClaimOnLineItem = getIsClaimInProgress(precheckMatch?.precheckResponse, [product])
      const shouldDisableClaimFiling =
        !precheckData ||
        isMissingRequiredProfileField ||
        hasActiveClaimOnLineItem ||
        displayDetails?.isClaimFilingDisabled ||
        !product.entitlements?.length

      const activeClaimTooltip = hasActiveClaimOnLineItem
        ? getValidationErrorMessages('active_claim_found').active_claim_found.default.fileClaimTooltip
        : ''
      const canTransfer = getCanTransfer({
        emsContract: precheckMatch?.contract,
        emsCoverage: product.entitlements?.[0]?.remainingCoverage,
        hasActiveClaim: hasActiveClaimOnLineItem,
      })

      const headerMenuItems = [
        {
          text: 'Review Terms & Conditions',
          onClick: () => handleReviewTerms(contractId || ''),
        },
        ...(precheckMatch?.precheckResponse?.status === 'failure' &&
        precheckMatch?.precheckResponse?.validationError === 'mfr_warranty_active'
          ? [
              {
                text: 'Contact Seller',
                onClick: handleContactSeller,
              },
            ]
          : []),
        ...(canTransfer
          ? [
              {
                text: 'Transfer Plan',
                onClick: () => handleTransferPlan(contractId || ''),
              },
            ]
          : []),
        ...(product.entitlements?.some((entitlement) => entitlement.type === 'product_protection')
          ? [
              {
                text: 'Get help with contract',
                onClick: () => navigate(`/${locale}/contact`),
              },
            ]
          : []),
      ]

      return {
        image: product?.imageUrl || images.fallbackImage,
        text: product?.title,
        ...(!product.entitlements?.length && { subText: "This item isn't eligible for shipping issues" }),
        itemsShowMoreText: 'View all items',
        itemsShowLessText: 'View less items',
        ...(contractId && {
          subText: (
            <ProductProtectionInlineBadge
              isClaimInProgress={hasActiveClaimOnLineItem || false}
              subBadgeText={displayDetails?.subBadgeText}
              isLoading={isLoading}
              badgeColor={displayDetails?.badgeColor}
              badgeDetails={displayDetails?.badgeDetails || ''}
            />
          ),
          primaryButtonProps: {
            emphasis: 'medium',
            text: 'File a Claim',
            isDisabled: shouldDisableClaimFiling,
            tooltip: isMissingRequiredProfileField ? missingProfileFieldTooltip : activeClaimTooltip,
            onClick: () =>
              onFileClaimClick({
                contractId,
                isMerchantOwnedIntake: merchantServicingSettings?.isMerchantOwnedIntake,
                lineItemIds: [product.lineItemId],
              }),
          },
          menuItems: headerMenuItems,
        }),
      }
    }) as CardListItemProps[]

  return {
    generateListItems,
    merchantServicingSettings,
  }
}
