import React, { useEffect, useState } from 'react'
import { connect as reduxConnect, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { PageWrapper, SplashScreen, SystemError } from '../../components/common'
import { Slot } from '@customers-api-client'
import { ScrollToBottom } from '../../components/common'
import { chatActions } from '../../actions'
import * as selectors from '../../reducers/selectors'
import type { RootState } from '../../reducers'
import ChatMessages from './chat-messages'
import ChatUserInput from './chat-user-input'
import { ChatErrorBoundary } from './chat-error-boundary'
import type { ConnectRequest } from '@customers-api-rtk-query'

interface SP {
  isPromptHidden: ReturnType<typeof selectors.getChatIsPromptHidden>
  messages: ReturnType<typeof selectors.getChatMessages>
  slots: ReturnType<typeof selectors.getChatSlots>
  prompt: ReturnType<typeof selectors.getChatPrompt>
  isLoading: ReturnType<typeof selectors.getChatIsLoading>
  isLoggedIn: ReturnType<typeof selectors.getIsLoggedIn>
}

interface DP {
  initialize: typeof chatActions.chatSessionRestart
}

type ChatPortalProps = SP & DP

function checkChatEnding(
  slots: ReturnType<typeof selectors.getChatSlots>,
  prompt: ReturnType<typeof selectors.getChatPrompt>,
): boolean {
  // Specifically used in routing back to the claims page once the "EndClaim" slot is set
  return slots.some((slot) => {
    return slot === Slot.EndClaim && !prompt
  })
}

const Component = ({
  messages,
  slots,
  prompt,
  initialize,
  isPromptHidden,
  isLoading,
  isLoggedIn,
}: ChatPortalProps): JSX.Element => {
  const [chatInputHeight, setChatInputHeight] = useState(0)
  const location = useLocation()
  const navigate = useNavigate()
  const accessToken = useSelector(selectors.getAccessToken)
  const { pathname } = location
  const contractId: string | undefined = location?.state?.contractId
  const orderId: string | undefined = location?.state?.orderId
  const claimType: ConnectRequest['claimType'] | undefined = location?.state?.claimType
  const lineItemIds: string[] | undefined = location?.state?.lineItemIds

  // Start the chat + reset when user navigates to '/kaley'
  useEffect(() => {
    if (contractId) {
      initialize({ contractId, orderId, lineItemIds, claimType, accessToken: accessToken || '' })
    } else {
      initialize()
    }
  }, [initialize, pathname, contractId, orderId, lineItemIds, claimType, accessToken])

  useEffect(() => {
    if (isLoggedIn && contractId && slots && checkChatEnding(slots, prompt)) {
      navigate('/my_claims')
    }
  }, [contractId, isLoggedIn, slots, navigate, prompt])

  if (isLoading) {
    return (
      <PageWrapper>
        <SplashScreen data-cy="splash-screen" />
      </PageWrapper>
    )
  }

  return (
    <ChatErrorBoundary page={SystemError} messages={messages} prompt={prompt}>
      <PageWrapper>
        <ScrollToBottom>
          <ChatMessages {...{ messages, chatInputHeight, setChatInputHeight }} />
          <ChatUserInput {...{ prompt, isPromptHidden, chatInputHeight, setChatInputHeight }} />
        </ScrollToBottom>
      </PageWrapper>
    </ChatErrorBoundary>
  )
}

const ChatPortal = reduxConnect(
  (state: RootState): SP => ({
    isPromptHidden: selectors.getChatIsPromptHidden(state),
    messages: selectors.getChatMessages(state),
    slots: selectors.getChatSlots(state),
    prompt: selectors.getChatPrompt(state),
    isLoading: selectors.getChatIsLoading(state),
    isLoggedIn: selectors.getIsLoggedIn(state),
  }),
  {
    initialize: chatActions.chatSessionRestart,
  } as DP,
)(Component)

export type { ChatPortalProps }
export { ChatPortal, Component }
