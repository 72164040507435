import React from 'react'
import { Button, COLOR, LinkButton, Paragraph, Stack } from '@extend/zen'
import type { Claim, ServiceOrder, ServiceOrderShipment } from '@customers-api-client'
import type { ReturnFulfillmentMethod } from '@extend-services/service-orders/dist/src/client/api-rest/v2/models'
import { currency, date } from '@extend/client-helpers'
import type { TimelineConfig } from '../claim-timeline'
import { TimelineFooter } from '../claim-timeline'
import { bp } from '@customers-ui'
import { ReturnOptionsContent } from './return-options-content'
import { ReturnPaymentDetails } from './return-payment-details'
import { FULFILLMENT_METHOD_LABELS, getCarrierText, TWENTY_SEVEN_DAYS_IN_MS, isCompletedState } from './constants'
import { ButtonWrapper } from './return-options-content'
import styled from '@emotion/styled'

interface GetTimelineConfigProps {
  claim: Claim
  activeServiceOrder?: ServiceOrder
  displayOptions: boolean
  selectedOption: ReturnFulfillmentMethod
  displayPaymentDetails: boolean
  setDisplayPaymentDetails: (show: boolean) => void
  handleOptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSelectReturnButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleConfirmReturnClick: () => void
  shipments?: ServiceOrderShipment[]
}

export const getReturnTimelineConfig = ({
  claim,
  activeServiceOrder,
  displayOptions,
  selectedOption,
  displayPaymentDetails,
  setDisplayPaymentDetails,
  handleOptionChange,
  handleSelectReturnButtonClick,
  handleConfirmReturnClick,
  shipments,
}: GetTimelineConfigProps): TimelineConfig => {
  const isCompleted = isCompletedState(activeServiceOrder)

  return {
    steps: [
      {
        label: 'Return started',
        isVisible: !isCompleted,
        renderActiveContent: () =>
          claim.status === 'pending_adjudication' ? (
            <TimelineFooter>
              <ButtonWrapper>
                <LinkButton
                  to={`/review_claim?claimId=${claim.id}&sessionId=${claim.sessionId}`}
                  data-cy="verify-shipping-address-button"
                  color="neutral"
                  fillContainer
                  text="Verify shipping address"
                />
              </ButtonWrapper>
            </TimelineFooter>
          ) : null,
      },
      {
        label: activeServiceOrder?.configurations?.fulfillmentMethod
          ? `Return option: ${FULFILLMENT_METHOD_LABELS[activeServiceOrder.configurations.fulfillmentMethod]}`
          : 'Return option',
        isVisible: !isCompleted,
        renderActiveContent: () => {
          if (activeServiceOrder?.configurations?.fulfillmentMethod) return null

          if (!displayOptions) {
            return (
              <TimelineFooter>
                <ButtonWrapper>
                  <Button
                    data-cy="display-return-options-button"
                    color="neutral"
                    text="Select return options"
                    emphasis="high"
                    fillContainer
                    onClick={handleSelectReturnButtonClick}
                  />
                </ButtonWrapper>
              </TimelineFooter>
            )
          }

          return (
            <ReturnOptionsContent
              selectedOption={selectedOption}
              onOptionChange={handleOptionChange}
              displayPaymentDetails={displayPaymentDetails}
              setDisplayPaymentDetails={setDisplayPaymentDetails}
              handleConfirmReturnClick={handleConfirmReturnClick}
              claim={claim}
            />
          )
        },
        renderCompleted: () => (
          <ReturnPaymentDetails
            claim={claim}
            displayPaymentDetails={displayPaymentDetails}
            setDisplayPaymentDetails={setDisplayPaymentDetails}
          />
        ),
      },
      {
        label: `Ship return${shipments?.length ? ` with ${getCarrierText(shipments[0])}` : ''}`,
        isVisible: !isCompleted,
        renderActiveContent: () => {
          if (!shipments?.[0]) return null

          const [shipment] = shipments

          if (shipment.status === 'created') {
            const shipmentReturnRequiredDate = Date.now() + TWENTY_SEVEN_DAYS_IN_MS
            return (
              <Stack spacing={1}>
                <Paragraph>
                  Shipment must be received by {date.format(shipmentReturnRequiredDate)}. Print label and return in
                  original packaging.
                </Paragraph>

                <PrintLabelWrapper>
                  <ButtonWrapper>
                    <Button
                      onClick={() =>
                        window.open(activeServiceOrder?.shippingLabels?.[0]?.pdfDownloadUrl ?? '', '_blank')
                      }
                      data-cy="print-label-button"
                      fillContainer
                      color="neutral"
                      text="Print label"
                    />
                  </ButtonWrapper>
                </PrintLabelWrapper>
              </Stack>
            )
          }

          return (
            <Paragraph>
              Package Status: {shipment.status === 'received_by_carrier' ? 'In Transit' : 'Delivered'}
            </Paragraph>
          )
        },
      },
      {
        label: `Return completed${
          activeServiceOrder?.fulfillmentMetaData?.fulfilledAt
            ? ` at ${date.format(activeServiceOrder.fulfillmentMetaData.fulfilledAt)}`
            : ''
        }`,
        isVisible: true,
        isSuccessOnActive: true,
        renderActiveContent: () => {
          const { totalValue } = claim

          return (
            <Stack>
              <Stack isRow align="center">
                <FieldNameText>Payout option:</FieldNameText> &nbsp;
                <Paragraph>
                  {FULFILLMENT_METHOD_LABELS[activeServiceOrder?.configurations?.fulfillmentMethod ?? 'store_credit']}
                </Paragraph>
              </Stack>
              <Stack isRow align="center">
                <FieldNameText>Refund status:</FieldNameText> &nbsp;
                <Paragraph>Paid</Paragraph>
              </Stack>
              <Stack isRow align="center">
                <FieldNameText>Refund amount:</FieldNameText> &nbsp;
                <Paragraph>{currency.format(totalValue?.amount ?? 0)}</Paragraph>
              </Stack>
            </Stack>
          )
        },
      },
    ],
  }
}

const FieldNameText = styled.div({
  fontWeight: 800,
  fontSize: 14,
  color: COLOR.NEUTRAL[600],
})

export const PrintLabelWrapper = styled.div({
  width: '100%',
  display: 'flex',
  paddingTop: 8,
  [bp.mobile]: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  [bp.desktop]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
})
