import React, { type ReactNode } from 'react'
import styled from '@emotion/styled'
import { GlobalStyles } from '@customers-ui'

export interface PageLayoutProps {
  className?: string
  children?: ReactNode
}

const PageLayout = ({ children, className }: PageLayoutProps): JSX.Element => {
  return (
    <StyledPageLayout className={className}>
      <GlobalStyles />
      {children}
    </StyledPageLayout>
  )
}

const StyledPageLayout = styled.div({
  position: 'relative',
  overflow: 'auto',
  minHeight: 'calc(100vh - 86px)',
  minWidth: '100%',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

export { PageLayout }
