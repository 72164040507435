import React from 'react'
import { useSelector } from 'react-redux'
import type { ContractSearch } from '@extend-contracts/client/dist/api-rest/versions/2023-07-01/models'
import { CategoryCard } from './contract-cards/category-card'
import { ProductProtectionCard } from './contract-cards/product-protection-card'
import type { ExtendedWarrantyContract, CategoryContract, ProductProtectionBundleContract } from '../../types/contract'
import { getAccountInfo } from '../../lib/consumer-profile-utils'
import * as selectors from '../../reducers/selectors'
import type { EntitlementSearchByOrdersResponse } from '@customers-api-rtk-query'

interface CardMapperProps {
  contract?: ContractSearch
  emsData?: EntitlementSearchByOrdersResponse
  handleFileClaimClick: (handlerArgs: {
    orderId?: string
    contractId?: string
    lineItemId?: string
    isMerchantOwnedIntake?: boolean
  }) => void
}

const CardMapper = ({ contract, emsData, handleFileClaimClick }: CardMapperProps): JSX.Element => {
  const contractType = contract ? contract.type : 'shipping_protection'
  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const { isEmailVerified, isPhoneNumberVerified, hasAccountInfo } = getAccountInfo(consumerProfile)

  const isMissingRequiredProfileField = hasAccountInfo && (!isEmailVerified || !isPhoneNumberVerified)

  return (
    <div>
      {contract && contractType === 'category' && (
        <CategoryCard
          key={contract.id}
          contract={contract as CategoryContract}
          onFileClaimClick={handleFileClaimClick}
          isMissingRequiredProfileField={isMissingRequiredProfileField}
        />
      )}
      {contract && contractType === 'pcrs' && (
        <ProductProtectionCard
          key={contract.id}
          contract={contract as ExtendedWarrantyContract}
          onFileClaimClick={handleFileClaimClick}
          isMissingRequiredProfileField={isMissingRequiredProfileField}
        />
      )}
      {contract && contractType === 'product_protection_bundle' && (
        <ProductProtectionCard
          key={contract.id}
          contract={contract as ProductProtectionBundleContract}
          onFileClaimClick={handleFileClaimClick}
          isMissingRequiredProfileField={isMissingRequiredProfileField}
        />
      )}
    </div>
  )
}

export { CardMapper }
