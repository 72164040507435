import React, { type PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

import { bp } from '../../utils/breakpoints'

interface CardProps {
  width?: number
  margin?: string
  padding?: string
  color?: string
  className?: string
  dataCy?: string
}

const Card = ({
  children,
  width,
  margin,
  padding,
  color,
  className,
  dataCy,
}: PropsWithChildren<CardProps>): JSX.Element => {
  return (
    <StyledCard {...{ className, width, margin, padding, color }} data-cy={dataCy}>
      {children}
    </StyledCard>
  )
}

const StyledCard = styled.div<{
  width?: number
  margin?: string
  padding?: string
  color?: string
}>(({ width, margin, padding, color }) => ({
  boxSizing: 'border-box',
  maxWidth: '100%',
  width: width || '100%',
  margin,
  border: `1px solid ${COLOR.NEUTRAL[200]}`,
  borderRadius: 16,
  backgroundColor: color,
  [bp.mobile]: {
    padding: padding || 24,
  },
  [bp.desktop]: {
    padding: padding || 32,
  },
}))

export { Card }
