import React, { useState, useRef, useEffect } from 'react'
import { useMyExtendLogin } from '../../hooks/use-myextend-login'
import { useDispatch, useSelector } from 'react-redux'
import { setAccessToken, setConsumerProfile, setIsFirstTimeLogin } from '../../store/slices/my-extend'
import { PopaFlow } from '../../components/popa-flow'
import { MyExtendAuthForm } from './myextend-auth-form'
import type { UserInfo } from './myextend-authentication'
import { ProgressCard } from '../../components/common/progress-card'
import type { RootState } from '@src/reducers'
import * as selectors from '@src/reducers/selectors'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '@src/constants/ld-flags'
import type { ConsumerProfileResponse } from '@extend-myextend/types'

interface LoginManagementProps {
  initialUserInfo: UserInfo
  onSuccess: () => void
  onReset: () => void
}

enum LoginStep {
  FIRST_VERIFICATION,
  SECOND_VERIFICATION,
}

export const LoginManagement = ({ initialUserInfo, onSuccess, onReset }: LoginManagementProps): JSX.Element => {
  const { [LDFlag.UseAuthPopa]: FF_USE_AUTH_POPA } = useFlags()
  const [currentStep, setCurrentStep] = useState<LoginStep>(LoginStep.FIRST_VERIFICATION)
  const [userInfo, setUserInfo] = useState<UserInfo>(initialUserInfo)
  const dispatch = useDispatch()
  const { requestLogin } = useMyExtendLogin()
  const isMounted = useRef(true)
  const emailToken = useSelector((state: RootState) => selectors.getEmailToken(state))
  const phoneToken = useSelector((state: RootState) => selectors.getPhoneToken(state))
  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleFirstVerificationSuccess = async (): Promise<void> => {
    if (!FF_USE_AUTH_POPA) {
      onSuccess()
      return
    }

    try {
      const { isFirstTimeUser, profile } = await requestLogin()

      if (isMounted.current) {
        if (isFirstTimeUser) {
          setCurrentStep(LoginStep.SECOND_VERIFICATION)
        } else {
          dispatch(setConsumerProfile(profile as ConsumerProfileResponse))
          dispatch(setAccessToken(emailToken || phoneToken || ''))
          onSuccess()
        }
      }
    } catch (error) {
      if (isMounted.current) {
        onReset()
      }
    }
  }

  const handleSecondContactSubmit = (info: UserInfo): void => {
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      ...info,
    }))
  }

  const handleSecondVerificationSuccess = async (): Promise<void> => {
    if (!FF_USE_AUTH_POPA) {
      onSuccess()
      return
    }

    try {
      const { profile } = await requestLogin()

      if (isMounted.current && profile) {
        dispatch(setConsumerProfile(profile))
        dispatch(setIsFirstTimeLogin(true))

        // we want the default in this case to be the email token
        if (emailToken) {
          dispatch(setAccessToken(emailToken))
        }
        onSuccess()
      }
    } catch (error) {
      if (isMounted.current) {
        onReset()
      }
    }
  }

  const hasEnteredSecondaryContact = (): boolean => {
    // If they started with email, check if they've entered phone
    if (initialUserInfo.email) {
      return !!userInfo.phoneNumber
    }
    // If they started with phone, check if they've entered email
    if (initialUserInfo.phoneNumber) {
      return !!userInfo.email
    }
    return false
  }

  if (currentStep === LoginStep.SECOND_VERIFICATION) {
    return (
      <ProgressCard progress={hasEnteredSecondaryContact() ? 75 : 50}>
         {!hasEnteredSecondaryContact() ? (
          <MyExtendAuthForm
            onSubmit={handleSecondContactSubmit}
            isFirstTimeUser={true}
            isRestrictedEmail={!initialUserInfo.email}
            isRestrictedLogin={true}
            hasBorderRadius={false}
          />
        ) : (
          <PopaFlow
            userInfo={userInfo}
            isAuth={false}
            resetForm={onReset}
            handleSuccess={handleSecondVerificationSuccess}
            hasBorderRadius={false}
          />
        )}
      </ProgressCard>
    )
  }

  return <PopaFlow userInfo={userInfo} isAuth resetForm={onReset} handleSuccess={handleFirstVerificationSuccess} />
}
