import { useState, useEffect } from 'react'

interface UseTimerConfig {
  initialSeconds: number
  isRunning: boolean
  onComplete?: () => void
}

interface UseTimerResult {
  secondsLeft: number
  isTimerRunning: boolean
  resetTimer: (newSeconds?: number) => void
  startTimer: () => void
  stopTimer: () => void
}

export function useTimer({ initialSeconds, isRunning, onComplete }: UseTimerConfig): UseTimerResult {
  const [secondsLeft, setSecondsLeft] = useState<number>(initialSeconds)
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(isRunning)

  const resetTimer = (newSeconds?: number): void => {
    setSecondsLeft(newSeconds ?? initialSeconds)
  }

  const startTimer = (): void => {
    setIsTimerRunning(true)
  }

  const stopTimer = (): void => {
    setIsTimerRunning(false)
  }

  useEffect(() => {
    if (isTimerRunning) {
      const timer = setInterval(() => {
        setSecondsLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer)
            setIsTimerRunning(false)
            onComplete?.()
            return 0
          }
          return prev - 1
        })
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [isTimerRunning, onComplete])

  return {
    secondsLeft,
    isTimerRunning,
    resetTimer,
    startTimer,
    stopTimer,
  }
} 