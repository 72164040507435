import type { ReturnFulfillmentMethod } from '@extend-services/service-orders/dist/src/client/api-rest/v2/models'
import type { Claim, ServiceOrder, ServiceOrderShipment } from '@customers-api-client'

export const FULFILLMENT_METHOD_LABELS: Record<ReturnFulfillmentMethod, string> = {
  store_credit: 'Instant credit',
  original_tender: 'Refund original payment',
}

export const TWENTY_SEVEN_DAYS_IN_MS = 27 * 24 * 60 * 60 * 1000

export const TIMELINE_STEPS = {
  RETURN_STARTED: 0,
  RETURN_OPTION: 1,
  RETURN_TO_CARRIER: 2,
  RETURN_FULFILLED: 3,
} as const

export const getCarrierText = (shipment?: ServiceOrderShipment): string => {
  if (!shipment) return ''
  return shipment.carrier.toLowerCase().includes('ups') ? 'UPS' : 'FedEx'
}

export const isPendingState = (claim: Claim, activeServiceOrder?: ServiceOrder): boolean => {
  return !!(
    (claim.status === 'approved' && !activeServiceOrder) ||
    (activeServiceOrder?.status === 'created' && activeServiceOrder.configurations?.fulfillmentMethod) ||
    activeServiceOrder?.status === 'assigned'
  )
}

export const isCompletedState = (activeServiceOrder?: ServiceOrder): boolean => {
  return !!(activeServiceOrder?.status === 'fulfilled' || activeServiceOrder?.status === 'completed')
}
