import type { OrdersListByShipmentsResponse } from '@extend-myextend/types'
import { getIsShippingResolution, getOrderProducts } from '@src/lib/ems-utils'
import { formatDate } from '@src/lib/helper-functions'
import { getIsShippingProtection } from '@src/lib/shipping-protection-utils'
import { getMultipleProductsHeader } from '../../../lib/card-utils'

export const getTitleHeading = (order: OrdersListByShipmentsResponse[0]) => {
  const products = getOrderProducts(order)
  const { name: sellerName } = order.store
  const isShippingResolution = getIsShippingResolution(order)

  if (isShippingResolution) {
    return getMultipleProductsHeader(products) ?? `Order from ${sellerName}`
  }
  return `Order from ${sellerName}`
}

export const getSubHeading = (order: OrdersListByShipmentsResponse[0]) => {
  const { transactionId, transactionDate, trackedProducts, hasShipmentInfo } = order
  const products = getOrderProducts(order)
  if (hasShipmentInfo) {
    return `Order # ${transactionId} · ${Object.entries(trackedProducts || {})?.length ?? 0} shipment(s) · ${
      products?.length
    } item(s) · Purchased ${formatDate(transactionDate || 0)}`
  }

  return `Order # ${transactionId} · ${products?.length} item(s) · Purchased ${formatDate(transactionDate || 0)}`
}

/**
 * Finds the data-props for the card
 * Prioritizes shipping entitlements
 */
export const getDataProps = (order: OrdersListByShipmentsResponse[0]) => {
  const isShippingResolution = getIsShippingResolution(order)
  const isShippingProtection = getIsShippingProtection(order)
  const productProtectionContract = order.contracts?.find((contract) =>
    ['pcrs', 'product_protection_bundle'].includes(contract.type),
  )
  const categoryContract = order.contracts?.find((contract) => contract.type === 'category')

  if (isShippingResolution) {
    return {
      dataCy: 'shipping-protection',
      dataProps: {
        'data-extend-live': 'shipping-protection-card-wrapper',
        'data-order-id': order.id,
        'data-extend-live-sr-id': order.id,
      } as Record<string, string>,
    }
  } else if (isShippingProtection) {
    return {
      dataCy: 'shipping-protection',
      dataProps: {
        'data-extend-live': 'shipping-protection-card-wrapper',
        'data-order-id': order.id,
        'data-extend-live-sp-id': order.contracts?.find((contract) => contract.type === 'shipping_protection')?.id,
      } as Record<string, string>,
    }
  } else if (categoryContract) {
    return {
      dataCy: 'category',
      dataProps: {
        'data-extend-live': 'category-card-wrapper',
        'data-contract-id': categoryContract.id,
        'data-extend-live-cat-id': categoryContract.id,
      } as Record<string, string>,
    }
  }

  return {
    dataCy: 'product-protection',
    dataProps: {
      'data-extend-live': 'product-protection-card-wrapper',
      'data-contract-id': productProtectionContract?.id,
      'data-extend-live-pp-id': productProtectionContract?.id,
    } as Record<string, string>,
  }
}
