import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR, Button, Icon } from '@extend/zen'
import { bp } from '@customers-ui'
import type { ButtonsPrompt, MultiSelectPrompt, Reply, Slot } from '@extend-incredibot/types'
import { isMobile } from 'react-device-detect'
import { getOptions } from '../../../lib/chat-utils'
import type { UserInputComponentProps } from './types'
import { useTheme } from '@emotion/react'

export interface MultiSelectProps
  extends UserInputComponentProps<Reply & { prompt: MultiSelectPrompt | ButtonsPrompt }> {}

const MultiSelect = ({ isLoading, onSubmit, reply }: MultiSelectProps): JSX.Element => {
  const [selectedValueIndex, setSelectedValueIndex] = useState<number | null>(null)
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null)
  const theme = useTheme()
  const navigate = useNavigate()

  useEffect(() => {
    if (selectedValueIndex !== null) {
      const selectedOption = (reply.prompt as MultiSelectPrompt).options[selectedValueIndex]
      setRedirectUrl(selectedOption?.redirectUrl || null)
    }
  }, [selectedValueIndex, reply.prompt])

  const handleSubmit = (): void => {
    if (redirectUrl) {
      // If redirecting to the contact support page in a merchant post purchase experience, link to the merchant support page
      if (redirectUrl === '/contact' && theme.merchantConfiguration.merchantSupportUrl) {
        window.open(theme.merchantConfiguration.merchantSupportUrl, '_blank')
      } else {
        navigate(redirectUrl)
      }
    } else if (reply.prompt?.slot && selectedValueIndex !== null) {
      const selectedOption = (reply.prompt as MultiSelectPrompt).options[selectedValueIndex]
      onSubmit(reply.prompt.slot as Slot, selectedOption.value)
    }
  }

  return (
    <ContentWrapper data-cy="multi-select-content-wrapper">
      {getOptions(reply).map(({ outputText, title, value }, index) => {
        const key = `${value}-${title}-${index}`
        const checked = selectedValueIndex === index
        return (
          <Option checked={checked} key={key} onClick={() => setSelectedValueIndex(index)} data-cy={`option-${key}`}>
            <OptionText data-cy={`option-text-${key}`}>{outputText ?? title}</OptionText>
            {checked && (
              <CheckWrapper data-cy={`checked-wrapper-${key}`}>
                <Icon icon={CheckCircle} color={COLOR.NEUTRAL[800]} />
              </CheckWrapper>
            )}
            {isMobile && !checked && (
              <CheckWrapper data-cy={`unchecked-wrapper-${key}`}>
                <Icon icon={{ name: 'Circle', isCircle: true }} color={COLOR.NEUTRAL[800]} />
              </CheckWrapper>
            )}
          </Option>
        )
      })}
      <ButtonWrapper>
        <Button
          data-cy="next-button"
          text="Next"
          isDisabled={selectedValueIndex === null || isLoading}
          isProcessing={isLoading}
          type="submit"
          onClick={handleSubmit}
          color="neutral"
        />
      </ButtonWrapper>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  width: '100%',
  display: 'inline-flex',
  justifyContent: 'center',
  flexDirection: 'column',
  [bp.desktop]: {
    gap: 32,
  },
  [bp.mobile]: {
    gap: 16,
  },
})

const Option = styled.div<{ checked: boolean }>(({ checked }) => ({
  display: 'flex',
  padding: '24px',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderRadius: '4px',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  ...(checked && {
    borderColor: COLOR.NEUTRAL[700],
    fontWeight: 600,
    backgroundColor: COLOR.NEUTRAL[100],
  }),
  '&:hover': {
    borderColor: COLOR.NEUTRAL[800],
    backgroundColor: COLOR.NEUTRAL[100],
    cursor: 'pointer',
  },
  [bp.mobile]: {
    height: '48px',
    padding: '33px 16px',
  },
}))

const OptionText = styled.div({
  textAlign: 'left',
})

const CheckWrapper = styled.div({
  display: 'flex',
  justifyContent: 'right',
  marginInlineStart: 'auto',
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const CheckCircle = {
  name: 'CheckCircle',
  d: 'M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM11.1908 16.35L7.75 12.4465L8.75 11.4465L11.1908 13.8873L15.75 8.25L16.75 9.25L11.1908 16.35Z',
}

export { MultiSelect }
