import React, { type PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { HeadTag } from '../head-tag'

import { bp } from '../../utils/breakpoints'

export interface ContentLayoutProps {
  className?: string
  pageTitle?: string
  'data-cy'?: string
}

/**
 * @deprecated Use PageWrapper and ContentSection instead
 * @see src/components/common/page-wrapper/page-wrapper.tsx
 * @see src/components/common/content-section/content-section.tsx
 */
const ContentLayout = ({
  children,
  className,
  'data-cy': dataCy,
  pageTitle = 'Extend',
}: PropsWithChildren<ContentLayoutProps>): JSX.Element => {
  return (
    <StyledContentLayout className={className} data-cy={dataCy}>
      <HeadTag title={pageTitle} />
      {children}
    </StyledContentLayout>
  )
}

const StyledContentLayout = styled.div({
  [bp.mobile]: {
    padding: '48px 0 24px',
  },
  [bp.desktop]: {
    padding: '72px 0 24px',
  },
})

export { ContentLayout }
