import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { NavLink, useNavigate } from 'react-router-dom'
import { bp, COLOR, FormattedMessage } from '@customers-ui'
import { useIntl } from 'react-intl'
import { ToastColor, ToastDuration, useToaster, Button } from '@extend/zen'
import { PasscodeInput } from '../../components/passcode'
import { TOAST_ERRORS, useMyExtendPopaToken } from '../../hooks/use-myextend-popa-token'
import { MyExtendAuthErrors } from './myextend-auth-errors'
import type { Locale } from '../../types/localization'
import { useAuthPopaManagement } from '@src/hooks'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '@src/constants/ld-flags'
import { setEmailToken, setPhoneToken } from '@src/store/slices/my-extend'
import { useDispatch } from 'react-redux'

export interface MyExtendAuthPasscodeInputProps {
  email: string
  phoneNumber: string
  sessionId: string
  resetForm: (error: string | null) => void
  resendPasscode: () => void
  isAuth?: boolean
  handleSuccess: () => void
  hasBorderRadius?: boolean
}

const PASSCODE_LENGTH = 6

const MyExtendAuthPasscodeInput = ({
  email,
  phoneNumber,
  sessionId,
  resetForm,
  resendPasscode,
  isAuth = true,
  handleSuccess,
  hasBorderRadius = true,
}: MyExtendAuthPasscodeInputProps): JSX.Element => {
  const { [LDFlag.UseAuthPopa]: FF_USE_AUTH_POPA } = useFlags()
  const [passcode, setPasscode] = useState<string>('')
  const [isErrorPageVisible, setIsErrorPageVisible] = useState<boolean>(false)
  const { locale } = useIntl()
  const dispatch = useDispatch()

  const {
    requestPopaToken: requestPopaTokenMyExtend,
    errorType: errorTypeMyExtend,
    isToastError: isToastErrorMyExtend,
    isReturnToUserInfoError: isReturnToUserInfoErrorMyExtend,
    isSuccess: isSuccessMyExtend,
    isTokenLoading: isTokenLoadingMyExtend,
  } = useMyExtendPopaToken({ email, phoneNumber }, sessionId)
  const {
    accessToken,
    requestPopaToken: requestPopaTokenAuth,
    errorType: errorTypeAuth,
    isToastError: isToastErrorAuth,
    isReturnToUserInfoError: isReturnToUserInfoErrorAuth,
    isSuccess: isSuccessAuth,
    isTokenLoading: isTokenLoadingAuth,
  } = useAuthPopaManagement({ email, phoneNumber })

  const { toast } = useToaster()
  const navigate = useNavigate()
  const [secondsLeft, setSecondsLeft] = useState<number>(10)
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(true)
  const [passcodeResets, setPasscodeResets] = useState<number>(1)

  const decreaseSeconds = (): void => {
    setSecondsLeft((prev) => {
      if (prev > 0) {
        return prev - 1
      }
      return 10
    })
  }

  useEffect(() => {
    if (secondsLeft <= 0) {
      setIsTimerRunning(false)
      return undefined
    }
    const timer = setInterval(decreaseSeconds, 1000)

    return () => clearInterval(timer)
  }, [secondsLeft])

  useEffect(() => {
    if (errorTypeMyExtend || errorTypeAuth) {
      if (isToastErrorMyExtend || isToastErrorAuth) {
        if (isReturnToUserInfoErrorMyExtend || isReturnToUserInfoErrorAuth) {
          resetForm(errorTypeMyExtend || errorTypeAuth)
          navigate(`/${locale}/authentication`)
        } else {
          toast({
            message: errorTypeMyExtend || errorTypeAuth,
            toastDuration: ToastDuration.short,
            toastColor: ToastColor.red,
          })
          setPasscode('')
        }
      } else {
        setIsErrorPageVisible(true)
      }
    }
  }, [
    errorTypeMyExtend || errorTypeAuth,
    isToastErrorMyExtend || isToastErrorAuth,
    isReturnToUserInfoErrorMyExtend || isReturnToUserInfoErrorAuth,
    resetForm,
    history,
    toast,
    locale,
  ])

  useEffect(() => {
    // Only set token when we get it
    if (accessToken && FF_USE_AUTH_POPA) {
      if (email) {
        dispatch(setEmailToken(accessToken as string))
      }

      if (phoneNumber) {
        dispatch(setPhoneToken(accessToken as string))
      }

      // dispatch(setAccessToken(accessToken as string))
    }
  }, [accessToken, FF_USE_AUTH_POPA, dispatch])

  // Separate effect for handling success
  useEffect(() => {
    if (isSuccessMyExtend || (isSuccessAuth && accessToken)) {
      handleSuccess()
    }
  }, [isSuccessMyExtend, isSuccessAuth, accessToken])

  const handlePasscodeSubmit = (code: string): void => {
    if (FF_USE_AUTH_POPA) {
      requestPopaTokenAuth(sessionId, code, locale as Locale)
    } else {
      requestPopaTokenMyExtend(code, locale as Locale, isAuth)
    }
  }

  const handleChangeUserInfo = (): void => {
    resetForm(null)
  }

  const handleResendPasscode = (): void => {
    setPasscodeResets(passcodeResets + 1)
    if (passcodeResets < 4) {
      resendPasscode()
      setSecondsLeft(10)
      setIsTimerRunning(true)
    } else {
      toast({
        message: TOAST_ERRORS.max_number_of_challenge_response_attempts,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
      resetForm(TOAST_ERRORS.max_number_of_challenge_response_attempts)
      navigate(`/${locale}/authentication`)
    }
  }

  // Get the current URL's search params to maintain the restricted login context
  const currentSearch = window.location.search

  return (
    <>
      {isErrorPageVisible ? (
        <MyExtendAuthErrors isAuth={isAuth} error={errorTypeMyExtend || errorTypeAuth} />
      ) : (
        <ParentContainer>
          <Wrapper hasBorderRadius={hasBorderRadius}>
            <Title data-cy="passcode-auth-input-title">
              <FormattedMessage
                id="PASSCODE_AUTHENTICATION_INPUT_TITLE"
                defaultMessage="Enter the passcode"
                description="This is the title for the passcode authentication confirmation form"
              />
            </Title>
            <Content data-cy="passcode-auth-input-content">
              <FormattedMessage
                id="PASSCODE_AUTHENTICATION_INPUT_MESSAGE"
                defaultMessage="Enter the {passcodeLength}-digit passcode we sent to {break}{userInfo} {changeInput} {break}If you don't see the passcode, check spam."
                values={{
                  changeInput: (
                    <ChangeInputLink
                      data-cy="change-input-link"
                      to={`${isAuth ? `/${locale}/authentication${currentSearch}` : `/${locale}/profile`}`}
                      onClick={handleChangeUserInfo}
                      className={isTokenLoadingMyExtend || isTokenLoadingAuth ? 'disabled' : ''}
                    >
                      <strong>Change</strong>
                    </ChangeInputLink>
                  ),
                  break: <br />,
                  userInfo: <strong>{email || phoneNumber}</strong>,
                  quote: `"`,
                  passcodeLength: PASSCODE_LENGTH,
                }}
                description="This message indicates an authentication email/text has been sent to {userInfo}."
              />
            </Content>
            <form data-cy="passcode-input-form">
              <PasscodeInputWrapper data-cy="passcode-input-label">
                <PasscodeTitleWrapper>
                  <FormattedMessage
                    id="PASSCODE_INPUT_LABEL"
                    defaultMessage="{passcodeLength}-digit passcode"
                    description="This is the label for the passcode input field."
                    values={{ passcodeLength: PASSCODE_LENGTH }}
                  />
                </PasscodeTitleWrapper>
                <PasscodeInput
                  numberOfInputs={PASSCODE_LENGTH}
                  value={passcode}
                  onChange={(newValue) => setPasscode(newValue)}
                  onSubmit={handlePasscodeSubmit}
                  isDisabled={isTokenLoadingMyExtend || isTokenLoadingAuth}
                />
              </PasscodeInputWrapper>
            </form>
            <ResendCodeButtonWrapper isDisabled={isTokenLoadingMyExtend || isTokenLoadingAuth || isTimerRunning}>
              <Button
                data-cy="resend-code-link"
                emphasis="low"
                color="neutral"
                text={`I didn't receive a passcode ${
                  isTimerRunning ? `(0:${secondsLeft.toString().padStart(2, '0')})` : ''
                }`}
                onClick={handleResendPasscode}
                isDisabled={isTokenLoadingMyExtend || isTokenLoadingAuth || isTimerRunning}
              />
            </ResendCodeButtonWrapper>
          </Wrapper>
        </ParentContainer>
      )}
    </>
  )
}

const ParentContainer = styled.div({
  [bp.desktop]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
})

const Wrapper = styled.div<{ hasBorderRadius?: boolean }>(({ hasBorderRadius }) => ({
  [bp.desktop]: {
    backgroundColor: COLOR.WHITE,
    ...(hasBorderRadius && { borderRadius: '4px' }),
    width: '416px',
    height: '444px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '32px 24px 32px 24px',
  },
  [bp.mobile]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
  },
}))

const Title = styled.div({
  color: COLOR.BLACK,
  textAlign: 'center',
  fontWeight: 600,
  [bp.mobile]: {
    marginBottom: 16,
    fontSize: '28px',
    lineHeight: '36px',
  },
  [bp.desktop]: {
    marginTop: '16px',
    fontSize: '32px',
    lineHeight: '44px',
  },
})

const Content = styled.div({
  color: COLOR.NEUTRAL[800],
  textAlign: 'center',
  strong: {
    // hack to remove ts error, does not recognize 'anywhere'
    wordWrap: 'anywhere' as unknown as 'break-word',
  },
  [bp.mobile]: {
    margin: '8px 0 16px',
    fontSize: 16,
    lineHeight: '20px',
  },
  [bp.desktop]: {
    fontSize: '18px',
    lineHeight: '28px',
  },
})

const PasscodeInputWrapper = styled.div({
  color: COLOR.BLACK,
  textAlign: 'left',
  fontSize: '14px',
  [bp.mobile]: {
    margin: '8px 0 16px',
    lineHeight: '20px',
  },
  [bp.desktop]: {
    margin: '8px 0 32px',
    lineHeight: '28px',
  },
})

const PasscodeTitleWrapper = styled.div({
  fontWeight: 'bold',
})

const ChangeInputLink = styled(NavLink)({
  color: COLOR.BLACK,
  textDecoration: 'underline',
  cursor: 'pointer',
  '&.disabled': {
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
})

const ResendCodeButtonWrapper = styled.div<{ isDisabled: boolean }>(({ isDisabled }) => ({
  display: 'block',
  textAlign: 'center',
  lineHeight: '24px',
  marginTop: 'auto',
  paddingBottom: '16px',
  button: {
    color: isDisabled ? COLOR.NEUTRAL[500] : COLOR.BLACK,
    textDecoration: 'underline',
  },
}))

export { MyExtendAuthPasscodeInput }
