import React from 'react'
import { Stack, RowOptionGroup, RowOption, Button } from '@extend/zen'
import styled from '@emotion/styled'
import type { Claim } from '@customers-api-client'
import type { ReturnFulfillmentMethod } from '@extend-services/service-orders/dist/src/client/api-rest/v2/models'
import { TimelineFooter } from '../claim-timeline'
import { ReturnPaymentDetails } from './return-payment-details'
import { bp } from '../../../../lib/customers-ui'
import { FULFILLMENT_METHOD_LABELS } from './constants'

interface ReturnOptionsContentProps {
  selectedOption: ReturnFulfillmentMethod
  onOptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  displayPaymentDetails: boolean
  setDisplayPaymentDetails: (show: boolean) => void
  handleConfirmReturnClick: () => void
  claim: Claim
}

export const ReturnOptionsContent = ({
  selectedOption,
  claim,
  onOptionChange,
  displayPaymentDetails,
  setDisplayPaymentDetails,
  handleConfirmReturnClick,
}: ReturnOptionsContentProps): JSX.Element => {
  return (
    <Stack spacing={2} justify="start">
      <RowOptionGroup
        value={selectedOption}
        name="return_option"
        onChange={onOptionChange}
        data-cy="return-option-group"
      >
        <RowOption
          data-cy="instant-credit-option"
          value="store_credit"
          label={FULFILLMENT_METHOD_LABELS.store_credit}
          description="Use immediately online or in-store"
          badgeText="Best value"
        />
        <RowOption
          data-cy="refund-option"
          value="original_tender"
          label={FULFILLMENT_METHOD_LABELS.original_tender}
          description="3-5 business days once received"
        />
      </RowOptionGroup>

      <ReturnPaymentDetails
        claim={claim}
        displayPaymentDetails={displayPaymentDetails}
        setDisplayPaymentDetails={setDisplayPaymentDetails}
      />

      <TimelineFooter>
        <ButtonWrapper>
          <Button
            data-cy="confirm-return-options-button"
            text="Confirm return option"
            fillContainer
            emphasis="high"
            color="neutral"
            onClick={handleConfirmReturnClick}
          />
        </ButtonWrapper>
      </TimelineFooter>
    </Stack>
  )
}

export const ButtonWrapper = styled.div({
  [bp.mobile]: {
    width: '100%',
  },
  [bp.desktop]: {
    width: 'auto',
  },
})
