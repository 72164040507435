import { useState, useEffect, useRef } from 'react'
import type { ConsumerProfilePatchRequest, ConsumerProfileResponse } from '@customers-api-rtk-query'
import { usePatchConsumerProfileMutation } from '@customers-api-rtk-query'
import * as selectors from '../reducers/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { setConsumerProfile } from '@src/store/slices/my-extend'
import { getShippingAddress } from '@src/lib/consumer-profile-utils'

interface PatchProfileState {
  name?: string
  addressToAdd?: ConsumerProfileResponse['addresses'][0]
  addressToUpdate?: ConsumerProfilePatchRequest['addressOperations']['update']
  shouldSubmit?: boolean
}

interface UsePatchProfileReturn {
  patchState: PatchProfileState
  setName: (name: string) => void
  addAddress: (
    address: ConsumerProfileResponse['addresses'][0],
    previousAddress?: ConsumerProfileResponse['addresses'][0],
  ) => void
  resetState: () => void
  submitPatch: () => Promise<void>
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
}

export const useMyExtendPatchProfile = (): UsePatchProfileReturn => {
  const dispatch = useDispatch()
  const [patchProfile, { isLoading, isError, isSuccess }] = usePatchConsumerProfileMutation()
  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const accountShippingAddress = getShippingAddress(consumerProfile as ConsumerProfileResponse)
  const [patchState, setPatchState] = useState<PatchProfileState>({})
  const submitPromiseRef = useRef<{
    resolve: () => void
    reject: (error: unknown) => void
  }>()
  const mounted = useRef(true)

  useEffect(() => {
    mounted.current = true

    const submitPatch = async (): Promise<void> => {
      if (!patchState.shouldSubmit) return

      const body: ConsumerProfilePatchRequest = {}

      if (patchState.name) {
        body.name = patchState.name
      }

      if (patchState.addressToAdd) {
        body.addressOperations = {
          add: [
            {
              type: 'shipping',
              address1: patchState.addressToAdd.address1,
              address2: patchState.addressToAdd.address2,
              city: patchState.addressToAdd.city,
              countryCode: patchState.addressToAdd.countryCode,
              postalCode: patchState.addressToAdd.postalCode,
              provinceCode: patchState.addressToAdd.provinceCode,
              isPrimary: true,
            },
          ],
          update: patchState.addressToUpdate,
        }
      }

      try {
        if (Object.keys(body).length) {
          const result = await patchProfile({ body, id: consumerProfile?.profile?.id }).unwrap()
          if (mounted.current) {
            dispatch(setConsumerProfile(result))
          }
        }
        submitPromiseRef.current?.resolve()
      } catch (error) {
        submitPromiseRef.current?.reject(error)
      } finally {
        if (mounted.current) {
          resetState()
        }
      }
    }

    submitPatch()

    return () => {
      mounted.current = false
    }
  }, [patchState, patchProfile])

  const setName = (name: string): void => {
    if (mounted.current) {
      setPatchState((prev) => ({ ...prev, name }))
    }
  }

  const addAddress = (address: ConsumerProfileResponse['addresses'][0]): void => {
    if (mounted.current) {
      setPatchState((prev) => ({
        ...prev,
        addressToAdd: address,
        // set the old address to not primary if it exists
        ...(accountShippingAddress && {
          addressToUpdate: {
            [accountShippingAddress.hash]: {
              isPrimary: false,
            },
          },
        }),
      }))
    }
  }

  const resetState = (): void => {
    if (mounted.current) {
      setPatchState({})
    }
  }

  const submitPatch = async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      submitPromiseRef.current = { resolve, reject }
      if (mounted.current) {
        setPatchState((prev) => ({ ...prev, shouldSubmit: true }))
      }
    })
  }

  return {
    patchState,
    setName,
    addAddress,
    resetState,
    submitPatch,
    isLoading,
    isError,
    isSuccess,
  }
}
