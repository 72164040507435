import React from 'react'
import styled from '@emotion/styled'
import { Button, ChevronRight, COLOR, Stack } from '@extend/zen'
import { Search } from '../../lib/svgs/search'

interface MissingDataCardProps {
  buttonProps: {
    text: string
    handleButtonClick: () => void
    isDisabled?: boolean
    tooltip?: string
  }
  headerText: string
  children?: React.ReactNode
}

export const MissingDataCard = ({ buttonProps, headerText, children }: MissingDataCardProps): JSX.Element => {
  return (
    <Container data-cy="missing-data-content">
      <Stack align="center" justify="center" spacing={1}>
        <Search />
        <HeaderText data-cy="missing-data-content-header">{headerText}</HeaderText>
        <Content data-cy="missing-data-content-body">{children}</Content>
        <div>
          <Button
            data-cy="missing-data-content-primary"
            onClick={buttonProps.handleButtonClick}
            icon={ChevronRight}
            iconPosition="right"
            text={buttonProps.text}
            color="neutral"
            emphasis="low"
            tooltip={buttonProps.tooltip}
            isDisabled={buttonProps.isDisabled}
          />
        </div>
      </Stack>
    </Container>
  )
}

const Container = styled.div({
  width: '100%',
  padding: 25,
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 16,
})

const Content = styled.div({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  maxWidth: 500,
})

const HeaderText = styled.div({
  fontSize: 20,
  fontWeight: 400,
})
