import React from 'react'

export const Search = (): JSX.Element => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1255 31.8223C27.2156 32.9018 25.009 33.518 22.6585 33.518C15.3897 33.518 9.49707 27.6254 9.49707 20.3565C9.49707 13.0877 15.3897 7.19507 22.6585 7.19507C29.9274 7.19507 35.82 13.0877 35.82 20.3565C35.82 24.7783 33.6395 28.6907 30.2951 31.0772L31.6032 32.9454C32.8568 32.0677 34.5845 32.3723 35.4623 33.6259L41.8194 42.7048C42.6971 43.9584 42.3925 45.6861 41.1389 46.5638L40.0041 47.3585C38.7505 48.2362 37.0228 47.9316 36.1451 46.678L29.7879 37.5991C28.9102 36.3456 29.2148 34.6178 30.4684 33.7401L29.1255 31.8223ZM34.4346 20.3565C34.4346 26.8603 29.1623 32.1326 22.6585 32.1326C16.1548 32.1326 10.8825 26.8603 10.8825 20.3565C10.8825 13.8528 16.1548 8.58048 22.6585 8.58048C29.1623 8.58048 34.4346 13.8528 34.4346 20.3565ZM31.263 34.8749L32.3979 34.0803C33.0246 33.6414 33.8885 33.7938 34.3274 34.4205L40.6845 43.4995C41.1234 44.1262 40.9711 44.9901 40.3443 45.429L39.2094 46.2236C38.5827 46.6625 37.7188 46.5102 37.2799 45.8834L30.9228 36.8044C30.4839 36.1777 30.6362 35.3138 31.263 34.8749Z"
        fill="#404852"
      />
    </svg>
  )
}
