import React from 'react'
import type { Claim as ApiClientClaim, ServiceOrder, ClaimPhotosGetResponse } from '@customers-api-client'
import { isMobile } from 'react-device-detect'
import type { MerchantServicingSettings } from '@customers-api-rtk-query'
import styled from '@emotion/styled'
import { COLOR, ChevronRight, Button } from '@extend/zen'
import { bp } from '@customers-ui'
import { useNavigate } from 'react-router-dom'
import { ClaimStateMachine } from '../claim-state-machine'
import type { Claim } from '../../../types/claim'
import type { EmsProduct, EntitlementSearchByOrdersResponse } from '@customers-api-rtk-query'
import { images } from '../../../lib/assets'

export interface ProductProtectionClaimCardProps {
  claim: Claim | ApiClientClaim
  emsData?: EntitlementSearchByOrdersResponse
  serviceOrders?: ServiceOrder[]
  merchantServicingSettings?: MerchantServicingSettings
  photoData?: ClaimPhotosGetResponse
  handleResultModalOpen: () => void
}

const ProductProtectionClaimCard = ({
  claim,
  emsData,
  serviceOrders,
  merchantServicingSettings,
  photoData,
  handleResultModalOpen,
}: ProductProtectionClaimCardProps): JSX.Element => {
  const navigate = useNavigate()
  const { serviceType } = claim
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    event.currentTarget.src = images.fallbackImage
  }

  const handleReviewClaimPage = (): void => {
    navigate(`/review_claim?claimId=${claim.id}&sessionId=${claim.sessionId}`)
  }

  const product = emsData?.products?.find((p) => p.lineItemId === claim.products?.[0].lineItemId)

  return (
    <ClaimCardContainer data-cy="pp-claim-card-container" data-claim-id={claim.id}>
      <ClaimCardHeaderSection data-cy="pp-claim-card-header">
        <ProductImage product={product} onError={(e) => handleImageError(e)} data-cy="product-image" />
        <TitleButtonWrapper>
          <ClaimCardTitleContainer>
            <ClaimCardTitle data-cy="claim-card-title">
              {serviceType === 'replace' ? 'REPLACEMENT' : 'REPAIR'} CLAIM
            </ClaimCardTitle>
            <ProductTitle data-cy="product-name">{product?.title}</ProductTitle>
          </ClaimCardTitleContainer>
          <ClaimDetailsButtonContainer>
            <Button
              icon={ChevronRight}
              iconPosition="right"
              emphasis="low"
              color="neutral"
              onClick={handleReviewClaimPage}
              text={isMobile ? '' : 'Claim details'}
              data-cy="claim-details-button"
            />
          </ClaimDetailsButtonContainer>
        </TitleButtonWrapper>
      </ClaimCardHeaderSection>
      <ClaimCardDetailsSection onClick={(e) => e.stopPropagation()}>
        <ClaimStateMachine
          claim={claim as ApiClientClaim}
          merchantServicingSettings={merchantServicingSettings}
          serviceOrders={serviceOrders}
          emsData={emsData}
          photoData={photoData}
          handleResultModalOpen={handleResultModalOpen}
        />
      </ClaimCardDetailsSection>
    </ClaimCardContainer>
  )
}

const ProductImage = styled.img<{ product: EmsProduct | undefined }>(({ product }) => ({
  [bp.mobile]: {
    width: 56,
    minWidth: 56,
    height: 56,
    minHeight: 56,
    borderRadius: 4.667,
    background: `url(${product?.imageUrl}), lightgray 50% / cover no-repeat;`,
    backgroundSize: 'contain',
  },
  [bp.desktop]: {
    width: 96,
    minWidth: 96,
    height: 96,
    minHeight: 96,
    borderRadius: 16,
    background: `url(${product?.imageUrl}), lightgray -10.663px -9.243px / 123.34% 121.307% no-repeat;`,
    backgroundSize: 'contain',
  },
}))

const ClaimCardContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  borderRadius: 16,
  border: `1px solid var(--neutral-200, ${COLOR.NEUTRAL[200]})`,
  background: `var(--white, ${COLOR.WHITE})`,
  overflow: 'hidden',
  width: '100%',
})

const ClaimCardHeaderSection = styled.div({
  display: 'flex',
  alignSelf: 'stretch',
  borderBottom: `1px solid ${COLOR.NEUTRAL[200]}`,
  background: `var(--white, ${COLOR.WHITE})`,
  [bp.mobile]: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 16,
  },
  [bp.desktop]: {
    padding: 24,
    gap: 24,
    alignItems: 'center',
    flexDirection: 'row',
  },
})

const TitleButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
})

const ClaimDetailsButtonContainer = styled.div({
  alignItems: 'center',
})

const ClaimCardTitleContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 8,
  [bp.mobile]: {
    flex: '1 0 0',
    alignSelf: 'center',
  },
})

const ClaimCardTitle = styled.div({
  color: `var(--text-emphasis-low, ${COLOR.NEUTRAL[600]});`,
  fontFeatureSettings: `'clig' off, 'liga' off;`,
  fontFamily: 'Nunito Sans',
  fontWeight: 700,
  textTransform: 'uppercase',
  [bp.mobile]: {
    fontSize: 13,
    lineHeight: '16px',
    letterSpacing: 0.2,
  },
  [bp.desktop]: {
    fontSize: 14,
    lineHeight: '18px',
  },
})

const ProductTitle = styled.div({
  overflow: 'hidden',
  color: `var(--text-emphasis-high-default, ${COLOR.NEUTRAL[1000]});`,
  fontFeatureSettings: `'clig' off, 'liga' off;`,
  textOverflow: 'ellipsis',
  fontFamily: 'Nunito Sans',
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: 17,
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: 24,
    lineHeight: '32px',
  },
})

const ClaimCardDetailsSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 24,
  alignSelf: 'stretch',
  [bp.mobile]: {
    padding: '16px 16px 24px 16px',
    alignItems: 'center',
  },
  [bp.desktop]: {
    padding: '24px 24px 24px 144px',
    alignItems: 'flex-start',
  },
})

export { ProductProtectionClaimCard }
