import React from 'react'

const ForwardArrow = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <path id="a" d="M-0.000210526316 0L8.42105263 0 8.42105263 16 -0.000210526316 16z" />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-917 -739) translate(694 722) translate(55 7) translate(168 10) translate(6.79)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            fill="#FFF"
            d="M.421 16a.422.422 0 01-.298-.72L7.405 8 .123.72A.422.422 0 01.719.122L8.3 7.702a.422.422 0 010 .596l-7.58 7.58A.423.423 0 01.421 16"
            mask="url(#b)"
          />
        </g>
        <path
          fill="#FFF"
          d="M14.898 8.421H.523C.351 8.421.211 8.232.211 8c0-.232.14-.421.312-.421h14.375c.173 0 .313.189.313.421 0 .232-.14.421-.313.421"
          transform="translate(-917 -739) translate(694 722) translate(55 7) translate(168 10)"
        />
      </g>
    </svg>
  )
}

export default ForwardArrow
