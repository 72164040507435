import React from 'react'
import { Slide } from 'pure-react-carousel'
import styled from '@emotion/styled'
import { COLOR } from '@customers-ui'
import type { OrderCarouselPromptOption } from '@customers-api-client'
import { currency } from '@extend/client-helpers'
import { ChatCardImage } from './chat-card-image'
import { formatDate } from './utils'

interface ChatOrderCardProps {
  onClick: (message: string, value: string) => void
  option: OrderCarouselPromptOption
  index: number
}

const ChatOrderCard = ({ option, index, onClick }: ChatOrderCardProps): JSX.Element => {
  const { title, purchasePrice, purchaseDate, orderId, value, outputText, items, additionalItems } = option

  const formattedDate = formatDate(purchaseDate)
  const quantity = items.length + additionalItems

  const handleClick = (): void => {
    onClick(outputText || '', value)
  }

  return (
    <Slide index={index}>
      <SlideContent onClick={handleClick} data-cy="carousel-order">
        <OrderHeader>Shipping Protection</OrderHeader>
        <ItemsWrapper>
          {items.slice(0, 5).map((item) => (
            <ChatCardImage imageUrl={item.imageUrl} title={item.title} key={item.title} />
          ))}
        </ItemsWrapper>
        <OrderBodyWrapper>
          <OrderBodyDetails data-cy="order-body-details-quantity">{`${quantity} item${
            quantity > 1 ? 's' : ''
          } in order`}</OrderBodyDetails>
          <OrderTitle>{title}</OrderTitle>
          <OrderBodyDetails data-cy="order-body-details-quantity-date">Purchased {formattedDate}</OrderBodyDetails>
        </OrderBodyWrapper>
        <OrderFooterWrapper>
          <OrderNumber>
            <OrderDetailTitle>ORDER NUMBER</OrderDetailTitle>
            <OrderDetailValue data-cy="order-detail-value-orderId">{orderId}</OrderDetailValue>
          </OrderNumber>
          <OrderPurchasePrice>
            <OrderDetailTitle>PURCHASE PRICE</OrderDetailTitle>
            <OrderDetailValue data-cy="order-detail-value-purchasePrice">
              {currency.format(purchasePrice)}
            </OrderDetailValue>
          </OrderPurchasePrice>
        </OrderFooterWrapper>
      </SlideContent>
    </Slide>
  )
}

const SlideContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  borderRadius: 8,
  border: `1px solid ${COLOR.BLUE[800]}`,
  margin: '0px 8px',
  borderColor: COLOR.BLUE[700],
  height: 268,
  width: 295,
})

const OrderHeader = styled.div({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: COLOR.NEUTRAL[100],
  padding: '8px 16px 8px 16px',
  borderRadius: '8px 8px 0px 0px',
  color: COLOR.NEUTRAL[800],
  fontSize: 17,
  fontWeight: 700,
  lineHeight: '24px',
})

const ItemsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  padding: '18px 18.5px 18px 18.5px',
  gap: 8,
})

const OrderBodyWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 18.5px 20px 18.5px',
  lineHeight: '20px',
  gap: 4,
})

const OrderBodyDetails = styled.div({
  fontSize: 13,
  color: COLOR.NEUTRAL[700],
})

const OrderTitle = styled.div({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontSize: 15,
  color: COLOR.BLACK,
})

const OrderFooterWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  height: 63,
  margin: '0 16px 0 16px',
  paddingTop: 16,
  borderTop: `1px solid ${COLOR.NEUTRAL[200]}`,
})

const OrderNumber = styled.div({
  marginBottom: 8,
  marginRight: 16,
  maxWidth: 172,
  minWidth: 64,
})

const OrderPurchasePrice = styled.div({})

const OrderDetailTitle = styled.div({
  color: COLOR.NEUTRAL[600],
  fontSize: 8,
  fontWeight: 700,
  lineHeight: '10.91px',
})

const OrderDetailValue = styled.div({
  fontSize: 10,
  fontWeight: 400,
  lineHeight: '13.64px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
})

export type { ChatOrderCardProps }
export { ChatOrderCard }
