import * as pages from '../pages'

// Routes in this file use the ProtectedRoute component in types.ts

export const routes = [
  {
    path: '/my_plans/:id',
    element: pages.ContractDetailsPage,
  },
  {
    path: '/my_plans/:id/product/:lineItemId',
    element: pages.ContractDetailsPage,
  },
  {
    path: '/my_plans',
    element: pages.PlansPage,
  },
  {
    path: '/my_claims/file-claim',
    element: pages.FileClaimPage,
  },
  {
    path: '/my_claims',
    element: pages.ClaimsPage,
  },
  {
    path: '/my_claims/virtual_cards/:id',
    element: pages.VirtualCardFulfillmentMyExtend,
  },
  {
    path: '/profile',
    element: pages.UserProfilePage,
  },
  {
    path: '/error',
    element: pages.ErrorPage,
  },
  {
    path: '/review_claim',
    element: pages.ReviewClaimSubmissionPage,
  },
  {
    path: '/my_returns',
    element: pages.ReturnsPage,
  },
  {
    path: '/my_plans/:id/transfer',
    element: pages.ContractTransfer,
  },
]
