import React from 'react'
import type { FC } from 'react'
import { images } from '../../../lib/assets'
import type { BaseStateProps } from './types'
import { Image, StatusText, MessageBody } from './styles'

export const ReviewState: FC<BaseStateProps> = () => {
  return (
    <>
      <Image src={images.reviewIcon2} data-cy="review-claim-icon" />
      <StatusText data-cy="review-claim-status-text">Your claim is under review.</StatusText>
      <MessageBody data-cy="review-claim-message-body">
        We are reviewing your claim and will reach out via email soon.
      </MessageBody>
    </>
  )
} 