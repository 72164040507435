import styled from '@emotion/styled'
import { Button, ChevronRight, COLOR } from '@extend/zen'
import React from 'react'
import { bp } from '../../lib/customers-ui'

interface FooterBlockProps {
  children?: React.ReactNode
  headerText: string
  buttonText: string
  onButtonClick: () => void
  icon: React.ReactNode
}

export const FooterBlock = ({
  children,
  icon,
  headerText,
  buttonText,
  onButtonClick,
}: FooterBlockProps): JSX.Element => {
  return (
    <FooterBlockContainer data-cy="footer-block">
      {icon}
      <FooterBlockHeader data-cy="footer-block-header">{headerText}</FooterBlockHeader>
      {children}
      <div>
        <Button
          data-cy="footer-block-button"
          onClick={onButtonClick}
          icon={ChevronRight}
          iconPosition="right"
          text={buttonText}
          color="neutral"
          emphasis="low"
        />
      </div>
    </FooterBlockContainer>
  )
}

const FooterBlockContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: 25,
  gap: 10,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 16,
  backgroundColor: COLOR.NEUTRAL[100],
  height: 300,
  maxWidth: 366,
  minWidth: 244,
  [bp.mobile]: {
    width: '100%',
    maxWidth: 1000,
  },
  [bp.desktop]: {
    maxWidth: 366,
    minWidth: 244,
  },
})

const FooterBlockHeader = styled.div({
  fontSize: 20,
  fontWeight: 700,
})
