import type { ComponentProps } from 'react'
import React from 'react'
import { Input as ChakraInput } from '@chakra-ui/react'
import { COLOR } from '@extend/zen'

import { FormControl } from '../form-control'

interface InputProps extends ComponentProps<typeof ChakraInput> {
  className?: string
  dataQa?: string
  id?: string
  label?: string
  errorMessage?: string
  hint?: string
}

const Input = ({
  className,
  dataQa,
  id,
  label,
  errorMessage,
  isDisabled,
  isRequired,
  isInvalid,
  hint,
  ...props
}: InputProps): JSX.Element => {
  return (
    <FormControl
      {...{
        className,
        id,
        label,
        errorMessage,
        isDisabled,
        isInvalid,
        hint,
      }}
      isAsteriskVisible={isRequired}
    >
      <ChakraInput data-qa={dataQa} {...InputStyles} {...props} {...{ id, isDisabled, isInvalid }} />
    </FormControl>
  )
}

const InputStyles = {
  height: 40,
  borderRadius: 16,
  _focus: {
    border: `2px solid ${COLOR.BLUE[800]}`,
    _invalid: {
      border: `2px solid ${COLOR.RED[700]}`,
    },
  },
  _invalid: {
    border: `1px solid ${COLOR.RED[700]}`,
  },
  sx: {
    '&.chakra-input': { paddingInlineStart: 16, paddingInlineEnd: 16 },
  },
}

export { Input }
