import type { Dispatch, SetStateAction } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { COLOR } from '@customers-ui'
import { useMeasureRef, useScreenSize } from '../../../hooks'
import { images } from '../../../lib/assets'

export interface TestimonialProps {
  name: JSX.Element
  comment: JSX.Element
  setMaxHeight: Dispatch<SetStateAction<number>>
  maxHeight: number
  isLongestReview?: boolean
}

const Testimonial = ({ name, comment, setMaxHeight, maxHeight, isLongestReview }: TestimonialProps): JSX.Element => {
  const { measureRef, height } = useMeasureRef()
  const screenSize = useScreenSize()

  useEffect(() => {
    const totalVerticalPadding = screenSize === 'small' ? 46 : 68

    if (isLongestReview && height > 0) {
      setMaxHeight(height + totalVerticalPadding)
    }
  }, [height, isLongestReview, name, screenSize, setMaxHeight])
  return (
    <BubbleWrapper>
      <Bubble className="bubble" height={maxHeight}>
        <Arrow />
        <span ref={measureRef}>{comment}</span>
        <Author>
          <SmileEmoji alt="smile emoji" aria-label="smile emoji" src={images.smilingFaceEmoji} />
          &nbsp;
          {name}
        </Author>
      </Bubble>
    </BubbleWrapper>
  )
}

const SmileEmoji = styled.img({
  display: 'inline',
  height: 28,
  transform: 'translateY(4px)',
  [bp.lg]: {
    height: 34,
    transform: 'translateY(8px)',
  },
})

const BubbleWrapper = styled.div({
  height: 'auto',
  position: 'relative',
})

const Bubble = styled.div<{ height: number }>(
  {
    background: COLOR.WHITE,
    color: COLOR.NEUTRAL[700],
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    padding: '20px 36px',
    lineHeight: '30px',
    marginRight: 24,
    border: `1px solid ${COLOR.NEUTRAL[200]}`,
    position: 'absolute',
    fontSize: 16,
    borderRadius: 14,
    height: 'auto',
    width: 'calc(100% - 24px)',
    [bp.md]: {
      marginRight: 32,
      width: 'calc(100% - 32px)',
      fontSize: 19,
      marginLeft: 2,
      padding: '32px 41px',
    },
    [bp.lg]: {
      fontSize: 20,
    },
  },
  ({ height }) => ({
    height,
  }),
)

const Arrow = styled.div({
  borderStyle: 'solid',
  position: 'absolute',
  borderColor: `${COLOR.NEUTRAL[200]} transparent transparent transparent`,
  borderWidth: '21px 21px 0px 21px',
  bottom: -21,
  '&:after': {
    borderColor: `${COLOR.WHITE} transparent transparent transparent`,
    borderStyle: 'solid',
    borderWidth: '20px 20px 0px 20px',
    bottom: '1px',
    content: '""',
    position: 'absolute',
    left: -20,
  },
})

const Author = styled.div({
  bottom: -59,
  left: 45,
  position: 'absolute',
  fontWeight: 800,
  fontSize: 18,
  color: COLOR.BLACK,
  [bp.md]: {
    fontSize: 20,
    left: 49,
    bottom: -61,
  },
  [bp.lg]: {
    left: 45,
    bottom: -63,
    fontSize: 22,
  },
})
export { Testimonial }
