import React from 'react'

export const PackageCheckmark = (): JSX.Element => {
  return (
    <svg height="62" viewBox="0 0 68 62" width="68" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="m55.8796088 22.3645328c-.0131264-.0240632-.0131264-.0503104-.0284368-.0721896l-10.26592-15.86592h-.0021896c-.1706264-.2668736-.4659368-.4265352-.783104-.4265352h-33.6c-.3171896 0-.612528.1596616-.783104.4265352l-10.26591929 15.86592c-.01531208.0218736-.01531208.0481264-.02843736.0721896-.03062528.0568736-.0525.1159424-.06999775.175-.01968736.0590632-.03062528.1203104-.03718736.1837472-.00218753.0262472-.01531264.0503104-.01531264.0765632v38.2659189c0 .2493736.09843679.4856262.27343679.6606318s.41125269.2734368.66063189.2734368h54.13184332c.2493736 0 .4856264-.0984368.660632-.2734368s.2734088-.4112526.2734088-.6606318v-38.2659189c0-.0262528-.0130984-.050316-.015288-.0765632-.0131824-.1246896-.0503664-.245-.105056-.3587472zm-3.37988-.6224275h-17.9384243l-1.5615757-13.3421053h10.9125548zm-19.834136 1.1124387v5.66776l-1.347472-.89908c-.315-.21-.721896-.21-1.036896 0l-2.281552 1.520288-2.281552-1.520288c-.315-.21-.721896-.21-1.036896 0l-1.347472.89908v-5.66776l1.763104-14.98896h5.80552zm-21.0082278-14.654544h11.1923638l-1.6016161 13.6127023h-18.39838386zm41.8423638 51.3576923h-50.99999996v-35.3076923h19.12445256v6.3380025c0 .3331308.185693.6386835.4823788.7978398.2945494.157016.6552585.1400411.934858-.0466823l2.2261958-1.4746665 2.2261957 1.4746665c.3073573.2036983.704381.2036983 1.0117383 0l2.2261957-1.4746665 2.2261958 1.4746665c.2796104.1867234.6403414.2036983.9348579.0466823.2966859-.15914.4823789-.4646873.4823789-.7999637v-6.3358786h19.1245525z"
          fill="#224bc5"
        />
        <g stroke="#224bc5" strokeLinecap="round" strokeWidth="1.936508">
          <path d="m15.662218 42.443783h23.952915" />
          <path d="m15.662218 47.325397h23.952915" />
          <path d="m15.662218 52.207011h23.952915" />
        </g>
        <g transform="translate(40.48121)">
          <path
            d="m14.2592593 26.5185185c7.8751714 0 11.7409312-5.2227903 11.7409312-13.0979618 0-7.87517141-3.8657598-13.09796177-11.7409312-13.09796177-7.87517147 0-14.2592593 4.0614929-14.2592593 11.93666437 0 7.8751714 6.38408783 14.2592592 14.2592593 14.2592592z"
            fill="#fff"
          />
          <g stroke="#27aee4" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.776986">
            <path d="m3 12.3677801c0-6.27728818 5.09049192-11.3677801 11.3677801-11.3677801 6.2772882 0 11.3677801 5.09049192 11.3677801 11.3677801 0 6.2772882-5.0904919 11.3677801-11.3677801 11.3677801-6.27728818 0-11.3677801-5.0904919-11.3677801-11.3677801z" />
            <path d="m7 12.547112 4.547112 4.5471121 9.0942241-9.0942241" />
          </g>
        </g>
      </g>
    </svg>
  )
}
