import React from 'react'

export const CircleCheckmark = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="22" height="22">
      <g fill="none" fillRule="evenodd">
        <circle fill="#224BC5" cx={11} cy={11} r={11} />
        <path stroke="#FFF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" d="M7 11.2l3 3.5 5-7.2" />
      </g>
    </svg>
  )
}
