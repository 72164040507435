import React from 'react'
import type { FC, ReactElement } from 'react'
import { images } from '../../../lib/assets'
import type { BaseStateProps } from './types'
import { Image, StatusText, MessageBody } from './styles'
import type { Claim } from '../../../types/claim'

const getDenialStatusText = (claim: Claim): string => {
  if (claim?.validationReason === 'shipping_delivery_waiting_period') {
    return 'Please wait to file your claim.'
  }

  return 'Your claim is denied.'
}

const getDenialMessage = (claim: Claim, storeName?: string): ReactElement => {
  if (claim?.validationReason === 'mfr_warranty_active') {
    return (
      <span>
        Your claim should still be covered under the product manufacturer warranty. Contact{' '}
        {storeName ?? 'manufacturer'} for help.
      </span>
    )
  }

  if (claim?.validationReason === 'shipping_delivery_waiting_period') {
    return (
      <span>
        Lost or stolen shipments are often delayed and will show up within 5 days of reported delivery date. Please come
        back to file another claim if it has still not arrived after 5 days.
      </span>
    )
  }

  return (
    <span>
      We are unable to fulfill your claim.<br></br>Please reach out with further questions.
    </span>
  )
}

export const DeniedState: FC<BaseStateProps> = ({ claim, storeName }) => {
  return (
    <>
      <Image src={images.deniedIcon2} data-cy="denied-claim-icon" />
      <StatusText data-cy="denied-claim-status-text">{getDenialStatusText(claim)}</StatusText>
      <MessageBody data-cy="denied-claim-message-body">
        {getDenialMessage(claim, storeName)}
      </MessageBody>
    </>
  )
} 