import { useGetMerchantServicingSettingsQuery } from '@customers-api-rtk-query'
import type { MerchantServicingSettings } from '@customers-api-rtk-query'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'

interface UseMerchantServicingSettingsReturn {
  data: MerchantServicingSettings | undefined
  isLoading: boolean
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}

/**
 * Hook to fetch merchant servicing settings for a given seller ID.
 * @param sellerId - The ID of the seller to fetch settings for.
 * @returns An object containing the fetched data, loading state, and error state.
 */
export const useMerchantServicingSettings = (sellerId?: string): UseMerchantServicingSettingsReturn => {
  const { data, isLoading, isError, error } = useGetMerchantServicingSettingsQuery(
    { sellerId: sellerId ?? '' },
    { skip: !sellerId || sellerId === 'extend' },
  )

  return {
    data,
    isLoading,
    isError,
    error,
  }
}
