import React from 'react'

const ConvenientSupportHeart = (): JSX.Element => {
  return (
    <svg width="56" height="52" viewBox="0 0 56 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1392 50.9361L28 49.8033C29.1392 50.9361 29.14 50.9353 29.14 50.9353L29.87 50.2C30.3384 49.7278 31.0134 49.0468 31.8399 48.2111C33.4927 46.5399 35.7517 44.2499 38.1764 41.7751C43.0144 36.8372 48.5474 31.1273 51.22 28.145C53.9571 25.0924 56 21.1417 56 16.5246C56 7.39797 48.602 0 39.4754 0C35.0161 0 30.9702 1.76912 28 4.63896C25.0298 1.76912 20.9839 0 16.5246 0C7.39797 0 0 7.39797 0 16.5246C0 21.1417 2.04289 25.0924 4.77996 28.145C7.45255 31.1273 12.9856 36.8372 17.8236 41.7751C20.2483 44.2499 22.5073 46.5399 24.1601 48.2111C24.9866 49.0468 25.6616 49.7278 26.13 50.2L26.86 50.9353C26.86 50.9353 26.8608 50.9361 28 49.8033L26.8608 50.9361C27.1624 51.2393 27.5724 51.4098 28 51.4098C28.4276 51.4098 28.8376 51.2393 29.1392 50.9361ZM29.5553 45.9517C28.9474 46.5664 28.4216 47.0972 28 47.5226C27.5784 47.0972 27.0526 46.5664 26.4447 45.9517C24.7943 44.2829 22.5389 41.9967 20.1187 39.5265C15.2668 34.5743 9.7881 28.919 7.17282 26.0006C4.81038 23.366 3.21311 20.1466 3.21311 16.5246C3.21311 9.17252 9.17252 3.21311 16.5246 3.21311C20.6418 3.21311 24.3203 5.08217 26.7642 8.02425C27.0694 8.3917 27.5223 8.60426 28 8.60426C28.4777 8.60426 28.9306 8.3917 29.2358 8.02425C31.6797 5.08217 35.3582 3.21311 39.4754 3.21311C46.8275 3.21311 52.7869 9.17252 52.7869 16.5246C52.7869 20.1466 51.1896 23.366 48.8272 26.0006C46.2119 28.919 40.7332 34.5743 35.8813 39.5265C33.4611 41.9967 31.2057 44.2829 29.5553 45.9517Z"
        fill="#0033CC"
      />
      <path
        d="M7.46945 17.7975C7.46945 12.1034 12.1032 7.4696 17.7973 7.4696"
        stroke="#0033CC"
        strokeWidth="3.21311"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { ConvenientSupportHeart }
