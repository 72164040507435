import React from 'react'
import { Button, ButtonGroup, GenericField, Input, InputType } from '@extend/zen'
import { formatPhoneNumberOnChange } from '@extend/client-helpers'
import { LDFlag } from '@src/constants/ld-flags'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { AddressInput } from '../../components/common/address-input'
import type { ClaimAddress } from '../../types/claim'
import { useMyExtendPatchProfile } from '../../hooks/use-myextend-patch-profile'
import { useFormik } from 'formik'
import { schema } from './schema'

export interface FormValues {
  name: string
  phoneNumber: string
  email: string
  shippingAddress: ClaimAddress
}

interface ProfileEditingViewProps {
  name: string | null
  phoneNumber: string | null
  email: string | null
  shippingAddress: ClaimAddress | string | null
  isEmailLogin: boolean
  onCancel: () => void
  onComplete: (values: FormValues) => void
}

const ProfileEditingView = ({
  name,
  phoneNumber,
  email,
  shippingAddress,
  isEmailLogin,
  onCancel,
  onComplete,
}: ProfileEditingViewProps): JSX.Element => {
  const { [LDFlag.UseAuthPopa]: FF_USE_AUTH_POPA } = useFlags()
  const { addAddress, setName, submitPatch, isLoading } = useMyExtendPatchProfile()

  const handleSave = async (): Promise<void> => {
    const formValues = form.values as FormValues

    if (formValues.name) {
      setName(formValues.name)
    }

    if (formValues.shippingAddress) {
      addAddress(formValues.shippingAddress)
    }

    await submitPatch()
    onComplete(formValues)
  }

  const form = useFormik({
    validationSchema: schema,
    initialValues: {
      name: name ?? '',
      phoneNumber: phoneNumber ?? '',
      email: email ?? '',
      // getAccountInfo returns 'None' if the profile has no shipping address
      shippingAddress: typeof shippingAddress === 'string' ? undefined : shippingAddress,
    },
    onSubmit: handleSave,
  })

  const handleAddressChange = (address: ClaimAddress): void => {
    form.setFieldValue('shippingAddress', address, true)
    addAddress(address)
  }

  // Show verify text if there is no existing accountInfo value for email or phone and one was entered or
  // an existing value exists and the value entered is different
  const isVerificationNeeded =
    (!email && !!form.values.email) ||
    (!!email && email !== form.values.email) ||
    (!phoneNumber && !!form.values.phoneNumber) ||
    (!!phoneNumber && phoneNumber !== form.values.phoneNumber)

  return (
    <>
      <Input
        id="name"
        data-cy="account-name-input"
        label="Name"
        value={form.values.name}
        onChange={form.handleChange}
        isError={Boolean(form.errors.name && form.touched.name)}
        errorFeedback={form.errors.name}
        onBlur={form.handleBlur}
      />
      {FF_USE_AUTH_POPA ? (
        <GenericField label="Phone Number">{form.values.phoneNumber}</GenericField>
      ) : (
        <Input
          id="phoneNumber"
          data-cy="account-phone-number-input"
          label="Phone Number"
          isDisabled={isEmailLogin}
          value={
            form.values.phoneNumber.length > 4
              ? formatPhoneNumberOnChange(form.values.phoneNumber, 'US')
              : form.values.phoneNumber
          }
          type={InputType.tel}
          onChange={(e) => {
            const { value } = e.target
            form.setFieldValue('phoneNumber', value)
          }}
          isError={Boolean(form.errors.phoneNumber && form.touched.phoneNumber)}
          errorFeedback={form.errors.phoneNumber}
          onBlur={form.handleBlur}
        />
      )}
      {FF_USE_AUTH_POPA ? (
        <GenericField label="Email">{form.values.email}</GenericField>
      ) : (
        <Input
          id="email"
          data-cy="account-email-input"
          isDisabled={isEmailLogin}
          label="Email"
          value={form.values.email}
          onChange={form.handleChange}
          isError={Boolean(form.errors.email && form.touched.email)}
          errorFeedback={form.errors.email}
          onBlur={form.handleBlur}
        />
      )}
      <AddressInput onAddressChange={handleAddressChange} />
      <ButtonGroup justify="space-between">
        <Button emphasis="low" text="Cancel" data-cy="cancel-button" onClick={onCancel} />
        <Button
          emphasis="high"
          text={`${isVerificationNeeded ? 'Verify & ' : ''}Save`}
          data-cy="save-button"
          onClick={handleSave}
          isDisabled={!form.dirty}
          isProcessing={isLoading}
        />
      </ButtonGroup>
    </>
  )
}
export { ProfileEditingView }
