import React from 'react'

export const PeaceOfMindImage = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="3"
        transform="translate(-143 -1551) translate(145 1553)"
      >
        <path
          stroke="#224BC5"
          strokeLinecap="round"
          d="M18.231 24.58c1.075-1.072 1.894-2.99 1.894-4.625 0-3.597-2.925-6.512-6.534-6.512-1.722 0-3.348.673-4.603 1.923"
          transform="rotate(135 14.556 19.011)"
        />
        <path
          stroke="#224BC5"
          strokeLinecap="round"
          d="M38.277 24.58c1.075-1.072 1.893-2.99 1.893-4.625 0-3.597-2.925-6.512-6.533-6.512-1.722 0-3.348.673-4.603 1.923"
          transform="rotate(135 34.602 19.011)"
        />
        <path
          stroke="#27AEE4"
          strokeLinecap="round"
          d="M27.44 37.864c.86-.858 1.515-2.391 1.515-3.7 0-2.877-2.34-5.21-5.227-5.21a5.174 5.174 0 00-3.683 1.54"
          transform="rotate(135 24.5 33.41)"
        />
        <circle cx="24.5" cy="24.5" r="24.5" stroke="#224BC5" />
      </g>
    </svg>
  )
}
