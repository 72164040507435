import type { EmsProduct } from '@extend-myextend/types/dist/src/services/api/types'
import { formatDate } from './helper-functions'
import { getOrderProducts } from './ems-utils'
import type { OrdersListByShipmentsResponse } from '@customers-api-rtk-query'

export const getMultipleProductsHeader = (products: EmsProduct[]) => {
  const productCount = products.length
  if (productCount === 1) {
    return `${products[0].title}`
  } else if (productCount === 2) {
    return `${products[0].title} and 1 item`
  } else if (productCount > 2) {
    return `${products[0].title} and ${productCount - 1} items`
  } else {
    return null
  }
}

export const getReturnSubHeading = (order: OrdersListByShipmentsResponse[0]) => {
  const { transactionId, transactionDate, hasShipmentInfo, trackedProducts } = order
  const products = getOrderProducts(order)
  if (hasShipmentInfo) {
    return `Order # ${transactionId} · Purchased ${formatDate(transactionDate || 0)} · ${
      Object.entries(trackedProducts || {})?.length ?? 0
    } shipment(s) · ${products?.length} item(s)`
  }

  return `Order # ${transactionId} · Purchased ${formatDate(transactionDate || 0)} · ${products?.length} item(s)`
}
