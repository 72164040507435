import type { MerchantConfiguration } from '../types/merchant-configuration'
import type { MerchantBrandingResponse } from '@extend-services/service-orders/dist/src/client/api-rest/v2/models'
import { images } from '../lib/assets'

export const extendConfiguration: MerchantConfiguration = {
  sellerId: 'extend',
  isExtend: true,
  subdomainName: 'extend',
  merchantWebsite: 'https://extend.com',
  merchantSupportUrl: undefined,
  hideExtendBranding: false,
  isEnabled: true,
  brandingConfigurations: {
    font: 'Arial' as MerchantConfiguration['brandingConfigurations']['font'],
    primaryColor: '#000000',
    assetsUrls: {
      logo: images.extendLogoWhiteBlue,
      loginBackground: images.extendLoginBackground,
      favicon: images.favicon,
    },
  },
}

export function buildMerchantConfiguration(branding: MerchantBrandingResponse): MerchantConfiguration {
  return {
    sellerId: branding.sellerId,
    isExtend: false,
    subdomainName: branding.subdomainName,
    merchantWebsite: branding.merchantWebsite,
    merchantSupportUrl: branding.merchantSupportUrl,
    hideExtendBranding: branding.hideExtendBranding ?? false,
    isEnabled: branding.isEnabled,
    brandingConfigurations: {
      font: branding.brandingConfigurations.font,
      primaryColor: branding.brandingConfigurations.primaryColor,
      assetsUrls: {
        logo: `${branding.brandingConfigurations.assetBucketUrl}${branding.sellerId}/logo.png`,
        loginBackground: `${branding.brandingConfigurations.assetBucketUrl}${branding.sellerId}/loginBackground.png`,
        favicon: `${branding.brandingConfigurations.assetBucketUrl}${branding.sellerId}/favicon.png`,
      },
    },
  }
}
