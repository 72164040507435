import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Button, COLOR, HeadingLarge } from '@extend/zen'
import { bp } from '@customers-ui'

import type { CardProps } from './types'
import { CardMenuItems } from './card-menu-items'
import { CardGroups } from './card-groups'
export const Card: FC<CardProps> = ({
  'data-cy': dataCy,
  dataProps,
  className,
  extendLiveOverride,
  color = 'neutral',
  preHeading,
  heading,
  subHeading,
  headerBackground,
  borderRadius = '16px',
  primaryButtonProps,
  menuItems,
  primaryGroups,
  secondaryGroups,
  groupShowMoreThreshold = 3,
  showMoreGroupsText = 'View All',
  showLessGroupsText = 'View Less',
  children,
  hasBodyPadding = true,
}) => {
  return (
    <CardWrapper
      data-cy={extendLiveOverride || `${dataCy}-card-wrapper`}
      {...dataProps}
      className={className}
      borderRadius={borderRadius}
    >
      {heading && (
        <HeaderSection
          data-cy={`${dataCy}-header-section`}
          data-extend-live={`${dataCy}-header-section`}
          headerBackground={headerBackground}
        >
          <HeaderDetailsLeft>
            {preHeading && <PreHeader data-cy={`${dataCy}-preheading`}>{preHeading}</PreHeader>}
            {typeof heading === 'string' ? (
              <HeadingLarge data-cy={`${dataCy}-heading`}>{heading}</HeadingLarge>
            ) : (
              heading
            )}
            {subHeading && <SubHeader data-cy={`${dataCy}-subheading`}>{subHeading}</SubHeader>}
          </HeaderDetailsLeft>
          {(primaryButtonProps || menuItems) && (
            <HeaderDetailsRight>
              {primaryButtonProps && (
                <Button
                  fillContainer
                  {...primaryButtonProps}
                  color={color}
                  data-cy={`${dataCy}-header-button-primary`}
                />
              )}
              {menuItems && menuItems.length > 0 && (
                <CardMenuItems
                  data-cy={`${dataCy}-header-menu-items`}
                  menuKey={'header'}
                  color={color}
                  menuItems={menuItems}
                />
              )}
            </HeaderDetailsRight>
          )}
        </HeaderSection>
      )}
      <BodySection data-cy={`${dataCy}-body-section`} hasBodyPadding={hasBodyPadding}>
        {primaryGroups && primaryGroups.length > 0 && (
          <CardGroups
            color={color}
            data-cy={`${dataCy}-primary-groups`}
            groups={primaryGroups}
            showMoreThreshold={groupShowMoreThreshold}
            showMoreText={showMoreGroupsText}
            showLessText={showLessGroupsText}
          />
        )}
        {secondaryGroups && secondaryGroups.length > 0 && (
          <CardGroups
            color={color}
            data-cy={`${dataCy}-secondary-groups`}
            groups={secondaryGroups}
            showMoreThreshold={groupShowMoreThreshold}
            showMoreText={showMoreGroupsText}
            showLessText={showLessGroupsText}
          />
        )}
        {children}
      </BodySection>
    </CardWrapper>
  )
}

const CardWrapper = styled.div<{ borderRadius: string }>(({ borderRadius }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius,
  border: '1px solid',
  borderColor: COLOR.NEUTRAL[300],
  overflow: 'hidden',
}))

// Header Styles
const HeaderSection = styled.div<{ headerBackground?: string }>(({ headerBackground }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignSelf: 'stretch',
  borderBottom: headerBackground ? 'none' : '1px solid',
  borderColor: COLOR.NEUTRAL[300],
  padding: '24px',
  overflow: 'hidden',
  [bp.mobile]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ...(headerBackground && {
    background: headerBackground,
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  }),
}))

const HeaderDetailsLeft = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  flex: 1,
})

const PreHeader = styled.div({
  display: 'flex',
  alignSelf: 'flex-start',
  alignItems: 'center',
  gap: '8px',
})

const SubHeader = styled.h4({
  color: COLOR.NEUTRAL[1000],
})

const HeaderDetailsRight = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  justifyContent: 'flex-end',
  width: 'auto',
  [bp.mobile]: {
    width: '100%',
  },
  [bp.desktop]: {
    width: 'auto',
  },
})

// Body Styles
const BodySection = styled.div<{ hasBodyPadding: boolean }>(({ hasBodyPadding }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  ...(hasBodyPadding && {
    padding: '24px',
  }),
}))
