import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import images from '../../../../../lib/images'

interface ChatCardImageProps {
  imageUrl?: string
  title: string
}

const ChatCardImage = ({ imageUrl, title }: ChatCardImageProps): JSX.Element => {
  const [isDefaultImage, setIsDefaultImage] = useState(false)

  useEffect(() => {
    if (!imageUrl) {
      setIsDefaultImage(true)
    }
  }, [imageUrl])

  const handleImageError = (): void => {
    setIsDefaultImage(true)
  }

  return (
    <Wrapper>
      {isDefaultImage ? (
        <DefaultImage>
          <img src={images.defaultCarouselImage} alt="Placeholder default" />
        </DefaultImage>
      ) : (
        <ProductImage>
          <img src={imageUrl} alt={title} onError={handleImageError} />
        </ProductImage>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  height: 45,
  width: 45,
})

const Img = styled.div({
  maxWidth: 45,
  display: 'flex',
  alignItems: 'center',
  '> img': {
    height: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
    width: 'auto',
    borderRadius: 4,
  },
})

const DefaultImage = styled(Img)({
  borderRadius: 4,
})

const ProductImage = styled(Img)({
  alignItems: 'center',
  borderRadius: 4,
})

export type { ChatCardImageProps }
export { ChatCardImage }
