import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR, HeadingSmall, Button, ModalController } from '@extend/zen'
import { bp } from '@customers-ui'
import { PageWrapper, ContentSection } from '../../components/common'
import { useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import * as selectors from '../../reducers/selectors'
import { getAccountInfo } from '../../lib/consumer-profile-utils'
import { logEvent } from '../../analytics'
import { LDFlag } from '../../constants/ld-flags'
import type { UserInfo } from './verification-modal/verification-modal'
import { VerificationModal } from './verification-modal/verification-modal'
import { ProfilePresenterView } from './profile-presenter-view'
import { ProfileEditingView } from './profile-editing-view'

const UserProfilePage = (): JSX.Element => {
  const navigate = useNavigate()
  const { [LDFlag.ProfileEditing]: FF_PROFILE_EDITING_ENABLED, [LDFlag.UseAuthPopa]: FF_USE_AUTH_POPA } = useFlags()
  const [verificationType, setVerificationType] = useState<'off' | 'phone' | 'email'>('off')
  const [isEditing, setIsEditing] = useState(false)
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined)
  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const accountInfo = getAccountInfo(consumerProfile)

  const { name, phoneNumber, email, shippingAddress, hasAccountInfo } = accountInfo

  const decodedToken = useSelector(selectors.getDecodedAccessToken)
  const isEmailLogin = decodedToken?.cid === 'consumerPortalViaEmailCode'

  const routeToPlans = (): void => {
    logEvent('Claims - Clicks', 'View My Plans')
    navigate('/my_plans')
  }

  const handleVerifyClick = (isEmail: boolean): void => {
    setVerificationType(isEmail ? 'email' : 'phone')
  }

  const handleCompleteVerification = (): void => {
    setVerificationType('off')
    setUserInfo(undefined)
    setIsEditing(false)
  }

  const handleFormCancelClick = (): void => {
    setUserInfo(undefined)
    setIsEditing(false)
  }

  return (
    <>
      <ModalController isOpen={verificationType !== 'off'}>
        <VerificationModal
          isEditVerification={isEditing}
          userInfo={userInfo}
          isEmail={verificationType === 'email'}
          handleComplete={handleCompleteVerification}
          handleClose={() => setVerificationType('off')}
        />
      </ModalController>
      <PageWrapper>
        <ContentSection>
          <AccountHeader>My Account</AccountHeader>
          {hasAccountInfo && (
            <SectionWrapper>
              <SmallHeading>Contact Information</SmallHeading>
              {isEditing ? (
                <ProfileEditingView
                  name={name}
                  phoneNumber={phoneNumber}
                  email={email}
                  shippingAddress={shippingAddress}
                  isEmailLogin={isEmailLogin}
                  onCancel={handleFormCancelClick}
                  onComplete={(values) => {
                    if (!FF_USE_AUTH_POPA) {
                      setUserInfo({
                        email: isEmailLogin ? '' : values.email,
                        phoneNumber: isEmailLogin ? values.phoneNumber : '',
                      })
                      setVerificationType(isEmailLogin ? 'phone' : 'email')
                    }
                    setIsEditing(false)
                  }}
                />
              ) : (
                <ProfilePresenterView
                  accountInfo={accountInfo}
                  onEditClick={() => setIsEditing(true)}
                  onVerifyClick={handleVerifyClick}
                  isProfileEditingEnabled={FF_PROFILE_EDITING_ENABLED}
                />
              )}
            </SectionWrapper>
          )}
          <ButtonWrapper>
            <Button emphasis="low" data-cy="view-my-plans-button" onClick={routeToPlans} text="View My Plans" />
          </ButtonWrapper>
        </ContentSection>
      </PageWrapper>
    </>
  )
}

const AccountHeader = styled.div({
  fontWeight: 700,
  [bp.desktop]: {
    fontSize: '28px',
    lineHeight: '36px',
  },
  [bp.mobile]: {
    fontSize: '20px',
    lineHeight: '24px',
  },
})

const SmallHeading = styled(HeadingSmall)({
  [bp.mobile]: {
    fontSize: '17px',
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
})

const SectionWrapper = styled.div({
  borderRadius: '16px',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  display: 'flex',
  padding: '16px',
  flexDirection: 'column',
  gap: 'var(--field-border-radius, 8px)',
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export { UserProfilePage }
