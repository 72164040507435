import { EXTEND_ENV } from '@helloextend/client-constants'
import React from 'react'
import { connect } from 'react-redux'
import { COLOR, Spinner, bp } from '@customers-ui'
import { getRTKQueryErrorMessage, useListOrdersQuery, useGetMyClaimsQuery } from '@customers-api-rtk-query'
import type { OrdersListByOrders } from '@customers-api-rtk-query'
import styled from '@emotion/styled'
import type { RootState } from '../../reducers'
import * as selectors from '../../reducers/selectors'
import { ErrorComponent } from './error-component'
import type { Claim } from '../../types/claim'
import { isEmpty } from 'lodash'
import { mapToClaimDetails } from './utils'
import { ViewAllClaims } from './view-all-claims'
import { EmptyPageContent } from '../plans-page/empty-page-content'
import { PageWrapper, ContentSection } from '../../components/common'

const Component = (): JSX.Element => {
  const { data: fetchedClaims, isLoading: isLoadingClaims, error: claimsQueryError } = useGetMyClaimsQuery()

  // Accounts in lower environments can potentially have hundreds of claims, and each claim requires a request to get service orders
  // and a request to get merchant servicing settings. This will limit those accounts to 10 displayed claims.
  let claims = [...(fetchedClaims ?? [])].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )
  if (EXTEND_ENV !== 'production' && fetchedClaims) {
    claims = claims.slice(0, 10)
  }

  const { data, isLoading: isLoadingEmsData, error: listOrdersQueryError } = useListOrdersQuery({})

  const emsOrdersResponse = data as OrdersListByOrders
  const claimDetails = mapToClaimDetails((claims as unknown as Claim[]) ?? [], emsOrdersResponse ?? [])
  const hasAtLeastOneMatchingOrder = Object.values(claimDetails).some((claimDetail) => claimDetail.emsData)

  return (
    <PageWrapper pageTitle="Claims">
      <ErrorComponent
        claimsError={claimsQueryError && getRTKQueryErrorMessage(claimsQueryError)}
        emsError={listOrdersQueryError && getRTKQueryErrorMessage(listOrdersQueryError)}
      />
      <ContentSection>
        {!isLoadingClaims && !isLoadingEmsData && (
          <ClaimsPageHeader data-cy="claims-page-header">Claims</ClaimsPageHeader>
        )}
        {isLoadingClaims || isLoadingEmsData ? (
          <SpinnerLoading data-cy="spinner-loading">
            <Spinner size="md" />
          </SpinnerLoading>
        ) : isEmpty(claims) || !hasAtLeastOneMatchingOrder ? (
          <EmptyPageContent
            primaryButtonText="View My Orders"
            primaryButtonUrl="/my_plans"
            headerText="No claims found."
          >
            To start a claim, go to the <b>My Orders</b> tab and select the order you need assistance with.
          </EmptyPageContent>
        ) : (
          <ViewAllClaims claimDetails={claimDetails} />
        )}
      </ContentSection>
    </PageWrapper>
  )
}

const ClaimsPageHeader = styled.div({
  fontWeight: 800,
  color: COLOR.NEUTRAL[1000],
  fontSize: '28px',
  [bp.desktop]: {
    fontSize: '44px',
  },
})

export const SpinnerLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '50px 20px 0',
})

export const EmptyPage = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  [bp.mobile]: {
    margin: '32px 10px 40px',
  },
  [bp.desktop]: {
    margin: '56px 156px 24px',
    width: '1134px',
  },
})

export const EmptyPageContainer = styled.div({
  alignContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
})

const ClaimsPage = connect((state: RootState) => ({
  decodedAccessToken: selectors.getDecodedAccessToken(state),
}))(Component)

export { Component, ClaimsPage }
