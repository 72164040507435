import { useState, useEffect } from 'react'
import type { ServiceOrder } from '@customers-api-rtk-query'
import { useGetServiceOrdersByClaimQuery } from '@customers-api-rtk-query'
import type { Claim } from '../types/claim'

const NO_POLL_CLAIM_STATUSES = ['pending_adjudication', 'denied', 'review', 'fulfilled', 'closed']
const NO_POLL_SERVICE_ORDER_STATUSES = ['payment_approved', 'closed', 'paid']
const TEN_SECOND_POLLING_INTERVAL = 10000
const THIRTY_SECOND_POLLING_INTERVAL = 30000
const SHORT_POLLING_TIMER = 120000
const MAX_POLLING_TIMER = 240000

interface UseServiceOrderPollingResult {
  serviceOrders: ServiceOrder[] | undefined
}

const getActiveServiceOrder = (serviceOrders: ServiceOrder[] | undefined): ServiceOrder | undefined => {
  if (!serviceOrders?.length) return undefined
  return [...serviceOrders].sort((a, b) => b.updatedAt - a.updatedAt)[0]
}

export const useServiceOrderPolling = (claim: Claim): UseServiceOrderPollingResult => {
  const [shouldFetchServiceOrders, setShouldFetchServiceOrders] = useState(true)
  const [pollingInterval, setPollingInterval] = useState(TEN_SECOND_POLLING_INTERVAL)

  const { data: serviceOrders } = useGetServiceOrdersByClaimQuery(
    { claimId: claim.id, includeShippingLabels: true },
    {
      selectFromResult: (response) => ({
        ...response,
        data: response.data ? [...response.data].sort((a, b) => b.createdAt - a.createdAt) : [],
      }),
      pollingInterval,
      skip: !shouldFetchServiceOrders,
    },
  )

  // Poll for service orders every 10 seconds for 2 minutes, then every 30 seconds for 2 minutes, then stop polling
  useEffect(() => {
    setTimeout(() => {
      setPollingInterval(THIRTY_SECOND_POLLING_INTERVAL)
    }, SHORT_POLLING_TIMER)

    setTimeout(() => {
      setShouldFetchServiceOrders(false)
    }, MAX_POLLING_TIMER)
  }, [])

  // Stop polling service orders if an active SO exists for the claim and either the claim
  // or the SO has a status that doesn't require polling, service change is excluded from this check
  useEffect(() => {
    const activeServiceOrder = getActiveServiceOrder(serviceOrders)
    if (
      activeServiceOrder &&
      (NO_POLL_CLAIM_STATUSES.includes(claim?.status) ||
        (NO_POLL_SERVICE_ORDER_STATUSES.includes(activeServiceOrder.status ?? '') &&
          activeServiceOrder.closedMetaData?.resolution !== 'service_change'))
    ) {
      setShouldFetchServiceOrders(false)
    }
  }, [claim, serviceOrders])

  return { serviceOrders }
}
