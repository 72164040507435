import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AnalyticsFooter } from './analytics-footer'
import { LogoFooter } from './logo-footer'

interface PortalFooterProps {
  locale: string
}

const PortalFooter = ({ locale }: PortalFooterProps): JSX.Element => {
  const [footerType, setFooterType] = useState('analytics')
  const location = useLocation()

  useEffect(() => {
    const localRegex = new RegExp(`/${locale}/?$`)
    if (location.pathname.match(localRegex)) {
      setFooterType('none')
    } else if (
      ['plan_details', 'claim_fulfillment', 'shipping_protection_plan_details'].some((page) =>
        location.pathname.includes(page),
      )
    ) {
      setFooterType('logo')
    } else {
      setFooterType('analytics')
    }
  }, [location.pathname, setFooterType, locale])

  return (
    <>
      {footerType === 'analytics' && <AnalyticsFooter />}
      {footerType === 'logo' && <LogoFooter />}
    </>
  )
}

export type { PortalFooterProps }
export { PortalFooter }
