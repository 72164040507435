import React from 'react'
import { PageWrapper, ContentSection } from '../../components/common'
import { useContactMutation } from '@customers-api-rtk-query'
import type { ContactRequest } from '@customers-api-rtk-query'
import { ContactForm } from './contact-form'
import { ContactFormSuccess } from './contact-form-success'

const Contact = (): JSX.Element => {
  const [contact, { isLoading, isSuccess, isError }] = useContactMutation()
  const onSubmit = (formValues: ContactRequest) => {
    contact(formValues)
  }

  return (
    <PageWrapper pageTitle="Contact Extend">
      <ContentSection>
        {isSuccess ? (
          <ContactFormSuccess />
        ) : (
          <ContactForm onSubmit={onSubmit} isLoading={isLoading} isError={isError} />
        )}
      </ContentSection>
    </PageWrapper>
  )
}

export { Contact }
