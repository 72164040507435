import type { ReactNode } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { Grid } from '@customers-ui'
import { usePrevious } from '../../../../../hooks'

import { bp } from '../../utils/breakpoints'

export interface StepperProps {
  className?: string
  activeStep?: number
  setActiveStep?(active: number): void
  currentStep?: number
  bubbleColor?: string
  children?: ReactNode
}

export interface StepProps {
  title: string
  children?: ReactNode
}

const Step = ({ children }: StepProps): JSX.Element => {
  return <>{children}</>
}

const Stepper = ({
  children,
  className,
  activeStep = 0,
  setActiveStep,
  currentStep,
  bubbleColor,
}: StepperProps): JSX.Element => {
  const steps: ReactNode[] = React.Children.toArray(children)
  const previousCurrentStep = usePrevious(currentStep)
  useEffect(() => {
    if (setActiveStep && currentStep && currentStep !== previousCurrentStep) {
      const movingStep = Math.min(activeStep, Math.max(currentStep, 0))
      setActiveStep(movingStep)
    }
  }, [setActiveStep, previousCurrentStep, activeStep, currentStep])
  return (
    <Grid direction="column" gap={24} className={className}>
      {steps.length &&
        steps.map((step, index) => {
          if (!React.isValidElement(step)) {
            return null
          }
          return (
            <StepWrapper key={step.props.title}>
              <StepBubble
                isComplete={activeStep > index}
                isActive={activeStep === index}
                isLastStep={activeStep === steps.length - 1}
                bubbleColor={bubbleColor}
              />
              <Grid direction="column" margin="1px 0 0 16px">
                <StepTitle>{step.props.title}</StepTitle>
                {currentStep === undefined && activeStep === index && <div>{step}</div>}
                {currentStep === index && currentStep <= activeStep && <StyledStepDetails>{step}</StyledStepDetails>}
              </Grid>
            </StepWrapper>
          )
        })}
    </Grid>
  )
}

const StepTitle = styled.div({
  textTransform: 'uppercase',
  fontWeight: 600,
  color: COLOR.BLUE[1000],
  [bp.mobile]: {
    fontSize: 13,
    lineHeight: '16px',
  },
  [bp.desktop]: {
    fontSize: 14,
    lineHeight: '18px',
  },
})

const StepWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  flex: 1,
  '&::after': {
    content: '""',
    position: 'absolute',
    zIndex: 0,
    height: 'calc(100% + 24px)',
    left: 10,
    borderLeft: `1px solid ${COLOR.NEUTRAL[300]}`,
  },
  '&:last-of-type': {
    '&::after': {
      content: 'none',
    },
  },
})

const StyledStepDetails = styled.div({
  height: '150px',
})

const getBubbleColor = (isComplete: boolean, isActive: boolean, isLastStep: boolean, bubbleColor?: string): string => {
  if (isActive) {
    if (bubbleColor) {
      return bubbleColor
    }

    if (isLastStep) {
      return COLOR.BLUE[900]
    }

    return COLOR.BLUE[800]
  }

  if (isComplete) {
    return COLOR.BLUE[900]
  }

  return COLOR.WHITE
}

const StepBubble = styled.div<{
  isComplete: boolean
  isActive: boolean
  isLastStep: boolean
  bubbleColor?: string
}>(({ isComplete, isActive, isLastStep, bubbleColor }) => ({
  position: 'relative',
  zIndex: 1,
  width: 20,
  height: 20,
  background: getBubbleColor(isComplete, isActive, isLastStep, bubbleColor),
  border: !isActive && !isComplete ? `1px solid ${COLOR.NEUTRAL[300]}` : '',
  borderRadius: '50%',
}))

export { Stepper, Step }
