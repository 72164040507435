import * as pages from '../pages'

// Routes in this file are public available routes
// Should not be behind FF (breaks the app, FF in routes.tsx file instead)
export const routes = [
  {
    path: '/',
    element: pages.PlansPage,
  },
  {
    path: '/contact',
    element: pages.Contact,
  },
  {
    path: '/plan_details/:id',
    element: pages.PlanDetails,
  },
  {
    path: '/plan_details',
    element: pages.PlanDetails,
  },
  {
    path: '/virtual_card_fulfillment/support',
    element: pages.VirtualCardSupport,
  },
  {
    path: '/tracking',
    element: pages.TrackingPage,
  },
  {
    path: '/shipping_protection_plan_details/:id',
    element: pages.ShippingProtectionPlanDetailsPage,
  },
  {
    path: '/shipping_protection_plan_details',
    element: pages.ShippingProtectionPlanDetailsPage,
  },
  {
    path: '/authentication',
    element: pages.MyExtendAuthenticationPage,
  },
]
