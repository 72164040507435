import React, { useState } from 'react'
import { useToaster, ToastColor, ToastDuration } from '@extend/zen'
import styled from '@emotion/styled'
import type { Claim, ServiceOrder } from '@customers-api-client'
import { useSelectFulfillmentMethodMutation } from '@customers-api-rtk-query'
import type { ReturnFulfillmentMethod } from '@extend-services/service-orders/dist/src/client/api-rest/v2/models'
import { ClaimTimeline } from '../claim-timeline'
import { ClaimPreparingState } from '../claim-preparing-state'
import { getReturnTimelineConfig } from './timeline-config'
import { isPendingState, TIMELINE_STEPS } from './constants'
import { useShipmentsPolling } from '../../../../hooks/use-shipments-polling'

const Container = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  button: {
    display: 'block !important',
  },
  gap: 15,
})

interface ReturnResolutionTimelineProps {
  claim: Claim
  activeServiceOrder?: ServiceOrder
}

export const getActiveStepIndex = (serviceOrder?: ServiceOrder): number => {
  if (!serviceOrder) return TIMELINE_STEPS.RETURN_STARTED
  if (serviceOrder.status === 'created' && !serviceOrder.configurations?.fulfillmentMethod)
    return TIMELINE_STEPS.RETURN_OPTION
  if (serviceOrder.status !== 'fulfilled' && serviceOrder.status !== 'completed')
    return TIMELINE_STEPS.RETURN_TO_CARRIER
  return TIMELINE_STEPS.RETURN_FULFILLED
}

/**
 * Timeline component for the return resolution process
 *
 * This component manages the state and flow of a return process, showing different
 * steps based on the current state of the claim and service order.
 */
const ReturnResolutionTimeline = ({ claim, activeServiceOrder }: ReturnResolutionTimelineProps): JSX.Element => {
  const [displayOptions, setDisplayOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState<ReturnFulfillmentMethod>('store_credit')
  const [displayPaymentDetails, setDisplayPaymentDetails] = useState(false)
  const [setReturnMethod] = useSelectFulfillmentMethodMutation()
  const { toast } = useToaster()
  const { shipments } = useShipmentsPolling(activeServiceOrder)

  const handleSelectReturnButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    setDisplayOptions(true)
  }

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedOption(e.target.value as ReturnFulfillmentMethod)
  }

  const handleConfirmReturnClick = async (): Promise<void> => {
    if (!activeServiceOrder) return

    try {
      const response = await setReturnMethod({
        serviceOrderId: activeServiceOrder.id,
        fulfillmentSelection: selectedOption,
      })

      if ((response as { error: { status: number; data: unknown } }).error) {
        toast({
          message: 'An error occurred',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
        return
      }

      const successMessage =
        selectedOption === 'store_credit'
          ? 'Instant credit selected! You can use this immediately at the store.'
          : 'Refund selected! This will be processed in 3-5 business days.'

      toast({
        message: successMessage,
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
    } catch (e) {
      toast({
        message: 'An error occurred',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  if (isPendingState(claim, activeServiceOrder)) {
    return <ClaimPreparingState entityName="return" />
  }

  const timelineConfig = getReturnTimelineConfig({
    claim,
    activeServiceOrder,
    displayOptions,
    selectedOption,
    displayPaymentDetails,
    setDisplayPaymentDetails,
    handleOptionChange,
    handleSelectReturnButtonClick,
    handleConfirmReturnClick,
    shipments,
  })

  const activeStepIndex = getActiveStepIndex(activeServiceOrder)

  return (
    <Container data-cy="return-resolution-timeline">
      <ClaimTimeline
        config={timelineConfig}
        claim={claim}
        serviceOrder={activeServiceOrder}
        activeStepIndex={activeStepIndex}
      />
    </Container>
  )
}

export { ReturnResolutionTimeline }
