import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery, X_EXTEND_ACCESS_TOKEN } from '../base-query'
import type {
  ServiceOrder,
  ServiceOrdersGetByClaimRequest,
  ServiceOrdersGetRequest,
  ServiceOrderShipment,
} from './types'
import type { FulfillmentMethod } from '@extend-services/service-orders/dist/src/client/api-rest/v2/models'

const API_VERSION = '2022-02-01'

export const serviceOrdersApi = createApi({
  baseQuery,
  reducerPath: 'ServiceOrder',
  tagTypes: ['ServiceOrder', 'VirtualCardUserAgreement'],
  endpoints: (build) => ({
    getServiceOrder: build.query<ServiceOrder, ServiceOrdersGetRequest>({
      query: ({ serviceOrderId, includeShippingLabels = false, version = API_VERSION, accessToken }) => ({
        url: `/service-orders/${serviceOrderId}`,
        params: { include_shipping_labels: includeShippingLabels },
        headers: {
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err, { serviceOrderId }) => [{ type: 'ServiceOrder', id: serviceOrderId }],
    }),
    getServiceOrdersByClaim: build.query<ServiceOrder[], ServiceOrdersGetByClaimRequest>({
      query: ({ claimId, accessToken }) => ({
        url: `/service-orders/search`,
        params: { claimId, includeShippingLabels: false },
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=2021-07-01;`,
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
        },
      }),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id: serviceOrderId }) => ({ type: 'ServiceOrder', id: serviceOrderId } as const)),
              { type: 'ServiceOrder', id: 'LIST' },
            ]
          : [{ type: 'ServiceOrder', id: 'LIST' }]
      },
    }),
    getServiceOrderShipments: build.query<ServiceOrderShipment[], { serviceOrderId: string }>({
      query: ({ serviceOrderId }) => ({
        url: `/service-orders/${serviceOrderId}/service-order-shipments`,
      }),
      providesTags: (_, _err, { serviceOrderId }) => [{ type: 'ServiceOrder', id: serviceOrderId }],
    }),
    selectFulfillmentMethod: build.mutation<
      undefined,
      {
        serviceOrderId: string
        fulfillmentSelection: FulfillmentMethod
        version?: string
      }
    >({
      query: ({ serviceOrderId, fulfillmentSelection, version = API_VERSION }) => ({
        url: `/service-orders/${serviceOrderId}/select-fulfillment`,
        headers: {
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body: { fulfillmentSelection },
      }),
      invalidatesTags: ['ServiceOrder'],
    }),
    submitVirtualCardUserAgreement: build.mutation<void, { accessToken?: string; version?: string }>({
      query: ({ accessToken, version = API_VERSION }) => ({
        url: `/service-orders/virtual-card-user-agreement`,
        method: 'POST',
        headers: {
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
          accept: `application/json; version=${version};`,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled
        setTimeout(() => {
          dispatch(serviceOrdersApi.util.invalidateTags(['VirtualCardUserAgreement']))
        }, 200)
      },
    }),
    getVirtualCardUserAgreement: build.query<void, { accessToken?: string; version?: string }>({
      query: ({ accessToken, version = API_VERSION }) => ({
        url: `/service-orders/virtual-card-user-agreement`,
        headers: {
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err) => [{ type: 'VirtualCardUserAgreement' }],
    }),
  }),
})

export const {
  useGetServiceOrdersByClaimQuery,
  useSubmitVirtualCardUserAgreementMutation,
  useGetVirtualCardUserAgreementQuery,
  useGetServiceOrderShipmentsQuery,
  useSelectFulfillmentMethodMutation,
} = serviceOrdersApi
