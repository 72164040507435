import styled from '@emotion/styled'
import { useListOrdersQuery } from '@customers-api-rtk-query'
import type { EntitlementSearchByOrdersResponse, OrdersListByOrders } from '@customers-api-rtk-query'
import { useTheme } from '@emotion/react'
import type { Claim } from '../../types/claim'
import { useNavigate } from 'react-router-dom'
import { InlineAlert, InlineAlertColor } from '@extend/zen'
import React from 'react'
import { getAccountInfo } from '../../lib/consumer-profile-utils'
import { useSelector } from 'react-redux'
import * as selectors from '../../reducers/selectors'
import { missingProfileFieldTooltip } from '../../lib/helper-functions'
import type { ContractsSearchIndividual } from '../../types/contract'
import { ClaimCard } from '../claims-page/claim-card'
import { MissingDataCard } from './missing-data-card'
import { useIntl } from 'react-intl'
import type { ProductDetailsProp } from './contract-details-page'
import { logEvent } from '../../analytics'
import { mapToClaimDetails } from '../claims-page/utils'
import { LDFlag } from '@src/constants/ld-flags'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface ClaimsSectionProps {
  claims?: Claim[]
  contract?: ContractsSearchIndividual
  product?: ProductDetailsProp
  fileAClaimSettings: {
    shouldDisableFileClaim?: boolean
    fileAClaimTooltipOverride?: string
  }
}

export const ClaimsSection = ({
  claims,
  contract,
  product,
  fileAClaimSettings: { shouldDisableFileClaim, fileAClaimTooltipOverride },
}: ClaimsSectionProps): JSX.Element => {
  const { [LDFlag.UseAuthPopa]: FF_USE_AUTH_POPA } = useFlags()
  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const { locale } = useIntl()
  const { isEmailVerified, isPhoneNumberVerified } = getAccountInfo(consumerProfile)
  const isMissingRequiredProfileField = !isEmailVerified || !isPhoneNumberVerified
  const theme = useTheme()
  const navigate = useNavigate()
  const { isExtend } = theme.merchantConfiguration

  const handleButtonClick = (): void => {
    logEvent('My Plans - Clicks', 'File a Claim')
    navigate(!isExtend ? `/${locale}/my_claims/file-claim` : `/${locale}/kaley`, {
      state: {
        contractId: contract?.id,
        ...(product?.lineItemId && { lineItemIds: [product.lineItemId] }),
      },
    })
  }

  const handleVerifyClick = (): void => {
    navigate('/profile')
  }

  const { data } = useListOrdersQuery({})
  const emsOrdersResponse = data as OrdersListByOrders

  const claimDetails = claims && emsOrdersResponse ? mapToClaimDetails(claims ?? [], emsOrdersResponse ?? []) : {}

  const claimDetailsArr = Object.values(claimDetails).sort((a, b) => b.claim.createdAt - a.claim.createdAt)

  return (
    <ClaimsSectionContainer>
      <SectionHeaderText>Claims</SectionHeaderText>
      {!FF_USE_AUTH_POPA && !isEmailVerified && (
        <InlineAlert
          data-cy="no-email-alert"
          color={InlineAlertColor.red}
          primaryButtonProps={{
            onClick: handleVerifyClick,
            text: 'Verify Now',
            'data-cy': 'verify-email-button',
          }}
        >
          To process your claim, let&apos;s verify your email. We&apos;ll never share your information or spam you.
        </InlineAlert>
      )}
      {!FF_USE_AUTH_POPA && !isPhoneNumberVerified && (
        <InlineAlert
          data-cy="no-phone-number-alert"
          color={InlineAlertColor.red}
          primaryButtonProps={{
            onClick: handleVerifyClick,
            text: 'Verify Now',
            'data-cy': 'verify-phone-button',
          }}
        >
          To process your claim, let&apos;s verify your phone number. We&apos;ll never share your information or spam
          you.
        </InlineAlert>
      )}
      {claimDetailsArr && claimDetailsArr.length > 0 ? (
        claimDetailsArr.map((detail) => (
          <ClaimCard
            listedClaim={detail.claim}
            emsData={detail?.emsData as EntitlementSearchByOrdersResponse}
            key={detail.claim.id}
          />
        ))
      ) : (
        <MissingDataCard
          headerText="No claim found."
          buttonProps={{
            handleButtonClick,
            text: 'File a Claim',
            isDisabled: shouldDisableFileClaim,
            tooltip: isMissingRequiredProfileField ? missingProfileFieldTooltip : fileAClaimTooltipOverride,
          }}
        />
      )}
    </ClaimsSectionContainer>
  )
}

const ClaimsSectionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})

const SectionHeaderText = styled.div({
  fontSize: 24,
  fontWeight: 700,
})
