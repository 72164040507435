import type { ComponentProps, FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import { Button, AdvancedSelect, COLOR, HeadingSmall } from '@extend/zen'
import type { Reply, DropdownPromptOption, ReasonSelectPrompt, Slot } from '@extend-incredibot/types'
import { getOptions } from '../../../lib/chat-utils'
import type { UserInputComponentProps } from './types'
import { ContentSection } from '@src/components/common'
import { Card } from '../../../components/card'
import { isMobile } from 'react-device-detect'

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface ReasonSelectProps extends UserInputComponentProps<Reply & { prompt: ReasonSelectPrompt }> {}

const ReasonSelect: FC<ReasonSelectProps> = ({ isLoading, onSubmit, reply }) => {
  const [selectedReasons, setSelectedReasons] = useState<
    Record<string, { value: string; display: string; category: string }>
  >({})

  const handleSubmit = (): void => {
    const options = getOptions(reply) as DropdownPromptOption[]
    const submissions = options
      .filter((option) => selectedReasons[option.value])
      .map((option) => {
        const selected = selectedReasons[option.value]
        return {
          lineItemId: option.value,
          failureType: selected.category,
          failureCause: selected.value,
        }
      })

    if (submissions.length > 0) {
      onSubmit(reply.prompt.slot as Slot, submissions)
    }
  }

  const handleChange =
    (groupValue: string) =>
    (e: AdvancedSelectChangeEvent): void => {
      const {
        target: { value: eValue },
      } = e

      const [category, value, display] = eValue.split(':')

      setSelectedReasons((prev) => ({
        ...prev,
        [groupValue]: { value, category, display },
      }))
    }

  const options = getOptions(reply) as DropdownPromptOption[]

  return (
    <ContentSection data-cy="reason-select-content-wrapper">
      <StyledCard data-cy="reason-select-card" className="reason-select-card">
        <Header>Select the reason</Header>
        {options.map(({ title, value, options = [], imageUrl }, index) => {
          const key = `${value}-${title}-${index}`
          const selectOptions = options.map((option) => {
            if ('category' in option) {
              return {
                label: option.category.display,
                options: option.options.map((displayOption) => ({
                  display: displayOption.display,
                  // the advanced select on click event only includes this value, so need to package category and value together
                  value: `${option.category.value}:${displayOption.value}:${displayOption.display}`,
                })),
              }
            }
            return {
              display: option.display,
              value: option.value,
            }
          })

          return (
            <StyledProductGroupCard key={key} data-cy={`reason-select-${key}`}>
              <LabelContainer>
                {imageUrl && <Image data-cy={`reason-select-image-${value}`} imageUrl={imageUrl} />}
                <Body data-cy="reason-label">{title}</Body>
              </LabelContainer>
              <AdvancedSelect
                data-cy={`reason-select-${key}`}
                multiple={false}
                options={selectOptions}
                value={selectedReasons[value]?.display || ''}
                id={key}
                isNotClearable
                onChange={handleChange(value)}
                placeholder="Select a reason"
              />
            </StyledProductGroupCard>
          )
        })}
      </StyledCard>
      <ButtonWrapper>
        <Button
          data-cy="next-button"
          text="Next"
          isDisabled={Object.keys(selectedReasons).length !== options.length || isLoading}
          isProcessing={isLoading}
          type="submit"
          onClick={handleSubmit}
          color="neutral"
        />
      </ButtonWrapper>
    </ContentSection>
  )
}

const Header = styled(HeadingSmall)({
  textAlign: 'center',
})

const StyledCard = styled(Card)({
  width: '640px',
  height: '412px',
  padding: '32px 0px',
  borderRadius: '8px',
  overflowY: 'auto',
})

const StyledProductGroupCard = styled(Card)({
  borderRadius: '8px',
  margin: '0 32px',
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const LabelContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
})

const Image = styled.div<{ imageUrl: string }>(({ imageUrl }) => ({
  width: 40,
  height: 40,
  borderRadius: 4,
  overflow: 'hidden',
  background: `url(${imageUrl}) lightgray 50% / contain no-repeat;`,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}))

export const BodyDesktop = styled.p({
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  padding: 0,
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 400,
  fontStyle: 'normal',
})

export const BodyMobile = styled.p({
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  padding: 0,
  fontSize: 15,
  lineHeight: '20px',
  fontWeight: 400,
  fontStyle: 'normal',
})

export const Body = (props: ComponentProps<typeof BodyDesktop | typeof BodyMobile>) => {
  const Component = isMobile ? BodyMobile : BodyDesktop
  return <Component {...props} />
}

export { ReasonSelect }
