import React, { useEffect, useMemo, useState } from 'react'
import type { FC } from 'react'
import { useNavigate, useLocation, useNavigationType } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Spinner } from '@customers-ui'
import { AccordionSection, COLOR, InlineAlert, InlineAlertColor } from '@extend/zen'
import { isEmpty } from 'lodash'
import { customLogger } from '@extend/client-helpers'
import * as selectors from '../../reducers/selectors'
import { ClaimSnapshot } from './claim-snapshot'
import { CustomerShippingAddress } from './customer-shipping-address'
import type { Claim } from '../../types/claim'
import { ClaimResultModal } from '../../components/claim-result-modal/claim-result-modal'
import { getAccountInfo } from '../../lib/consumer-profile-utils'
import { setPollState } from '../../store/slices/poll'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '@src/constants/ld-flags'
import { ContentSection, PageWrapper } from '@src/components/common'
import { useClaimSearch, useClaimSubmission, useClaimSessionLogs } from '../../hooks'

const ReviewClaimSubmissionPage: FC = () => {
  const { [LDFlag.UseAuthPopa]: FF_USE_AUTH_POPA } = useFlags()
  const [hasLoadedClaim, setHasLoadedClaim] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const claimId = queryParams.get('claimId') || ''
  const sessionId = queryParams.get('sessionId') || ''
  const [isModalOpen, setModalOpen] = useState(false)
  const navigationType = useNavigationType()

  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const { isEmailVerified, isPhoneNumberVerified } = getAccountInfo(consumerProfile)
  const isMissingRequiredProfileField = !isEmailVerified || !isPhoneNumberVerified

  const decodedAccessToken = useSelector(selectors.getDecodedAccessToken)
  const poll = useSelector(selectors.getPoll)

  const email = poll?.customerEmail || decodedAccessToken?.email
  const phone = poll?.customerPhone || decodedAccessToken?.phone_number
  const pollSessionId = poll?.sessionId || sessionId

  const { logs: sessionLogs, isLoading: isSessionLogsLoading } = useClaimSessionLogs(pollSessionId)

  const {
    claims,
    isLoading: isClaimsLoading,
    isError: isListClaimsError,
    error: listClaimsError,
  } = useClaimSearch({
    email,
    phone,
    sessionId: pollSessionId,
    claimId,
    pollingInterval: 5000,
    skip: hasLoadedClaim,
  })

  const [claim] = claims || []
  const {
    submitClaim,
    isSuccess: isClaimSubmitSuccess,
    isError: isSubmitClaimError,
    error: submitClaimError,
    claim: updatedClaim,
  } = useClaimSubmission()

  useEffect(() => {
    if (!isEmpty(claim)) {
      setHasLoadedClaim(true)
    }

    const queryError = listClaimsError || submitClaimError
    if (queryError) {
      customLogger.warn(`[Err: Review Claim Page]: ${queryError}`, {
        isListClaimsError,
        isSubmitClaimError,
        claimId,
        sessionId,
        pollSessionId,
      })
      navigate('/error')
    }
  }, [claim, isSubmitClaimError, isListClaimsError, navigate])

  // Handle back navigation (browser back button, mobile swipe gestures, hardware back button)
  // useNavigationType detects 'POP' actions from all navigation triggers:
  // - Browser back button clicks
  // - iOS swipe back gesture
  // - Android back gesture and hardware button
  // Using { replace: true } ensures consistent history stack management across devices
  useEffect(() => {
    if (navigationType === 'POP') {
      navigate('/my_claims', { replace: true })
    }
    return () => {
      dispatch(setPollState(''))
    }
  }, [navigationType, navigate, dispatch])

  const handleModalOpen = (): void => {
    setModalOpen(true)
  }

  const handleModalClose = (): void => {
    setModalOpen(false)
  }

  const handleVerifyClick = (): void => {
    navigate('/profile')
  }

  const isLoading = isSessionLogsLoading || isClaimsLoading || !hasLoadedClaim

  return (
    <PageWrapper data-cy="review-claim-page-wrapper">
      <ContentSection>
        {isLoading && (
          <SpinnerLoading data-cy="spinner-loading">
            <Spinner size="md" />
          </SpinnerLoading>
        )}
        {!FF_USE_AUTH_POPA && !isEmailVerified && (
          <InlineAlert
            data-cy="no-email-alert"
            color={InlineAlertColor.red}
            primaryButtonProps={{
              onClick: handleVerifyClick,
              text: 'Verify Now',
              'data-cy': 'verify-email-button',
            }}
          >
            To process your claim, let&apos;s verify your email. We&apos;ll never share your information or spam you.
          </InlineAlert>
        )}
        {!FF_USE_AUTH_POPA && !isPhoneNumberVerified && (
          <InlineAlert
            data-cy="no-phone-number-alert"
            color={InlineAlertColor.red}
            primaryButtonProps={{
              onClick: handleVerifyClick,
              text: 'Verify Now',
              'data-cy': 'verify-phone-button',
            }}
          >
            To process your claim, let&apos;s verify your phone number. We&apos;ll never share your information or spam
            you.
          </InlineAlert>
        )}
        {!isLoading && (
          <>
            <ClaimSummaryHeader data-cy="claim-summary-header">Claim Details</ClaimSummaryHeader>
            <CollapsibleSection>
              <AccordionSection heading="Claim Snapshot" data-cy="claim-snapshot-accordion">
                <ClaimSnapshot sessionLogs={sessionLogs} />
              </AccordionSection>
            </CollapsibleSection>
            <CollapsibleSection>
              <AccordionSection heading="Shipping Address" data-cy="shipping-address-accordion" isExpanded>
                <CustomerShippingAddress
                  claim={claim as unknown as Claim}
                  handleModalOpen={handleModalOpen}
                  submitClaim={({ claimId }) => submitClaim(claimId)}
                  isClaimSubmitSuccess={isClaimSubmitSuccess}
                  isMissingRequiredProfileField={isMissingRequiredProfileField}
                />
              </AccordionSection>
            </CollapsibleSection>
          </>
        )}
      </ContentSection>
      <ClaimResultModal
        claim={(updatedClaim as unknown as Claim) || (claim as unknown as Claim)}
        onDismiss={handleModalClose}
        isModalOpen={isModalOpen}
      />
    </PageWrapper>
  )
}

const SpinnerLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 20px',
})

const ClaimSummaryHeader = styled.div({
  fontWeight: 700,
  fontSize: '28px',
  lineHeight: '36px',
})

const CollapsibleSection = styled.div({
  borderRadius: '16px',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  display: 'flex',
  padding: '16px',
  flexDirection: 'column',
})

export { ReviewClaimSubmissionPage }
