import React from 'react'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import styled from '@emotion/styled'
import { v4 as uuid } from 'uuid'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { customLogger } from '@extend/client-helpers'
import { COLOR } from '@customers-ui'
import { bp } from '@extend/client-helpers'
import type { CarouselPrompt, CarouselPromptOption, ContractAndLineItemId } from '@customers-api-client'
import { isMobile } from 'react-device-detect'
import { ChatOrderCard } from './chat-order-card'
import { ChatContractCard } from './chat-contract-card'
import { svgs } from '../../../../../lib/assets'
import type { UserInputComponentProps } from '../../types'
import { BackButton, NextButton } from '../../../../../components/common'
import { useScreenSize } from '../../../../../hooks'
import { isOrderCarouselOption } from './utils'

interface ChatCarouselProps extends UserInputComponentProps<CarouselPrompt> {}

const CARD_HEIGHT = 268
const CARD_WIDTH = 301

const ChatCarousel = ({ onAddInput, prompt }: ChatCarouselProps): JSX.Element => {
  const { slot, options } = prompt

  if (!options.length) {
    customLogger.error(`ChatCarousel options is empty`)
  }

  const isScreenSizeSmall = useScreenSize() === 'small'
  const numSlides = options.length + 1

  const onClick = (message: string, value: string | ContractAndLineItemId): void => {
    onAddInput({ message, slot, value })
  }

  const finalSlideText = options.length > 1 ? 'None of these!' : 'Not this one'
  const renderProducts = options.map((option: CarouselPromptOption, index: number) => {
    const { value } = option
    const key = typeof value === 'object' ? uuid() : value

    if (isOrderCarouselOption(option)) {
      return <ChatOrderCard key={key} {...{ option, index, onClick }} />
    }

    return <ChatContractCard product={option} key={key} {...{ index, onClick }} />
  })

  const handleLastSlideClick = (): void => {
    onClick(finalSlideText, '')
  }

  return (
    <CarouselWrapper data-cy="chat-carousel">
      <CarouselProviderWrapper
        naturalSlideWidth={CARD_WIDTH}
        naturalSlideHeight={CARD_HEIGHT}
        totalSlides={numSlides}
        visibleSlides={isScreenSizeSmall ? 1 : 2}
        touchEnabled={isMobile}
        dragEnabled={isMobile}
        data-cy="carousel-provider-wrapper"
      >
        {!isMobile && (
          <BackButton>
            <svgs.BackArrow />
          </BackButton>
        )}

        <SliderContainer isMobile={isMobile} isScreenSizeSmall={isScreenSizeSmall}>
          <SliderWrapper padding={isMobile ? '0px 16px' : 0} aria-label="slider">
            {renderProducts}
            <Slide index={options.length} key={options.length}>
              <LastSlideContent onClick={handleLastSlideClick} data-cy="last-slide-content">
                {finalSlideText}
              </LastSlideContent>
            </Slide>
          </SliderWrapper>
        </SliderContainer>

        {!isMobile && (
          <NextButton>
            <svgs.RightChevron />
          </NextButton>
        )}
      </CarouselProviderWrapper>
    </CarouselWrapper>
  )
}

const CarouselWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '0px 0px 24px',
  [bp.md]: {
    width: '100%',
    padding: '0px 16px 32px',
  },
  maxWidth: 768,
})

const CarouselProviderWrapper = styled(CarouselProvider)({
  display: 'flex',
  width: '100%',
  position: 'relative',
  height: CARD_HEIGHT,
})

interface SliderContainerProps {
  isMobile: boolean
  isScreenSizeSmall: boolean
}

const SliderContainer = styled.div<SliderContainerProps>((props) => ({
  margin: '0px auto',
  [bp.md]: {
    margin: 0,
  },
  // `isMobile` is declared in an upper scope, so can not deconstruct from props
  width: !props.isMobile && props.isScreenSizeSmall ? '90%' : '100%',
}))

const SliderWrapper = styled(Slider)<{ padding: string | number }>(({ padding }) => ({
  overflowX: 'hidden',
  height: '100%',
  width: '100%',
  '*': {
    outlineStyle: 'none !important',
  },
  padding,
}))

const LastSlideContent = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: COLOR.BLUE[700],
  cursor: 'pointer',
  borderRadius: 6,
  margin: '0px 8px',
  border: `1px solid ${COLOR.BLUE[700]}`,
  height: CARD_HEIGHT,
})

export type { ChatCarouselProps }
export { ChatCarousel }
