import React from 'react'
import styled from '@emotion/styled'
import { COLOR, bp, Card, Divider } from '@customers-ui'

import { CustomerFormView } from '../../components/forms/customer-form-view'
import type { FormSubmitData } from '../../components/forms/customer-form-template.types'

export interface TransferCustomerDetailsProps {
  className?: string
  formData: FormSubmitData
  dataCy?: string
}

const TransferCustomerDetails = ({ className, formData, dataCy }: TransferCustomerDetailsProps): JSX.Element => {
  return (
    <StyledCard className={className} dataCy={dataCy}>
      <CardTitle>Recipient Information</CardTitle>
      <StyledDivider />
      <CustomerFormView formData={formData} />
    </StyledCard>
  )
}

const StyledCard = styled(Card)({
  [bp.mobile]: {
    width: '100%',
    padding: '32px 32px 0',
  },
  [bp.desktop]: {
    width: 668,
    padding: '32px 32px 8px',
  },
})

const CardTitle = styled.div({
  color: COLOR.NEUTRAL[1000],
  [bp.mobile]: {
    marginBottom: 16,
    fontSize: 16,
    lineHeight: '24px',
  },
  [bp.desktop]: {
    marginBottom: 24,
    fontSize: 20,
    lineHeight: '28px',
  },
})

const StyledDivider = styled(Divider)({
  marginBottom: 24,
})

export { TransferCustomerDetails }
