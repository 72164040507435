import type { PlanTermsSetResponse } from '@customers-api-client'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@customers-ui'
import svgs from '../../../lib/svgs'

type PlanTermsListProps = {
  terms: PlanTermsSetResponse
}

const PlanTermsList = ({ terms }: PlanTermsListProps): JSX.Element => {
  return (
    <Wrapper data-cy="plan-terms-links-list">
      {terms.map((term) => {
        return (
          <ListItem key={term.termsId}>
            <Link
              key={term.termsId}
              href={term.staticAssetUrl ?? `${term.s3Url}?v=${Date.now()}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-describedby="extend-terms-conditions"
              data-cy={`plan-terms-link-${term.termsId}`}
            >
              {term.consumerText} <svgs.External />
            </Link>
          </ListItem>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.span({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
})

const ListItem = styled.span({
  display: 'block',
})

const Link = styled.a({
  color: COLOR.BLUE[700],
  cursor: 'pointer',
  lineHeight: '27px',
  fontSize: 20,
  '> svg': {
    display: 'inline',
    height: 12,
  },
})

export { PlanTermsList }
