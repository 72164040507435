import React from 'react'

export const LostPackage = (): JSX.Element => {
  return (
    <svg width="112" height="97" viewBox="0 0 112 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.9438 83.9952L44.5 85.5498V3.45023L83.0667 14.4684V32.95C83.0667 33.7688 83.7312 34.4333 84.55 34.4333C85.3688 34.4333 86.0333 33.7688 86.0333 32.95V13.35C86.0333 12.6884 85.5943 12.107 84.9564 11.923L43.4231 0.0563667C43.3816 0.0445 43.3371 0.0652667 43.2955 0.0563667C43.2036 0.0385667 43.1146 0 43.0167 0C42.9188 0 42.8298 0.0385667 42.7378 0.0563667C42.6933 0.0652667 42.6488 0.0445 42.6073 0.0563667L1.07393 11.923C0.439067 12.107 0 12.6884 0 13.35V75.65C0 76.3116 0.439067 76.893 1.07393 77.077L42.6073 88.9436C42.7408 88.9822 42.8802 89 43.0167 89C43.1531 89 43.2926 88.9822 43.4231 88.9466L50.7597 86.8492C51.5488 86.6237 52.0027 85.8019 51.7772 85.0158C51.5518 84.2296 50.7419 83.7727 49.9438 83.9952ZM14.8333 11.0775L26.697 7.68663V42.3996L25.2137 43.8829L21.8139 40.4831C21.2354 39.9046 20.295 39.9046 19.7165 40.4831L16.3167 43.8859L14.8333 42.4026V11.0775ZM41.5333 85.5498L2.96667 74.5316V14.4684L11.8667 11.926V43.0167C11.8667 43.4112 12.0239 43.788 12.3028 44.0639L15.2694 47.0306C15.8479 47.6091 16.7884 47.6091 17.3669 47.0306L20.7667 43.6308L24.1665 47.0335C24.4572 47.3213 24.8369 47.4667 25.2167 47.4667C25.5964 47.4667 25.9761 47.3213 26.2639 47.0335L29.2306 44.0669C29.5065 43.788 29.6637 43.4112 29.6637 43.0167V6.84113L41.5333 3.45023V85.5498Z"
        fill="#2146DE"
      />
      <path
        d="M82.8333 97C68.0377 97 56 84.9624 56 70.1667C56 55.371 68.0377 43.3334 82.8333 43.3334C97.629 43.3334 109.667 55.371 109.667 70.1667C109.667 84.9624 97.629 97 82.8333 97ZM82.8333 45.6667C69.3233 45.6667 58.3333 56.6567 58.3333 70.1667C58.3333 83.6767 69.3233 94.6667 82.8333 94.6667C96.3433 94.6667 107.333 83.6767 107.333 70.1667C107.333 56.6567 96.3433 45.6667 82.8333 45.6667Z"
        fill="#27AEE4"
      />
      <path
        d="M82.8333 80.6667C82.1893 80.6667 81.6667 80.144 81.6667 79.5V77.7337C81.6667 75.552 82.516 73.5033 84.0583 71.9587L86.9563 69.0607C88.06 67.957 88.6667 66.4917 88.6667 64.933V63.1667C88.6667 59.9513 86.051 57.3333 82.8333 57.3333C79.6157 57.3333 77 59.9513 77 63.1667V65.5C77 66.144 76.4773 66.6667 75.8333 66.6667C75.1893 66.6667 74.6667 66.144 74.6667 65.5V63.1667C74.6667 58.6633 78.33 55 82.8333 55C87.3367 55 91 58.6633 91 63.1667V64.933C91 67.1147 90.1507 69.1633 88.6083 70.708L85.7103 73.606C84.6067 74.7097 84 76.175 84 77.7337V79.5C84 80.144 83.4773 80.6667 82.8333 80.6667ZM82.8333 87.6667C81.5453 87.6667 80.5 86.619 80.5 85.3333C80.5 84.0477 81.5453 83 82.8333 83C84.1213 83 85.1667 84.0477 85.1667 85.3333C85.1667 86.619 84.1213 87.6667 82.8333 87.6667ZM82.8333 85.331C82.8333 85.3333 82.8333 85.3333 82.8333 85.331L84 85.3333L82.8333 85.331Z"
        fill="#27AEE4"
      />
    </svg>
  )
}
