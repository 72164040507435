import React, { useState } from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { COLOR, FormattedMessage } from '@customers-ui'
import type { Plan, PlanDetails } from '@customers-api-client'
import { useIntl } from 'react-intl'

import { svgs } from '../../../lib/assets'
import { setDateByMonths } from '../../../utils/date-converter'
import { getIsWheelsTirePlan } from '../../../utils/get-is-wheel-tire-plan'
import { getIsAdhPlan, isJewelryPlan } from '../../../utils/plan-helpers'
import { PlanBarGraph, BAR_GRAPH_MAX_WIDTH } from './plan-bar-graph'

const XL_PLAN_LENGTH_SECTION_WIDTH = 1081
const XL_PADDING = 112
const MIN_TERM_LENGTH_MONTHS = 12
const MAX_TERM_LENGTH_MONTHS = 60

type PlanCalculatorProps = {
  plan: Plan
  planDetails: PlanDetails
}

const PlanCalculator = ({ plan, planDetails }: PlanCalculatorProps): JSX.Element | null => {
  const { locale } = useIntl()
  const { term_length: termLength } = plan.contract

  const isAdh = getIsAdhPlan(plan)
  const [months, setMonths] = useState(termLength)
  const [date, setDate] = useState(setDateByMonths(new Date(), termLength + (isAdh ? 0 : 12)))

  // The plan calculator includes spacing at the bottom, so the <p /> corrects this */
  if (getIsWheelsTirePlan(plan)) return <EmptySpacer />

  const termLengthYears = new Intl.NumberFormat(locale).format(Math.floor(months / 12))
  const planTermsEndDate = new Intl.DateTimeFormat(locale, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(date)
  const { calculatorNote, calculatorFootnote, policyType } = planDetails.planDetails

  const handleClickIncrement = (): void => {
    if (months < MAX_TERM_LENGTH_MONTHS) {
      setMonths(months + 12)
      setDate(setDateByMonths(date, 12))
    }
  }

  const handleClickDecrement = (): void => {
    if (months > MIN_TERM_LENGTH_MONTHS) {
      setMonths(months - 12)
      setDate(setDateByMonths(date, -12))
    }
  }

  if (isJewelryPlan(plan)) return null

  return (
    <PlanLengthSection>
      <PlanLengthWrapper>
        <PlanLengthDescription>
          <DesktopOnly>
            <FormattedMessage
              id="PLAN_DETAILS_PLAN_CALCULATOR_DESKTOP"
              defaultMessage="With a <MinusIconWrapper>{minusIcon}</MinusIconWrapper><PlanLength>{termLengthYears, plural, one {{termLengthYears} year} other {{termLengthYears} year}} {policyType, select, plan {plan} policy {policy} other {plan}}</PlanLength><PlusIconWrapper>{plusIcon}</PlusIconWrapper> your purchase is protected until <strong>{planTermsEndDate}</strong>"
              description="termLengthYears: plan length in years. planTermsEndDate: date protection plan ends. Calculation of when a selected protection plan ends. Changes with plus and minus selectors."
              values={{
                planTermsEndDate,
                termLengthYears,
                policyType,
                minusIcon: <svgs.Minus />,
                plusIcon: <svgs.Plus />,
                MinusIconWrapper: (chunks) => (
                  <IconWrapper data-cy="desktop-minus" onClick={handleClickDecrement}>
                    {chunks}
                  </IconWrapper>
                ),
                PlusIconWrapper: (chunks) => (
                  <IconWrapper data-cy="desktop-plus" onClick={handleClickIncrement}>
                    {chunks}
                  </IconWrapper>
                ),
                PlanLength: (chunks) => <PlanLength>{chunks}</PlanLength>,
                strong: (chunks) => <strong>{chunks}*</strong>,
              }}
            />
          </DesktopOnly>
          <MobileOnly>
            <FormattedMessage
              id="PLAN_DETAILS_PLAN_CALCULATOR_MOBILE"
              defaultMessage="<MinusIconWrapper>{minusIcon}</MinusIconWrapper><PlanLength>{termLengthYears, plural, one {{termLengthYears} year} other {{termLengthYears} year}} {policyType, select, plan {plan} policy {policy} other {plan}}</PlanLength><PlusIconWrapper>{plusIcon}</PlusIconWrapper> <MobileAlign>protects your purchase until: <strong>{planTermsEndDate}</strong></MobileAlign>"
              description="termLengthYears: plan length in years. planTermsEndDate: date protection plan ends. Calculation of when a selected protection plan ends. Changes with plus and minus selectors."
              values={{
                planTermsEndDate,
                termLengthYears,
                policyType,
                minusIcon: <svgs.Minus />,
                plusIcon: <svgs.Plus />,
                MinusIconWrapper: (chunks) => (
                  <IconWrapper data-cy="mobile-minus" onClick={handleClickDecrement}>
                    {chunks}
                  </IconWrapper>
                ),
                PlusIconWrapper: (chunks) => (
                  <IconWrapper data-cy="mobile-plus" onClick={handleClickIncrement}>
                    {chunks}
                  </IconWrapper>
                ),
                PlanLength: (chunks) => <PlanLength>{chunks}</PlanLength>,
                MobileAlign: (chunks) => <MobileAlign>{chunks}</MobileAlign>,
                strong: (chunks) => <strong>{chunks}*</strong>,
              }}
            />
          </MobileOnly>
        </PlanLengthDescription>
        <PlanBarGraph planDetails={planDetails.planDetails} termLengthInYears={Number(termLengthYears)} isAdh={isAdh} />
      </PlanLengthWrapper>
      <FootnoteWrapper>
        <CalculatorNote>*{calculatorNote}</CalculatorNote>
        {isAdh && <CalculatorFootnote>{calculatorFootnote}</CalculatorFootnote>}
      </FootnoteWrapper>
    </PlanLengthSection>
  )
}

const EmptySpacer = styled.p({ marginTop: 80 })

const PlanLengthSection = styled.section({
  position: 'initial',
  marginLeft: -32,
  marginTop: -6,
  width: '100vw',
  backgroundColor: COLOR.BLUE[700],
  [bp.lg]: {
    marginLeft: 40,
    marginTop: -102,
    width: '100%',
    backgroundColor: COLOR.WHITE,
  },
  [bp.xl]: {
    marginLeft: `calc(100vw - ${XL_PLAN_LENGTH_SECTION_WIDTH + XL_PADDING}px)`,
    width: XL_PLAN_LENGTH_SECTION_WIDTH,
  },
  [bp.maxWidth]: {
    marginLeft: 'auto',
  },
})

const PlanLengthWrapper = styled.div({
  background: COLOR.BLUE[700],
  color: COLOR.WHITE,
  minHeight: 0,
  padding: '32px 37px 16px',
  [bp.md]: {
    paddingBottom: 30,
  },
  [bp.lg]: {
    paddingBottom: 30,
    marginBottom: 16,
  },
})

const PlanLengthDescription = styled.div({
  margin: 0,
  fontSize: 20,
  fontWeight: 300,
  [bp.md]: {
    fontSize: 25,
  },
})

const FootnoteWrapper = styled.div({
  marginLeft: 37,
  paddingBottom: 32,
  paddingLeft: 29,
  maxWidth: BAR_GRAPH_MAX_WIDTH,
  [bp.md]: {
    paddingLeft: 0,
  },
  [bp.lg]: {
    paddingBottom: 0,
  },
})

const IconWrapper = styled.span({
  '&:hover': {
    cursor: 'pointer',
  },
  '& svg': {
    display: 'inline',
    transform: 'translateY(6px)',
    marginRight: 6,
    marginLeft: 6,
  },
  '& svg:hover > g text': {
    fill: COLOR.WHITE,
  },
  '& svg:hover > g circle': {
    stroke: COLOR.WHITE,
  },
  [bp.lg]: {
    '& svg': {
      marginRight: 10,
      marginLeft: 8,
    },
  },
})

const Footnote = styled.p<{ mobileHidden?: boolean; last?: boolean }>({
  paddingRight: 32,
  color: COLOR.WHITE,
  fontSize: 16,
  fontWeight: 300,
  margin: 0,
  '& + &': {
    marginTop: 8,
  },
  [bp.lg]: {
    color: COLOR.BLACK,
  },
})

const CalculatorNote = styled(Footnote)()
const CalculatorFootnote = styled(Footnote)()

const PlanLength = styled.span({
  fontWeight: 600,
})

const MobileOnly = styled.span({
  padding: '8px 0',
  display: 'block',
  [bp.md]: {
    padding: '16px 0',
    paddingLeft: 0,
    display: 'none',
  },
})

const MobileAlign = styled(MobileOnly)({
  paddingLeft: 29,
})

const DesktopOnly = styled.span({
  display: 'none',
  [bp.md]: {
    display: 'initial',
  },
})
export { PlanCalculator }
