import type { FormEvent, KeyboardEvent } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { COLOR } from '@customers-ui'
import { bp, validate } from '@extend/client-helpers'
import { formatPhoneNumberIso, formatPhoneNumberLocal, validatePhoneNumber } from '@extend/client-helpers'
import type { InputPrompt } from '@customers-api-client'
import { Slot } from '@customers-api-client'
import { isMobile } from 'react-device-detect'
import { svgs } from '../../../../lib/assets'
import { useScreenSize } from '../../../../hooks'
import { MailButton } from '../mail-button'
import type { UserInputComponentProps } from '../types'
import { ChatTextArea } from './chat-text-area'

const MAX_DESCRIPTION_LENGTH = 1000

export interface ChatTextInputProps extends UserInputComponentProps<InputPrompt> {}

export const ChatTextInput = ({ onAddInput, prompt }: ChatTextInputProps): JSX.Element => {
  const [message, setMessage] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorFeedback, setErrorFeedback] = useState<string>('')
  const screenSize = useScreenSize()
  const intl = useIntl()

  const { slot, validationType } = prompt
  let { placeholder } = prompt

  if (slot === Slot.ContractsSearchKey) {
    placeholder = screenSize === 'small' ? 'Search' : placeholder
  }
  placeholder = validationType === 'number' && !placeholder ? 'Input number' : placeholder

  const isFailureDescription = slot === Slot.FailureDescription
  const isDescriptionUnderMaxLength = message.length <= MAX_DESCRIPTION_LENGTH

  const isSubmitEnabled =
    (!!message && !isFailureDescription) || (!!message && isFailureDescription && isDescriptionUnderMaxLength)

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    if (message.trim()) {
      handleSubmitMessage(message.trim())
    }
  }

  const onKeyDown = (event: KeyboardEvent<HTMLFormElement>): void => {
    const { key } = event

    if (message.trim() && key === 'Enter') handleSubmitMessage(message.trim())
  }

  const handleSubmitMessage = (input: string): void => {
    if (isInvalidInput(input)) return

    let newMessage = input
    let formattedMessage = input
    if (validationType === 'phone') {
      const [, country] = intl.locale.split('-')
      newMessage = formatPhoneNumberIso(input, country)
      formattedMessage = formatPhoneNumberLocal(input, country)
    }

    onAddInput({ message: formattedMessage, slot, value: newMessage })
    setMessage('')
  }

  const isInvalidInput = (input: string): boolean => {
    if (validationType === 'phone' && !validatePhoneNumber(input)) {
      setErrorFeedback('Invalid phone number. Please try again.')
      setIsError(true)
      return true
    }
    if (validationType === 'email' && !validate.isValidEmail(input)) {
      setErrorFeedback('Invalid email format. Please try again.')
      setIsError(true)
      return true
    }
    // regex checks if input is a number or decimal number
    if (validationType === 'number' && !/^-?\d*\.?\d+$/.test(input)) {
      setErrorFeedback('Please enter a numeric value')
      setIsError(true)
      return true
    }
    if (isFailureDescription && !isDescriptionUnderMaxLength) return true

    setIsError(false)
    setErrorFeedback('')
    return false
  }

  const getCounterMessage = (): string => {
    const overRemaining = message.length > MAX_DESCRIPTION_LENGTH ? 'over' : 'remaining'
    const count = Math.abs(MAX_DESCRIPTION_LENGTH - message.length)

    return `${count} ${count === 1 ? 'character' : 'characters'} ${overRemaining}`
  }

  return (
    <Wrapper data-cy="chat-text-input">
      <Form {...{ onSubmit, onKeyDown }}>
        <ChatTextArea
          aria-label={placeholder}
          {...{
            setMessage,
            message,
            placeholder,
            validationType,
            isError,
            errorFeedback,
            slot,
            isMobile,
          }}
        />
        <StyledMailButton type="submit" disabled={!isSubmitEnabled} validationType={validationType} aria-label="send">
          {isSubmitEnabled ? <svgs.MailEnabled /> : <svgs.MailDisabled />}
        </StyledMailButton>
      </Form>
      <CharacterCounter
        data-cy="character-counter"
        isDescriptionUnderMaxLength={isDescriptionUnderMaxLength}
        isFailureDescription={isFailureDescription}
      >
        {getCounterMessage()}
      </CharacterCounter>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 768,
  [bp.md]: {
    padding: '0px 24px',
    maxWidth: 673,
  },
})

const Form = styled.form({
  display: 'flex',
  borderTop: `1px solid ${COLOR.NEUTRAL[500]}`,
  paddingRight: 10,
  [bp.md]: {
    borderTop: `1px solid ${COLOR.WHITE}`,
    paddingRight: 0,
    marginBottom: '28px',
  },
})

const CharacterCounter = styled.div<{
  isDescriptionUnderMaxLength: boolean
  isFailureDescription: boolean
}>(({ isDescriptionUnderMaxLength, isFailureDescription }) => ({
  display: 'flex',
  fontSize: 13,
  marginTop: -28,
  color: isDescriptionUnderMaxLength ? COLOR.NEUTRAL[600] : COLOR.RED[700],
  visibility: isFailureDescription ? 'visible' : 'hidden',
}))

const StyledMailButton = styled(MailButton)<{ validationType: string | undefined }>(({ validationType }) => ({
  paddingBottom: validationType ? 56 : 0,
}))
