import React from 'react'

export const InfoCircleIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18">
      <g fill="none" fillRule="evenodd" stroke="#A1A1A1" strokeWidth="1">
        <circle cx="9" cy="9" r="8" strokeWidth="2" />
        <path d="M8.5 4.5H9.5V5.5H8.5z" />
        <path d="M8.5 8.5H9.5V13.5H8.5z" />
      </g>
    </svg>
  )
}
