import { useState, useEffect } from 'react'
import type { ServiceOrder, ServiceOrderShipment } from '@customers-api-rtk-query'
import { useGetServiceOrderShipmentsQuery } from '@customers-api-rtk-query'

const NO_POLL_SERVICE_ORDER_STATUSES = ['created', 'assigned', 'completed', 'closed']
const TEN_SECOND_POLLING_INTERVAL = 10000
const THIRTY_SECOND_POLLING_INTERVAL = 30000
const SHORT_POLLING_TIMER = 60000
const MAX_POLLING_TIMER = 120000

interface UseShipmentsPollingResult {
  shipments?: ServiceOrderShipment[]
}

export const useShipmentsPolling = (serviceOrder?: ServiceOrder): UseShipmentsPollingResult => {
  const [shouldPollShipments, setShouldPollShipments] = useState(false)
  const [pollingInterval, setPollingInterval] = useState(TEN_SECOND_POLLING_INTERVAL)

  // Only start polling if service order exists and is in a pollable state
  useEffect(() => {
    if (!serviceOrder || NO_POLL_SERVICE_ORDER_STATUSES.includes(serviceOrder.status ?? '')) {
      setShouldPollShipments(false)
      return
    }
    setShouldPollShipments(true)
  }, [serviceOrder])

  const { data: shipments } = useGetServiceOrderShipmentsQuery(
    { serviceOrderId: serviceOrder?.id ?? '' },
    {
      pollingInterval,
      // Skip if no service order, or shouldn't poll, or if we already have shipments
      skip: !serviceOrder?.id || !shouldPollShipments,
    },
  )

  // Stop polling if we have shipments
  useEffect(() => {
    if (shipments && shipments.length > 0) {
      setShouldPollShipments(false)
    }
  }, [shipments])

  // Poll for shipments every 10 seconds for 2 minutes, then every 30 seconds for 2 minutes, then stop polling
  useEffect(() => {
    if (!shouldPollShipments) return

    const slowdownTimer = setTimeout(() => {
      setPollingInterval(THIRTY_SECOND_POLLING_INTERVAL)
    }, SHORT_POLLING_TIMER)

    const stopTimer = setTimeout(() => {
      setShouldPollShipments(false)
    }, MAX_POLLING_TIMER)

    return () => {
      clearTimeout(slowdownTimer)
      clearTimeout(stopTimer)
    }
  }, [shouldPollShipments])

  return { shipments }
}
