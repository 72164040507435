import type { ChangeEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@customers-ui'
import { ImagePreview } from './image-preview'

import svgs from '../../../../../lib/svgs'

interface RadioInputProps {
  checked: boolean
  name: string
  label: string
  value: number
  id: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  imageUrl?: string
}

const Checkbox = ({ checked, name, label, value, id, onChange, imageUrl }: RadioInputProps): JSX.Element => {
  return (
    <Card htmlFor={id}>
      <Input
        checked={checked}
        aria-checked={checked}
        id={id}
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
      />
      <Label>
        {imageUrl && (
          <ImageContainer>
            <ImagePreview imageUrl={imageUrl} />
          </ImageContainer>
        )}
        <LabelText>{label}</LabelText>
        <LabelCheck>
          <LabelIcon className="radio-icon-wrapper">
            <svgs.CircleCheckmark />
          </LabelIcon>
        </LabelCheck>
      </Label>
    </Card>
  )
}

const Card = styled.label()

const ImageContainer = styled.div({
  paddingLeft: 10,
  paddingRight: 15,
  maxHeight: 40,
})

const Label = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'left',
  width: '100%',
})

const LabelCheck = styled.div({
  display: 'flex',
  justifyContent: 'right',
  marginInlineStart: 'auto',
})

const LabelText = styled.div()

const LabelIcon = styled.div({
  flex: '0 1 22px',
  paddingLeft: 8,
  justifyContent: 'right',
})

const Input = styled.input({
  opacity: 0,
  position: 'absolute',
  // radio label ("selector")
  '+ div': {
    border: `1px solid ${COLOR.NEUTRAL[200]}`,
    cursor: 'pointer',
    borderRadius: 4,
    marginBottom: 8,
    minHeight: 48,
    padding: '15px 17px',
    '.radio-icon-wrapper': {
      opacity: 0,
    },
  },
  '&:hover': {
    ' + div': {
      borderColor: COLOR.BLUE[700],
      color: COLOR.BLUE[700],
      backgroundColor: COLOR.NEUTRAL[100],
    },
  },
  '&:checked': {
    ' + div': {
      borderColor: COLOR.BLUE[700],
      color: COLOR.BLUE[700],
      fontWeight: 600,
      backgroundColor: COLOR.NEUTRAL[100],
      '.radio-icon-wrapper': {
        opacity: 1,
      },
    },
  },
})

export type { RadioInputProps }
export { Checkbox }
