import type { PromptType } from '@extend-conversations/types'
import type { Reply, Slot, SlotValue } from '@extend-incredibot/types'
import type { ComponentType } from 'react'
import * as Yup from 'yup'
import { MultiSelect } from './multi-select'
import { ProductSelect } from './product-select'
import { TextInput } from './text-input'
import { DatePicker } from './date-picker'
import { ReasonSelect } from './reason-selector'
export interface UserInputComponentProps<T extends Reply> {
  onSubmit: (slot: Slot, slotValue: SlotValue) => void
  reply: T
  isLoading?: boolean
}

export const PROMPT_MAP: Partial<Record<PromptType | 'reasonSelect', ComponentType<UserInputComponentProps<any>>>> = {
  buttons: MultiSelect,
  datepicker: DatePicker,
  input: TextInput,
  multiselect: MultiSelect,
  productSelect: ProductSelect,
  reasonSelect: ReasonSelect,
}

export const DESCRIPTION_MAX = 1000
export const textInputSchema = Yup.object()
  .shape({
    description: Yup.string().label('Description').required('Description is required').max(DESCRIPTION_MAX),
  })
  .defined()
