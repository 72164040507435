import React from 'react'
import type { FC } from 'react'
import { LoadingText } from '@extend/zen'
import { images } from '../../../lib/assets'
import type { BaseStateProps } from './types'
import { Image, StatusText } from './styles'

export const AnalyzingState: FC<BaseStateProps> = ({ claim }) => {
  const isReturnResolution = claim?.type === 'return_resolution'

  return (
    <>
      <Image src={images.analyzingGif} data-cy="analyzing-claim-icon" />
      <StatusText data-cy="analyzing-claim-status-text">
        {isReturnResolution ? (
          <>
            Processing your <LoadingText text="return" />
          </>
        ) : (
          <>
            Analyzing your <LoadingText text="claim" />
          </>
        )}
      </StatusText>
    </>
  )
} 