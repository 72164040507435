import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { kebabCase } from 'lodash'
import styled from '@emotion/styled'
import type { ButtonsPrompt } from '@customers-api-client'
import { Button } from '@extend/zen'
import type { RootState } from '../../../../reducers'
import * as selectors from '../../../../reducers/selectors'
import type { UserInputComponentProps } from '../types'
import { ChatTrackCard } from './chat-track-card/chat-track-card'

interface SP {
  isLoading: ReturnType<typeof selectors.getChatIsLoading>
}

interface OP extends UserInputComponentProps<ButtonsPrompt> {}

type ChatButtonsInputProps = SP & OP

const Component = ({ prompt, onAddInput, isLoading }: ChatButtonsInputProps): JSX.Element | null => {
  const [isDisabled, setIsDisabled] = useState(false)
  const navigate = useNavigate()
  const { slot } = prompt

  if (!prompt.options) {
    return null
  }

  const renderButtons = prompt.options.map((option, index) => {
    const { title, value, outputText, redirectUrl } = option

    const handleClick = (): void => {
      setIsDisabled(true)
      if (!isLoading) {
        if (redirectUrl) {
          navigate(redirectUrl)
        } else {
          onAddInput({ message: outputText, value, slot })
        }
      }
    }

    return (
      <Button
        key={index}
        emphasis="medium"
        data-cy={`chat-button-${kebabCase(title)}`}
        size="regular"
        ariaLabel={title}
        text={title}
        isDisabled={isLoading || isDisabled}
        isProcessing={isLoading}
        onClick={handleClick}
      />
    )
  })

  return (
    <ChatButtonsInputWrapper>
      {prompt.card && (
        <ChatTrackCardWrapper>
          <ChatTrackCard cardData={prompt.card} />
        </ChatTrackCardWrapper>
      )}
      <ButtonGroup key="chat-buttons-input" data-cy="chat-buttons-input">
        {renderButtons}
      </ButtonGroup>
    </ChatButtonsInputWrapper>
  )
}

const BTN_MARGIN_RIGHT = 32
const CHAT_MAX_WIDTH = 673
const TOTAL_BODY_PADDING = 48
const OFFSET_DIFFERENCE = TOTAL_BODY_PADDING - BTN_MARGIN_RIGHT

const ChatButtonsInputWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  paddingBottom: 4,
})

const ChatTrackCardWrapper = styled.div({
  width: '100%',
  marginBottom: 32,
  paddingLeft: 24,
  paddingRight: 24,
})

const ButtonGroup = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  maxWidth: `${CHAT_MAX_WIDTH - OFFSET_DIFFERENCE}px`,
  width: `calc(100% - ${TOTAL_BODY_PADDING - BTN_MARGIN_RIGHT}px)`,
  gap: '8px 16px',
})

const ChatButtonsInput = connect(
  (state: RootState): SP => ({
    isLoading: selectors.getChatIsLoading(state),
  }),
)(Component)

export type { ChatButtonsInputProps }
export { ChatButtonsInput, Component }
