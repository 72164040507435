import { useGetClaimPhotosQuery } from '@customers-api-rtk-query'
import type { ClaimPhotoDetail } from '@extend-claims-management/types'

interface UsePhotoPollingProps {
  claimId: string
  shouldFetchPhotos: boolean
  pollingInterval?: number
  filterStatuses?: ClaimPhotoDetail['status'][]
}

/**
 * Hook to handle polling for photo statuses
 */
export function usePhotoPolling({
  claimId,
  shouldFetchPhotos,
  pollingInterval = 1000,
  filterStatuses = ['saved', 'rejected'],
}: UsePhotoPollingProps) {
  const { data: claimPhotosData, isLoading: isQueryLoading } = useGetClaimPhotosQuery(
    { claimId, filterStatuses },
    {
      skip: !shouldFetchPhotos,
      selectFromResult: (response) => ({
        ...response,
        data: response.data ? response.data.photoDetails : null,
      }),
      pollingInterval,
      refetchOnMountOrArgChange: true,
    },
  )

  return {
    claimPhotosData,
    isLoading: isQueryLoading,
  }
}
