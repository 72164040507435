import React from 'react'
import styled from '@emotion/styled'
import { Button, DataProperty, Badge, Check, Error } from '@extend/zen'
import type { AccountInfo } from '../../lib/consumer-profile-utils'

interface ProfilePresenterViewProps {
  accountInfo: AccountInfo
  onEditClick: () => void
  onVerifyClick: (isEmail: boolean) => void
  isProfileEditingEnabled: boolean
}

const ProfilePresenterView = ({
  accountInfo,
  onEditClick,
  onVerifyClick,
  isProfileEditingEnabled,
}: ProfilePresenterViewProps): JSX.Element => {
  const { isPhoneNumberVerified, isEmailVerified, name, phoneNumber, email, shippingAddress } = accountInfo

  return (
    <>
      <DataProperty data-cy="account-name" label="Name" value={name} />
      <VerifiablePropertyWrapper>
        <label htmlFor="phone">Phone Number</label>
        <VerifiablePropertyData id="phone" data-cy="account-phone">
          {phoneNumber && <p>{phoneNumber}</p>}
          {isPhoneNumberVerified ? (
            <Badge icon={Check} color="green" text="Verified" data-cy="verified-phone" />
          ) : (
            <UnverifiedWrapper>
              <Badge icon={Error} color="red" text="Unverified" data-cy="unverified-phone" />
              <Button onClick={() => onVerifyClick(false)} text="Verify" size="small" data-cy="verify-phone-button" />
            </UnverifiedWrapper>
          )}
        </VerifiablePropertyData>
      </VerifiablePropertyWrapper>
      <VerifiablePropertyWrapper>
        <label htmlFor="email">Email</label>
        <VerifiablePropertyData id="email" data-cy="account-email">
          {email && <p>{email}</p>}
          {isEmailVerified ? (
            <Badge icon={Check} color="green" text="Verified" data-cy="verified-email" />
          ) : (
            <UnverifiedWrapper>
              <Badge icon={Error} color="red" text="Unverified" data-cy="unverified-email" />
              <Button onClick={() => onVerifyClick(true)} text="Verify" size="small" data-cy="verify-email-button" />
            </UnverifiedWrapper>
          )}
        </VerifiablePropertyData>
      </VerifiablePropertyWrapper>
      <DataProperty data-cy="account-shipping-address" label="Shipping Address" value={shippingAddress} />
      {isProfileEditingEnabled && (
        <ButtonWrapper>
          <Button emphasis="medium" text="Edit" data-cy="edit-button" onClick={onEditClick} />
        </ButtonWrapper>
      )}
    </>
  )
}

const UnverifiedWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
})

const VerifiablePropertyWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  marginBottom: 8,
  label: {
    lineHeight: '24px',
    cursor: 'text',
    color: 'var(--neutral-600)',
    fontSize: 14,
    fontWeight: 700,
  },
})

const VerifiablePropertyData = styled.div({
  p: {
    overflow: 'hidden',
  },
  display: 'flex',
  flexDirection: 'row',
  lineHeight: '24px',
  alignItems: 'center',
  gap: 4,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export { ProfilePresenterView }
