import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { COLOR, Button } from '@customers-ui'

import type { RootState } from '../../reducers'
import { errorActions } from '../../actions'
import * as selectors from '../../reducers/selectors'
import { svgs } from '../../lib/assets'

const { CloseIcon } = svgs

interface DP {
  closeBanner: typeof errorActions.customersApiErrorReset
}

interface SP {
  showBanner: boolean
}

type BannerProps = DP & SP

export const Banner = ({ closeBanner, showBanner }: BannerProps): JSX.Element => {
  const [className, setClassName] = useState('')
  const [hasLoaded, setHasLoaded] = useState(false)

  const handleReload = (): void => {
    window.location.reload()
  }

  const handleClose = (): void => {
    closeBanner()
  }

  useEffect(() => {
    setHasLoaded(true)
  }, [])

  useEffect(() => {
    if (hasLoaded) {
      setClassName(showBanner ? 'show' : 'exit')
    }
  }, [showBanner])

  return (
    <BannerWrapper className={className}>
      <HeaderText>
        <BoldText>Oops!</BoldText> We ran into an issue
      </HeaderText>
      <ButtonWrapper>
        <Button kind="whiteOutline" size="sm" text="Reload" onClick={handleReload} />
        <CloseIconWrapper onClick={handleClose}>
          <CloseIcon />
        </CloseIconWrapper>
      </ButtonWrapper>
    </BannerWrapper>
  )
}

const drop = keyframes({
  '0%': {
    transform: 'translateY(-100%)',
  },
  '100%': {
    transform: 'translateY(0%)',
  },
})

const out = keyframes({
  '0%': {
    transform: 'translateY(0%)',
  },
  '100%': {
    transform: 'translateY(-100%)',
  },
})

const BannerWrapper = styled.div({
  display: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  background: `linear-gradient(90deg, ${COLOR.RED[600]} 0%, ${COLOR.RED[500]} 100%)`,
  height: 64,
  width: '100%',
  padding: '14px 16px',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: COLOR.WHITE,
  zIndex: 100,
  '&.show': {
    display: 'flex',
    animation: `${drop} 0.6s`,
  },
  '&.exit': {
    display: 'flex',
    animation: `${out} 0.6s forwards`,
  },
  [bp.md]: {
    padding: '14px 64px',
  },
  button: {
    padding: '0px 8px',
    height: '26px',
    fontSize: 13,
    marginRight: 32,
    [bp.md]: {
      fontSize: 16,
      padding: '4px 18px',
      height: '34px',
      marginRight: 64,
    },
  },
})

const HeaderText = styled.span({
  fontSize: 13,
  lineHeight: '22px',
  [bp.md]: {
    fontSize: '16px',
  },
})

const BoldText = styled.span({
  fontWeight: 'bold',
})

const ButtonWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const CloseIconWrapper = styled.div({
  width: 18,
  height: 16,
  '&:hover': {
    cursor: 'pointer',
  },
  '& > svg g': {
    stroke: COLOR.WHITE,
  },
})

export default connect(
  (state: RootState): SP => ({
    showBanner: selectors.getHasApiError(state),
  }),
  {
    closeBanner: errorActions.customersApiErrorReset,
  } as DP,
)(Banner)
