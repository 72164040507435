import React from 'react'
import { createPortal } from 'react-dom'
import { FormattedMessage } from 'react-intl'
import { isMobile } from 'react-device-detect'
import { Modal, ModalController } from '@extend/zen'
import styled from '@emotion/styled'

interface FileClaimLanguageOptionModalProps {
  onDismiss: () => void
  onContinue: () => void
  isModalOpen: boolean
}

const FileClaimLanguageOptionModal = ({
  onDismiss,
  onContinue,
  isModalOpen,
}: FileClaimLanguageOptionModalProps): JSX.Element => {
  return createPortal(
    <ModalController isOpen={isModalOpen}>
      <Modal
        heading={
          <FormattedMessage
            id="FILE_CLAIM_LANGUAGE_OPTION_MODAL_HEADING_ENGLISH"
            defaultMessage="Prefer to file your claim in French?"
          />
        }
        size="md"
        onDismissRequest={onDismiss}
        primaryButtonProps={{
          text: 'Continue in English',
          subText: (
            <FormattedMessage
              id="FILE_CLAIM_LANGUAGE_OPTION_MODAL_PRIMARY_BUTTON_SUBTEXT_TRANSLATED"
              defaultMessage="Continuer en anglais"
            />
          ),
          onClick: onContinue,
          emphasis: 'medium',
          borderRadius: '64px',
          'data-cy': 'file-claim-language-option-continue-button',
        }}
        secondaryButtonProps={{
          text: 'Close',
          subText: (
            <FormattedMessage
              id="FILE_CLAIM_LANGUAGE_OPTION_MODAL_SECONDARY_BUTTON_SUBTEXT_TRANSLATED"
              defaultMessage="Fermer"
            />
          ),
          onClick: onDismiss,
          emphasis: 'medium',
          borderRadius: '64px',
          'data-cy': 'file-claim-language-option-close-button',
        }}
        isMobile={isMobile}
      >
        <ContentWrapper data-cy="file-claim-language-option-modal-content-wrapper">
          <BodyText>
            <FormattedMessage
              id="FILE_CLAIM_LANGUAGE_OPTION_MODAL_SECONDARY_BUTTON_SUBTEXT_TRANSLATED"
              defaultMessage="Fermer"
            />
          </BodyText>

          <BodyText>
            <FormattedMessage
              id="FILE_CLAIM_LANGUAGE_OPTION_MODAL_CONTENT_TRANSLATED"
              defaultMessage="Il semble que vous déposiez une réclamation depuis le Canada. Si vous préférez déposer votre réclamation en français, <strong>veuillez nous appeler au 877-248-7707,</strong> ou continuer en anglais."
              values={{
                strong: (text: string) => <strong>{text}</strong>,
              }}
            />
          </BodyText>
        </ContentWrapper>
      </Modal>
    </ModalController>,
    document.body,
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  width: '100%',
  gap: '12px',
})

const BodyText = styled.p({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
})

export { FileClaimLanguageOptionModal }
