import React from 'react'
import type { FC } from 'react'
import { createPortal } from 'react-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import type { ClaimPhotosGetResponse } from '@customers-api-client'
import { Modal, ModalController } from '@extend/zen'
import { useGetStoreConsumerQuery } from '@customers-api-rtk-query'
import type { Claim } from '../../types/claim'
import { useClaimResultTimer } from './hooks/use-claim-result-timer'
import {
  ContentWrapper,
  AnalyzingState,
  PhotoRequirementsState,
  ApprovedState,
  ReviewState,
  DeniedState,
} from './states'
import { useIntl } from 'react-intl'

interface ClaimResultModalProps {
  claim: Claim
  onDismiss: () => void
  isModalOpen: boolean
  isPollingClaim?: boolean
  photoData?: ClaimPhotosGetResponse
}

const ClaimResultModal: FC<ClaimResultModalProps> = ({
  claim,
  onDismiss,
  isModalOpen,
  isPollingClaim = false,
  photoData,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { locale } = useIntl()

  const { data: store } = useGetStoreConsumerQuery({ storeId: claim?.sellerId }, { skip: !claim?.sellerId })

  const handleRedirectToMyClaims = (): void => {
    const isOnMyClaimsPage = location.pathname.includes('/my_claims')
    const redirectPath = claim?.type === 'return_resolution' ? `/${locale}/my_returns` : `/${locale}/my_claims`

    onDismiss()
    
    if (!isOnMyClaimsPage) {
      navigate(redirectPath, { replace: true })
    }
  }

  const { secondsLeft, isTimerRunning, showPhotoUploadReview, shouldRunAnalyzingClaimTimer } = useClaimResultTimer({
    isModalOpen,
    claim,
    photoData,
    isPollingClaim,
    onTimerComplete: handleRedirectToMyClaims,
  })

  const isClaimAnalyzing =
    (!claim || claim?.status === 'pending_adjudication' || shouldRunAnalyzingClaimTimer) && !showPhotoUploadReview
  const isReturnResolution = claim?.type === 'return_resolution'

  return createPortal(
    <ModalController isOpen={isModalOpen}>
      <Modal
        heading={isClaimAnalyzing ? (isReturnResolution ? 'Start a Return' : 'Claim Analysis') : 'Claim Result'}
        size="sm"
        onDismissRequest={onDismiss}
        primaryButtonProps={
          (!isClaimAnalyzing || showPhotoUploadReview) && !shouldRunAnalyzingClaimTimer
            ? {
                text: `Continue ${isTimerRunning ? `(0:${secondsLeft.toString().padStart(2, '0')})` : ''}`,
                onClick: handleRedirectToMyClaims,
                emphasis: 'medium',
                'data-cy': 'claim-result-modal-continue-button',
              }
            : undefined
        }
        isMobile={isMobile}
      >
        <ContentWrapper data-cy="claim-result-content-wrapper">
          {isClaimAnalyzing && <AnalyzingState claim={claim} />}
          {showPhotoUploadReview && claim?.status === 'pending_adjudication' && (
            <PhotoRequirementsState claim={claim} />
          )}
          {!shouldRunAnalyzingClaimTimer && claim?.status === 'approved' && <ApprovedState claim={claim} />}
          {!shouldRunAnalyzingClaimTimer && claim?.status === 'review' && <ReviewState claim={claim} />}
          {!shouldRunAnalyzingClaimTimer && claim?.status === 'denied' && (
            <DeniedState claim={claim} storeName={store?.name || undefined} />
          )}
        </ContentWrapper>
      </Modal>
    </ModalController>,
    document.body,
  )
}

export { ClaimResultModal }
