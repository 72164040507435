import React from 'react'

export const LearnMoreArrow = (): JSX.Element => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.83335 15.1667H18.865L13.1717 20.8601C12.7167 21.3151 12.7167 22.0617 13.1717 22.5167C13.6267 22.9717 14.3617 22.9717 14.8167 22.5167L22.505 14.8284C22.96 14.3734 22.96 13.6384 22.505 13.1834L14.8167 5.49506C14.3617 5.04006 13.6267 5.04006 13.1717 5.49506C12.7167 5.95006 12.7167 6.68506 13.1717 7.14006L18.865 12.8334L5.83335 12.8334C5.19169 12.8334 4.66669 13.3584 4.66669 14.0001C4.66669 14.6417 5.19169 15.1667 5.83335 15.1667Z"
        fill="#F3F6F9"
      />
    </svg>
  )
}
