import React from 'react'
import type { FC } from 'react'
import { InlineAlert, InlineAlertColor } from '@extend/zen'
import { useNavigate } from 'react-router-dom'

const ReviewProfileInfo: FC = () => {
  const navigate = useNavigate()
  const handleReviewClick = (): void => {
    navigate('/profile')
  }

  return (
    <InlineAlert
      data-cy="review-profile-info-alert"
      color={InlineAlertColor.neutral}
      primaryButtonProps={{
        text: 'Review Now',
        onClick: handleReviewClick,
        'data-cy': 'review-profile-info-button',
      }}
      isDismissable
    >
      Keep your info up to date! Visit <strong>My Profile</strong> to review your address and contact info.
    </InlineAlert>
  )
}

export { ReviewProfileInfo }
