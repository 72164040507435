import React from 'react'

const CloseModal = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width="13" height="13">
      <path
        d="M.4 12.7c.2.2.5.3.7.3.3 0 .6-.1.8-.3L6.5 8l4.6 4.6c.3.2.5.3.8.3.3 0 .6-.1.8-.3a1 1 0 000-1.6L8 6.5l4.6-4.6a1 1 0 000-1.6 1 1 0 00-1.6 0L6.5 5 1.9.4A1 1 0 00.3.4a1 1 0 000 1.5L5 6.5.4 11.1a1 1 0 000 1.6z"
        fill="#090637"
        fillRule="evenodd"
      />
    </svg>
  )
}

export { CloseModal }
