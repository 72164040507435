import React from 'react'
import styled from '@emotion/styled'

interface HamburgerIconProps {
  openMenu: () => void
  dataQa?: string
  'data-cy'?: string
  color: string
}

const HamburgerIcon = ({ openMenu, dataQa, 'data-cy': dataCy, color }: HamburgerIconProps): JSX.Element => (
  <Wrapper id="burger-icon" data-qa={dataQa} data-cy={dataCy} onClick={openMenu}>
    <Line color={color} />
    <Line color={color} />
    <Line color={color} />
  </Wrapper>
)

const Line = styled.div<{ color: string }>(({ color }) => ({
  height: '1px',
  background: color,
  marginBottom: '5px',
  width: '23px',
}))

const Wrapper = styled.div({
  alignSelf: 'center',
  marginTop: 5,
  position: 'fixed',
  '&:hover': {
    cursor: 'pointer',
  },
})

export { HamburgerIcon }
