import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'
import {
  useSearchContractsV1Query,
  getRTKQueryErrorMessage,
  useListOrdersQuery,
  adjudicationManagementApi,
} from '@customers-api-rtk-query'
import type { OrdersListByShipmentsResponse } from '@customers-api-rtk-query'
import { ContractType } from '@customers-api-client'
import { useNavigate } from 'react-router-dom'
import { customLogger, formatPhoneNumberLocal } from '@extend/client-helpers'
import * as selectors from '../../reducers/selectors'
import { EmptyPageContent } from './empty-page-content'
import { logEvent } from '../../analytics'
import { PlansPageHeader } from './plans-page-header'
import type { RootState } from '../../reducers'
import type { ContractsSearchIndividual } from '../../types/contract'
import { ContractListContainer } from './contract-list-container'
import { getAccountInfo } from '../../lib/consumer-profile-utils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../constants/ld-flags'
import { ReviewProfileInfo } from '@src/components/review-profile-info/review-profile-info'
import { ContentSection, ErrorToast, PageWrapper } from '@src/components/common'
import { Spinner } from '@customers-ui'
import { InlineAlertColor, InlineAlert } from '@extend/zen'

interface PlansPageProps {
  decodedAccessToken: ReturnType<typeof selectors.getDecodedAccessToken>
}

const Component = ({ decodedAccessToken }: PlansPageProps): JSX.Element => {
  const { [LDFlag.UseMegaCard]: FF_USE_MEGA_CARD, [LDFlag.UseAuthPopa]: FF_USE_AUTH_POPA } = useFlags()
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false)
  const email = decodedAccessToken ? decodedAccessToken.email : ''
  const phoneNumber = decodedAccessToken ? decodedAccessToken.phone_number : ''
  const navigate = useNavigate()
  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const isFirstTimeLogin = useSelector(selectors.getIsFirstTimeLogin)
  const { isPhoneNumberVerified, isEmailVerified, phoneNumber: consumerPhoneNumber } = getAccountInfo(consumerProfile)

  const userContact = decodedAccessToken?.email || formatPhoneNumberLocal(decodedAccessToken?.phone_number as string)

  const {
    data: { contracts } = {},
    isLoading: isContractsSearchLoading,
    error,
  } = useSearchContractsV1Query(
    {
      customerEmail: email,
      customerPhone: phoneNumber,
      typeFilter: [ContractType.PCRS, ContractType.CATEGORY, ContractType.PRODUCT_PROTECTION_BUNDLE],
      // we need this to see the productsList for category/pp_bundle contracts.
      showAll: true,
    },
    {
      skip: FF_USE_MEGA_CARD,
    },
  )

  const { data = [], isLoading: isListOrdersLoading } = useListOrdersQuery({ viewBy: 'shipments' })
  const emsOrdersResponse = data as OrdersListByShipmentsResponse
  const isLoading = FF_USE_MEGA_CARD ? isListOrdersLoading : isContractsSearchLoading || isListOrdersLoading

  // Error logging for requests
  const [hasLoggedError, setHasLoggedError] = useState(false)
  if (error && !hasLoggedError) {
    const errorMessage = getRTKQueryErrorMessage(error)
    customLogger.warn(`[Err: Plans Page]: ${errorMessage}`)
    setHasLoggedError(true)
  }

  useEffect(() => {
    if (error && !isVisible) {
      setIsVisible(true)
    }
    if (!isLoading) {
      if (FF_USE_MEGA_CARD && isEmpty(emsOrdersResponse)) {
        logEvent('My Orders - No Contracts Found', 'View My Orders')
      } else if (!FF_USE_MEGA_CARD && isEmpty(emsOrdersResponse) && isEmpty(contracts)) {
        logEvent('My Orders - Contracts Found for User', 'View My Orders')
      }
    }
  }, [error, isVisible, isLoading, contracts])

  // Invalidate cache to execute precheck every time the user navigates to this page to update it after a claim is filed
  useEffect(() => {
    setTimeout(() => dispatch(adjudicationManagementApi.util.invalidateTags(['adjudication-management'])))
  }, [])

  const handleVerifyClick = (): void => {
    navigate('/profile')
  }

  return (
    <PageWrapper pageTitle="My Orders" data-cy="plans-page-content-layout">
      <ErrorToast isVisible={isVisible} />
      <ContentSection data-cy="plans-page-content">
        {!FF_USE_AUTH_POPA && !isEmpty(consumerProfile) && !isEmailVerified && (
          <InlineAlert
            data-cy="no-email-alert"
            color={InlineAlertColor.red}
            primaryButtonProps={{
              onClick: handleVerifyClick,
              text: 'Verify Now',
              'data-cy': 'verify-email-button',
            }}
          >
            To process your claim, let&apos;s verify your email. We&apos;ll never share your information or spam you.
          </InlineAlert>
        )}

        {!FF_USE_AUTH_POPA && !isEmpty(consumerProfile) && !isPhoneNumberVerified && (
          <InlineAlert
            data-cy="no-phone-number-alert"
            color={InlineAlertColor.red}
            primaryButtonProps={{
              onClick: handleVerifyClick,
              text: 'Verify Now',
              'data-cy': 'verify-phone-button',
            }}
          >
            To process your claim, let&apos;s verify your phone number. We&apos;ll never share your information or spam
            you.
          </InlineAlert>
        )}
        {!isLoading && !error && <PlansPageHeader />}
        {isLoading && (
          <SpinnerLoading>
            <Spinner size="md" />
          </SpinnerLoading>
        )}
        {!isLoading &&
          ((FF_USE_MEGA_CARD && !isEmpty(emsOrdersResponse)) || (!FF_USE_MEGA_CARD && !isEmpty(contracts))) && (
            <ContractListContainer
              contracts={contracts as unknown as ContractsSearchIndividual[]}
              emsOrders={emsOrdersResponse}
            />
          )}
        {!isLoading && !error && isEmpty(contracts) && isEmpty(emsOrdersResponse) && (
          <EmptyPageContent
            primaryButtonText="Log in with a different account"
            primaryButtonUrl="/authentication"
            headerText="No orders found."
          >
            We couldn&apos;t find any orders for <b>{userContact}</b>
            {isFirstTimeLogin && (
              <>
                {' '}
                or <b>{formatPhoneNumberLocal(consumerPhoneNumber)}</b>
              </>
            )}
            . Try logging in with the phone or email linked to your order.
          </EmptyPageContent>
        )}
        {!isLoading && isFirstTimeLogin && !!emsOrdersResponse?.length && <ReviewProfileInfo />}
      </ContentSection>
    </PageWrapper>
  )
}

const SpinnerLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 20px',
})

const PlansPage = connect(
  (state: RootState): PlansPageProps => ({
    decodedAccessToken: selectors.getDecodedAccessToken(state),
  }),
)(Component)

export type { PlansPageProps }
export { Component, PlansPage }
