import React from 'react'
import type { FC } from 'react'
import { celebrate } from '@extend/zen'
import { images } from '../../../lib/assets'
import type { BaseStateProps } from './types'
import { Image, StatusText, MessageBody } from './styles'

export const ApprovedState: FC<BaseStateProps> = () => {
  return (
    <>
      {celebrate()}
      <Image src={images.approvedIcon2} data-cy="approved-claim-icon" />
      <StatusText data-cy="approved-claim-status-text">Your claim is approved!</StatusText>
      <MessageBody data-cy="approved-claim-message-body">
        Click &quot;Continue&quot; to proceed with the fulfillment process.
      </MessageBody>
    </>
  )
} 