import React from 'react'

const ClaimTextBubbles = (): JSX.Element => {
  return (
    <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.56667 74.2C9.30973 74.2 9.0528 74.1289 8.82867 73.9841C8.33393 73.667 8.09887 73.0711 8.241 72.5026L10.5479 63.2667H9.56667C4.29133 63.2667 0 58.9753 0 53.7V34.5667C0 29.2913 4.29133 25 9.56667 25H42.3667C47.642 25 51.9333 29.2913 51.9333 34.5667C51.9333 35.3211 51.3211 35.9333 50.5667 35.9333H39.6333C35.8668 35.9333 32.8 39.0001 32.8 42.7667V59.1667C32.8 59.8965 32.9394 60.6399 33.2237 61.4435C33.3713 61.8617 33.3084 62.3264 33.0515 62.6899C32.7945 63.0507 32.3791 63.2667 31.9363 63.2667H23.7144L10.4222 73.9021C10.1735 74.0989 9.87007 74.2 9.56667 74.2ZM9.56667 27.7333C5.80013 27.7333 2.73333 30.8001 2.73333 34.5667V53.7C2.73333 57.4693 5.80013 60.5333 9.56667 60.5333H12.3C12.7209 60.5333 13.1173 60.7274 13.3769 61.0581C13.6366 61.3889 13.7268 61.8207 13.6257 62.2307L11.8736 69.2363L22.3778 60.8313C22.6211 60.6399 22.9217 60.5333 23.2333 60.5333H30.176C30.1022 60.0741 30.0667 59.6204 30.0667 59.1667V42.7667C30.0667 37.4913 34.358 33.2 39.6333 33.2H49.0633C48.4292 30.084 45.6658 27.7333 42.3667 27.7333H9.56667Z"
        fill="#0033CC"
      />
      <path
        d="M72.4333 79.6667C72.1299 79.6667 71.8265 79.5656 71.5778 79.3688L58.2856 68.7334H39.6333C34.358 68.7334 30.0667 64.4421 30.0667 59.1667V42.7667C30.0667 37.4914 34.358 33.2001 39.6333 33.2001H72.4333C77.7087 33.2001 82 37.4914 82 42.7667V59.1667C82 64.4421 77.7087 68.7334 72.4333 68.7334H71.4521L73.7617 77.9693C73.9039 78.5379 73.6661 79.1337 73.1741 79.4508C72.9472 79.5957 72.6903 79.6667 72.4333 79.6667ZM39.6333 35.9334C35.8668 35.9334 32.8 39.0002 32.8 42.7667V59.1667C32.8 62.936 35.8668 66.0001 39.6333 66.0001H58.7667C59.0755 66.0001 59.3789 66.1067 59.6222 66.298L70.1264 74.703L68.3743 67.6975C68.2732 67.2902 68.3634 66.8556 68.6231 66.5249C68.8828 66.1941 69.2791 66.0001 69.7 66.0001H72.4333C76.2026 66.0001 79.2667 62.936 79.2667 59.1667V42.7667C79.2667 39.0002 76.2026 35.9334 72.4333 35.9334H39.6333Z"
        fill="#0033CC"
      />
    </svg>
  )
}

export { ClaimTextBubbles }
