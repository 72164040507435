import React from 'react'
import type { PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import { HeadTag } from '@src/lib/customers-ui/src/components/head-tag'

interface PageWrapperProps {
  pageTitle?: string
  'data-cy'?: string
  hasPadding?: boolean
}

export const PageWrapper = ({
  children,
  'data-cy': dataCy,
  pageTitle = 'Extend',
  hasPadding = true,
}: PropsWithChildren<PageWrapperProps>): JSX.Element => {
  return (
    <Wrapper data-cy={dataCy} hasPadding={hasPadding}>
      <HeadTag title={pageTitle} />
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ hasPadding: boolean }>(({ hasPadding }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 47,
  width: '100%',
  maxWidth: '1134px',
  margin: '0 auto',
  ...(hasPadding
    ? {
        [bp.mobile]: {
          padding: '16px',
        },
        [bp.desktop]: {
          padding: '24px',
        },
      }
    : {}),
}))
