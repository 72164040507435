import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTheme } from '@emotion/react'

interface UseContactSupportResult {
  isMiniZendeskEnabled: boolean
  contactSupport: () => void
}

/**
 * Hook to handle contact support functionality
 */
export const useContactSupport = (): UseContactSupportResult => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { FEATURES_MIN_ZD_APP: isMiniZendeskEnabled } = useFlags()

  const contactSupport = (): void => {
    // Use merchant support URL if available, otherwise navigate to contact page
    if (theme.merchantConfiguration?.merchantSupportUrl) {
      window.open(theme.merchantConfiguration.merchantSupportUrl, '_blank')
    } else {
      navigate('/contact')
    }
  }

  return { isMiniZendeskEnabled, contactSupport }
}
