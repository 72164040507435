import type { ComponentProps, ReactNode } from 'react'
import React from 'react'
import { FormControl as ChakraFormControl, FormLabel, FormErrorMessage, FormHelperText } from '@chakra-ui/react'
import { COLOR } from '@extend/zen'

interface FormControlProps extends ComponentProps<typeof ChakraFormControl> {
  id?: string
  label?: string
  errorMessage?: string
  isAsteriskVisible?: boolean
  hint?: string
  children?: ReactNode
}

const FormControl = ({
  children,
  id,
  label,
  errorMessage,
  isInvalid,
  isAsteriskVisible,
  hint,
  ...props
}: FormControlProps): JSX.Element => {
  return (
    <ChakraFormControl isInvalid={isInvalid} {...props}>
      {label && (
        <FormLabel {...LabelStyles} htmlFor={id}>
          {`${label}${isAsteriskVisible ? '*' : ''}`}
        </FormLabel>
      )}
      {children}
      {errorMessage && isInvalid && <FormErrorMessage {...ErrorMessageStyles}>{errorMessage}</FormErrorMessage>}
      {!isInvalid && hint && <FormHelperText {...HelperTextStyles}>{hint}</FormHelperText>}
    </ChakraFormControl>
  )
}

const LabelStyles = {
  marginBottom: 4,
  color: COLOR.NEUTRAL[1000],
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '22px',
}

const ErrorMessageStyles = {
  marginTop: 4,
  color: COLOR.RED[700],
  fontWeight: 700,
  fontSize: 10,
  lineHeight: '14px',
}

const HelperTextStyles = {
  marginTop: 8,
  fontSize: 14,
  color: COLOR.NEUTRAL[700],
  lineHeight: '19px',
  fontWeight: 400,
}

export { FormControl }
