import React, { type PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import type { PlanDetails } from '@customers-api-client'

type ClaimStepsProps = {
  planDetails: PlanDetails
}

const ClaimSteps = ({ planDetails }: ClaimStepsProps): JSX.Element => {
  const {
    claimsProcessHeader,
    claimsProcessImage,
    claimsProcessIcon1,
    claimsProcessTitle1,
    claimsProcessBody1,
    claimsProcessIcon2,
    claimsProcessTitle2,
    claimsProcessBody2,
    claimsProcessIcon3,
    claimsProcessTitle3,
    claimsProcessBody3,
  } = planDetails.planDetails

  return (
    <Section title={claimsProcessHeader}>
      <StepList>
        {[
          [claimsProcessTitle1, claimsProcessIcon1, claimsProcessBody1],
          [claimsProcessTitle2, claimsProcessIcon2, claimsProcessBody2],
          [claimsProcessTitle3, claimsProcessIcon3, claimsProcessBody3],
        ].map(([title, iconSrc, body]) => {
          return (
            <StepItem key={title}>
              <IconWrapper>
                <Icon src={iconSrc} />
              </IconWrapper>
              <DescriptionWrapper>
                <Title>{title}</Title>
                {/* Renders the templated anchor tag from API response with '/claims' */}
                <Body
                  dangerouslySetInnerHTML={{
                    __html: body.replace('{{claimsLinkUrl}}', '/claims'),
                  }}
                />
              </DescriptionWrapper>
            </StepItem>
          )
        })}
      </StepList>
      <ImageWrapper>
        <ClaimsProcessImage src={claimsProcessImage} alt="File a claim online" />
      </ImageWrapper>
    </Section>
  )
}

type SectionProps = PropsWithChildren<{
  title: string
}>

const Section = ({ children, title }: SectionProps): JSX.Element => (
  <CenteredSection>
    <SectionTitle>{title}</SectionTitle>
    <FlexWrapper>{children}</FlexWrapper>
  </CenteredSection>
)

const CenteredSection = styled.section({
  textAlign: 'center',
  maxWidth: 1000,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: 40,
  [bp.md]: {
    marginBottom: 64,
  },
})

const FlexWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  flexDirection: 'column',
  [bp.md]: {
    flexDirection: 'row',
  },
  [bp.lg]: {
    width: 1025,
  },
})

const SectionTitle = styled.h2({
  fontSize: 26,
  fontWeight: 700,
  margin: 0,
  marginBottom: 40,
  padding: '0 8px',
  [bp.md]: {
    fontSize: 39,
    marginBottom: 95,
  },
})

const StepList = styled.ul<{ column?: boolean; half?: boolean }>({
  display: 'flex',
  listStyleType: 'none',
  margin: 0,
  marginBottom: 70,
  height: '100%',
  justifyContent: 'space-between',
  padding: 0,
  flexDirection: 'column',
  flexWrap: 'nowrap',
  flexBasis: '50%',
  flex: 2,
  [bp.md]: {
    marginBottom: 40,
    marginTop: 40,
    paddingRight: 57,
    height: 490,
    width: 405,
  },
})

const StepItem = styled.li({
  textAlign: 'left',
  display: 'flex',
  marginTop: 0,
  marginBottom: 16,
  '&:last-child': {
    marginBottom: 0,
  },
  [bp.md]: {
    marginBottom: 32,
    marginTop: 0,
  },
})

const DescriptionWrapper = styled.div({
  flex: 1,
})

const Title = styled.h3({
  margin: 0,
  marginBottom: 8,
  textTransform: 'uppercase',
  fontSize: 13,
  fontWeight: 'bold',
  [bp.md]: {
    fontSize: 16,
  },
})

const Body = styled.p({
  margin: 0,
  fontSize: 16,
  [bp.md]: {
    fontSize: 20,
  },
})

const ClaimsProcessImage = styled.img({
  height: 'auto',
  width: '100%',
  marginTop: 0,
  maxWidth: 253,
  [bp.md]: {
    maxWidth: 452,
  },
})

const ImageWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [bp.md]: {
    flexBasis: 'auto',
  },
})

const IconWrapper = styled.div({
  marginRight: 29,
  width: 40,
  transform: 'translateY(-10px)',
  [bp.md]: {
    width: 61,
    transform: 'translateY(0)',
  },
})

const Icon = styled.img({
  width: '100%',
  maxWidth: 40,
  height: 'auto',
  maxHeight: 52,
  objectFit: 'contain',
})

export { ClaimSteps }
