import React from 'react'
import styled from '@emotion/styled'
import { Header } from '../../header'
import { PortalFooter } from '../../footer'
import { Banner } from '..'
import { HEADER_HEIGHT } from '../../../types/constants'

interface AppLayoutProps {
  isLoggedIn: boolean
  locale: string
  children: React.ReactNode
}

export const AppLayout = ({ isLoggedIn, locale, children }: AppLayoutProps): JSX.Element => {
  return (
    <LayoutWrapper>
      <Header isLoggedIn={isLoggedIn} />

      <MainContent>
        <Banner />
        <ContentWrapper>{children}</ContentWrapper>
      </MainContent>

      <PortalFooter locale={locale} />
    </LayoutWrapper>
  )
}

const LayoutWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
})

const MainContent = styled.main({
  flex: 1,
  paddingTop: `var(--header-height, ${HEADER_HEIGHT}px)`, // Use constant as fallback
  display: 'flex',
  flexDirection: 'column',
})

const ContentWrapper = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})
