import React from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import {
  usePrecheckQuery,
  useGetMerchantServicingSettingsQuery,
  useLazyGetStoreConsumerQuery,
} from '@customers-api-rtk-query'
import { Badge, Shield } from '@extend/zen'
import type {
  ExtendedWarrantyContract,
  ProductProtectionBundleContract,
  ExtendedWarrantyProduct,
  CustomBundleProduct,
} from '../../../types/contract'
import {
  formatDate,
  getCustomBundleProduct,
  getDisplayDetails,
  getIsClaimInProgress,
  isBundleContract,
  isExtendedWarrantyContract,
  missingProfileFieldTooltip,
} from '../../../lib/helper-functions'
import { images } from '../../../lib/assets'
import { logEvent } from '../../../analytics'
import { getCanTransfer } from '../../contract-transfer/contract-transfer.utils'
import type { CardListItemProps } from '../../../components/card'
import { Card } from '../../../components/card'

export interface ProductProtectionCardProps {
  contract: ExtendedWarrantyContract | ProductProtectionBundleContract
  onFileClaimClick: (handlerArgs: { contractId: string; isMerchantOwnedIntake?: boolean }) => void
  isMissingRequiredProfileField?: boolean
}

const ProductProtectionCard = ({
  contract,
  onFileClaimClick,
  isMissingRequiredProfileField,
}: ProductProtectionCardProps): JSX.Element => {
  const navigate = useNavigate()
  const [fetchStore] = useLazyGetStoreConsumerQuery()
  const { data: precheck, isLoading } = usePrecheckQuery({ contractId: contract.id })
  const { data: merchantServicingSettings } = useGetMerchantServicingSettingsQuery({
    sellerId: contract.sellerId,
  })

  const { purchaseDate, sellerName, id } = contract

  const isContractExtendedWarranty = isExtendedWarrantyContract(contract)
  const contractProduct: ExtendedWarrantyProduct | CustomBundleProduct = isContractExtendedWarranty
    ? ((contract as ExtendedWarrantyContract).product as ExtendedWarrantyProduct)
    : (getCustomBundleProduct(contract as ProductProtectionBundleContract) as CustomBundleProduct)
  const productName =
    (contractProduct as CustomBundleProduct).title || (contractProduct as ExtendedWarrantyProduct).name

  const { isClaimFilingDisabled, fileClaimTooltip, subBadgeText, badgeDetails, badgeColor } = getDisplayDetails({
    precheck,
    contract,
  })

  const shouldDisplayContactSeller =
    precheck?.status === 'failure' && precheck?.validationError === 'mfr_warranty_active'

  const handleContactSeller = async (): Promise<void> => {
    logEvent('My Plans - Contract Card - Clicks', 'Contact Seller')
    let storeDomain
    if (!contract?.resellerMerchant?.domain) {
      const store = await fetchStore({
        storeId: contract.sellerId,
        version: 'latest',
      }).unwrap()
      storeDomain = store.domain
    }

    window.open(contract?.resellerMerchant?.domain ?? `https://${storeDomain}`, '_blank')
  }

  const handleReviewTerms = (e: React.SyntheticEvent): void => {
    logEvent('My Plans - Contract Card - Clicks', 'Review Terms')
    if (!isContractExtendedWarranty && !isBundleContract) {
      e.stopPropagation()
    } else {
      navigate(`/my_plans/${id}`)
    }
  }

  const handleTransferPlan = (): void => {
    logEvent('My Plans - Contract Card - Clicks', 'Transfer Plan')
    navigate(`/my_plans/${id}/transfer`)
  }

  const BadgeSubText = () => {
    return (
      <BadgeWrapper>
        <Badge text="Product Protection" color="blue" data-cy="category-coverage-badge" icon={Shield} />
        {!isLoading && (
          <>
            {isClaimInProgress ? (
              <Badge
                emphasis="low"
                text={subBadgeText || 'Claim in Progress'}
                data-cy="product-protection-progress-badge"
                color="green"
              />
            ) : (
              <Badge
                emphasis="low"
                text={badgeDetails}
                color={badgeColor}
                data-cy="product-protection-coverage-badge"
              />
            )}
          </>
        )}
      </BadgeWrapper>
    )
  }

  const isClaimInProgress = getIsClaimInProgress(precheck)
  const canTransfer = getCanTransfer({ contract, hasActiveClaim: isClaimInProgress })
  const headerMenuItems = [
    {
      text: 'Review Terms & Conditions',
      onClick: handleReviewTerms,
    },
    ...(shouldDisplayContactSeller
      ? [
          {
            text: 'Contact Seller',
            onClick: handleContactSeller,
          },
        ]
      : []),
    ...(canTransfer
      ? [
          {
            text: 'Transfer Plan',
            onClick: handleTransferPlan,
          },
        ]
      : []),
  ]

  const listItems = [
    {
      image: contractProduct.imageUrl || images.fallbackImage,
      text: (
        <ProductTitle
          onClick={(e) => (!isContractExtendedWarranty ? e.stopPropagation() : navigate(`/my_plans/${id}`))}
        >
          {productName}
        </ProductTitle>
      ),
      subText: <BadgeSubText />,
      primaryButtonProps: {
        text: 'File a Claim',
        emphasis: 'medium',
        isDisabled: !precheck || isClaimFilingDisabled || isMissingRequiredProfileField,
        tooltip: isMissingRequiredProfileField ? missingProfileFieldTooltip : fileClaimTooltip,
        onClick: () =>
          onFileClaimClick({
            contractId: id,
            isMerchantOwnedIntake: merchantServicingSettings?.isMerchantOwnedIntake,
          }),
      },
      menuItems: isMobile ? headerMenuItems : undefined,
      extendLiveOverride: {
        image: 'product-protection-image',
      },
    },
  ] as CardListItemProps[]

  return (
    <Card
      data-cy="product-protection"
      dataProps={{
        'data-extend-live': 'product-protection-card-wrapper',
        'data-contract-id': id,
        'data-extend-live-pp-id': id,
      }}
      heading={`Order from ${sellerName}`}
      subHeading={`Purchased ${formatDate(purchaseDate)}`}
      menuItems={!isMobile ? headerMenuItems : undefined}
      primaryGroups={[
        {
          listItems,
          itemsShowMoreThreshold: 1,
          itemsShowMoreText: 'View all items',
          itemsShowLessText: 'View less items',
        },
      ]}
    />
  )
}

const BadgeWrapper = styled.div({
  display: 'inline-flex',
  gap: 8,
})

const ProductTitle = styled.div({
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
})

export { ProductProtectionCard }
