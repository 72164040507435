import React from 'react'
import { Timeline, TimelineItem } from '@extend/zen'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import type { Claim, ServiceOrder } from '@customers-api-client'

export type TimelineState = 'complete' | 'current' | 'upcoming' | 'success'

export interface TimelineStep {
  label: string
  isVisible: boolean
  renderActiveContent?: (claim: Claim, serviceOrder?: ServiceOrder) => JSX.Element | null
  renderCompleted?: (claim: Claim, serviceOrder?: ServiceOrder) => JSX.Element | null
  renderActiveFooter?: (claim: Claim, serviceOrder?: ServiceOrder) => JSX.Element | null
  isSuccessOnActive?: boolean
}

export interface TimelineConfig {
  steps: TimelineStep[]
}

export interface ClaimTimelineProps {
  config: TimelineConfig
  claim: Claim
  serviceOrder?: ServiceOrder
  activeStepIndex: number
}

const getStepState = (stepIndex: number, activeStepIndex: number, isSuccessOnActive?: boolean): TimelineState => {
  if (stepIndex < activeStepIndex) return 'complete'
  if (stepIndex === activeStepIndex) return isSuccessOnActive ? 'success' : 'current'
  return 'upcoming'
}

/**
 * A component that renders a timeline for claim processing
 *
 * Features:
 * - Renders steps based on configuration
 * - Shows/hides steps based on visibility
 * - Handles different content for active and completed states
 * - Supports footer content for active steps
 */
const ClaimTimeline = ({ config, claim, serviceOrder, activeStepIndex }: ClaimTimelineProps): JSX.Element => {
  return (
    <Container data-cy="claim-timeline">
      <Timeline>
        {config.steps.map((step, index) =>
          step.isVisible ? (
            <TimelineItem
              key={step.label}
              label={step.label}
              state={getStepState(index, activeStepIndex, step.isSuccessOnActive)}
              data-cy={`timeline-step-${index}`}
            >
              {index === activeStepIndex && step.renderActiveContent?.(claim, serviceOrder)}
              {index < activeStepIndex && step.renderCompleted?.(claim, serviceOrder)}
            </TimelineItem>
          ) : (
            <React.Fragment key={step.label} />
          ),
        )}
      </Timeline>
      {config.steps[activeStepIndex]?.renderActiveFooter?.(claim, serviceOrder)}
    </Container>
  )
}

const Container = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  button: {
    display: 'block !important',
  },
  gap: 15,
})

export const TimelineFooter = styled.div({
  width: '100%',
  display: 'flex',
  paddingTop: 8,
  [bp.mobile]: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  [bp.desktop]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
})

export { ClaimTimeline }
