import type { ComponentType, Dispatch, SetStateAction } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import type { Prompt, PromptType } from '@customers-api-client'
import { Slot } from '@customers-api-client'
import { connect } from 'react-redux'
import { useDetectScreenResize, useMeasureRef, useScreenSize } from '../../../hooks'
import { chatActions } from '../../../actions'
import type { UserInputComponentProps } from './types'
import { ChatTextInput } from './user-inputs/chat-text-input'
import { ChatTextWithButtonsInput } from './user-inputs/chat-text-with-buttons-input/chat-text-with-buttons-input'
import { ChatDateInput } from './user-inputs/chat-date-input/chat-date-input'
import { ChatButtonsInput } from './user-inputs/chat-buttons-input'
import { ChatCarousel } from './user-inputs/chat-carousel'
import { ChatUserInputAnimateHeight } from './chat-user-input-animate-height'
import { ChatMultiselect } from './user-inputs/chat-multiselect'
import { ChatImageInput } from './user-inputs/chat-image-input'
import { ChatAddressInput } from './user-inputs/chat-address-input'
import { ChatShipmentSelect } from './user-inputs/chat-shipment-select'
import { ChatProductSelect } from './user-inputs/chat-product-select'

export const PROMPT_MAP: Record<PromptType, ComponentType<UserInputComponentProps<any>>> = {
  input: ChatTextInput,
  inputWithButtons: ChatTextWithButtonsInput,
  buttons: ChatButtonsInput,
  datepicker: ChatDateInput,
  carousel: ChatCarousel,
  multiselect: ChatMultiselect,
  imageUpload: ChatImageInput,
  addressInput: ChatAddressInput,
  shipmentSelect: ChatShipmentSelect,
  productSelect: ChatProductSelect,
}

interface DP {
  onAddInput: typeof chatActions.chatSessionUpdate
}

interface OP {
  isPromptHidden: boolean
  prompt: Prompt | null
  setChatInputHeight: Dispatch<SetStateAction<number>>
  chatInputHeight: number
}

type ChatUserInputProps = DP & OP

export const ADJUSTED_DATEPICKER_HEIGHT = 105
const MOBILE_VIEWPORT_BREAKPOINT = 767

const Component = ({
  prompt,
  onAddInput,
  setChatInputHeight,
  chatInputHeight,
  isPromptHidden,
}: ChatUserInputProps): JSX.Element | null => {
  const PromptComponent = prompt ? PROMPT_MAP[prompt.type] : null
  const { measureRef, height } = useMeasureRef()
  const screenSize = useScreenSize()

  const handleResize = (): void => {
    if (!isPromptHidden) {
      setChatInputHeight(height)
    }
  }

  useDetectScreenResize(handleResize, 0, false)

  useEffect(() => {
    const totalVerticalPadding = screenSize === 'small' ? 20 : 25

    if (isPromptHidden) return
    const shouldRenderFullSizedInputPrompts = window.innerWidth >= MOBILE_VIEWPORT_BREAKPOINT
    if (prompt?.type === 'datepicker' && shouldRenderFullSizedInputPrompts) {
      setChatInputHeight(ADJUSTED_DATEPICKER_HEIGHT)
    } else if (prompt?.slot === Slot.FailureDescription) {
      setChatInputHeight(height + totalVerticalPadding + 32)
    } else {
      setChatInputHeight(height + totalVerticalPadding)
    }
  }, [isPromptHidden, height, prompt, screenSize, setChatInputHeight, measureRef])

  return (
    <ChatUserInputSection>
      <ChatUserInputAnimateHeight
        prompt={prompt}
        setChatInputHeight={setChatInputHeight}
        isPromptHidden={isPromptHidden}
        chatInputHeight={chatInputHeight}
      >
        <InputWrapper ref={measureRef} data-cy="chat-input-wrapper">
          {prompt && PromptComponent && <PromptComponent prompt={prompt} onAddInput={onAddInput} />}
        </InputWrapper>
      </ChatUserInputAnimateHeight>
    </ChatUserInputSection>
  )
}

const InputWrapper = styled.div({
  height: 'fit-content',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'flex-start',
})

const ChatUserInputSection = styled.section({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
})

const ChatUserInput = connect(null, {
  onAddInput: chatActions.chatSessionUpdate,
} as DP)(Component)

export { ChatUserInput, Component }
