export const CLAIM_PREPARING_DESCRIPTION = "We're preparing your claim. This may take a moment."
export const CLAIM_PREPARING_STATUS_MESSAGE = 'Preparing claim'
export const CLAIM_APPROVED = 'Claim approved'
export const CUSTOMER_TO_RETURN_THE_DEFECTIVE_PRODUCT = 'Awaiting the customer to return the defective product'
export const CLAIM_FULFILLED = 'Claim fulfilled'
export const PAYOUT_OPTION_SELECTED = 'Payout option selected'
export const DOWNLOAD_SHIPMENT_LABEL = 'Download shipment label'
export const CLAIM_DENIED = 'Claim denied'
export const GET_HELP_BUTTON = 'Get help'

// Layout constants
export const HEADER_HEIGHT = 72 // pixels

export const getClaimPreparingDescription = (entityName: string): string => {
  return `We're preparing your ${entityName}. This may take a moment.`
}

export const getClaimPreparingStatusMessage = (entityName: string): string => {
  return `Preparing ${entityName}`
}
