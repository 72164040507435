import type { CSSProperties } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { images } from '../../lib/assets'

export interface LogoProps {
  isShield?: boolean
  extraCss?: CSSProperties
}

const Logo = ({ extraCss, isShield }: LogoProps): JSX.Element => {
  if (isShield) return <ShieldLogoImg src={images.extendIcon} alt="Extend icon" />
  return <LogoImg id="extend-logo" src={images.extendLogo} alt="Extend logo" style={extraCss} />
}

const ShieldLogoImg = styled.img({
  width: 16,
  height: 16,
})

const LogoImg = styled.img({
  width: '64px',
  height: '15px',
  backgroundSize: 'cover',
  marginLeft: '16px',
})

export default Logo
