import React, { type PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

interface Props {
  title?: string
  stylesheets?: string[]
}

export const HeadTag = ({ title = 'Extend', stylesheets = [], children }: PropsWithChildren<Props>): JSX.Element => {
  const stylesheetTags = stylesheets.map((url) => <link rel="stylesheet" href={url} key={url} />)
  return createPortal(
    <>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {stylesheetTags}
      {children}
    </>,
    document.head,
  )
}
