import React from 'react'
import type { FC } from 'react'
import type { Claim as ApiClientClaim, ServiceOrder } from '@customers-api-client'
import type { OrdersListByShipmentsResponse } from '@customers-api-rtk-query'
import styled from '@emotion/styled'
import type { BadgeProps } from '@extend/zen'
import { COLOR, Stack, Badge, CaptionLegal, Paragraph } from '@extend/zen'
import type { Claim } from '../../../types/claim'
import { Card } from '../../../components/card'
import { getMultipleProductsHeader, getReturnSubHeading } from '../../../lib/card-utils'
import { getFilteredOrderAndProductImagesForClaimCard } from './claim-card-helpers'
import { ClaimStateMachine } from '../claim-state-machine'
import { useServiceOrderPolling } from '../../../hooks/use-service-order-polling'
import { getActiveServiceOrder } from '../utils'

export interface ReturnClaimCardProps {
  claim: Claim | ApiClientClaim
  order: OrdersListByShipmentsResponse[0]
}

const defaultBadgeProps: BadgeProps = {
  text: 'Awaiting Item',
  color: 'blue',
}

const pendingBadgeProps: BadgeProps = {
  text: 'Pending',
  color: 'yellow',
}

const soStatusToBadgeText: Partial<Record<ServiceOrder['status'], BadgeProps>> = {
  created: {
    text: 'Awaiting Item',
    color: 'blue',
  },
  assigned: {
    text: 'Awaiting Item',
    color: 'blue',
  },
  accepted: {
    text: 'Awaiting Item',
    color: 'blue',
  },
  approved: {
    text: 'Refund Approved',
    color: 'green',
  },
  fulfilled: {
    text: 'Complete',
    color: 'green',
  },
  completed: {
    text: 'Complete',
    color: 'green',
  },
}

const getBadgeText = (activeServiceOrder: ServiceOrder | undefined): BadgeProps => {
  if (!activeServiceOrder) return pendingBadgeProps
  return soStatusToBadgeText[activeServiceOrder.status] ?? defaultBadgeProps
}

export const ReturnClaimCard: FC<ReturnClaimCardProps> = ({ claim, order: baseOrder }) => {
  if (!baseOrder) {
    return null
  }

  const { order, productImages, orderProducts } = getFilteredOrderAndProductImagesForClaimCard(claim, baseOrder)

  const { serviceOrders } = useServiceOrderPolling(claim as Claim)

  const activeServiceOrder = getActiveServiceOrder(serviceOrders)

  const preHeading = (
    <Stack spacing={1} isRow align="center">
      <ReturnText>Return</ReturnText>
      <Badge {...getBadgeText(activeServiceOrder)} />
    </Stack>
  )

  const getSubHeading = () => (
    <SubHeadingWrapper>
      <Paragraph>{getReturnSubHeading(order)}</Paragraph>
      <CaptionLegal>{orderProducts.length} item(s) in return</CaptionLegal>
      {productImages && productImages.length > 0 && (
        <ProductImageContainer>
          {productImages.slice(0, 5).map((image, index) => (
            <ProductImage key={index} imageUrl={image} />
          ))}
        </ProductImageContainer>
      )}
    </SubHeadingWrapper>
  )

  return (
    <Card
      data-cy="return-claim-card"
      key={claim.id}
      preHeading={preHeading}
      heading={getMultipleProductsHeader(orderProducts)}
      subHeading={getSubHeading()}
    >
      <ClaimStateMachine
        key={claim.id}
        claim={claim as ApiClientClaim}
        handleResultModalOpen={() => {}}
        serviceOrders={serviceOrders}
      />
    </Card>
  )
}

const ProductImageContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
})

const ProductImage = styled.img<{ imageUrl: string }>(({ imageUrl }) => ({
  width: 40,
  height: 40,
  borderRadius: '4px',
  background: `url(${imageUrl}), lightgray 50% / cover no-repeat;`,
  backgroundSize: 'contain',
}))

const ReturnText = styled(Paragraph)({
  color: COLOR.NEUTRAL[600],
  fontWeight: 800,
  textTransform: 'uppercase',
})

const SubHeadingWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
})
