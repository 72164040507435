import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { ShipmentSelectPrompt, Slot } from '@customers-api-client'
import { Modal, ModalController } from '@extend/zen'
import { Button } from '@customers-ui'
import styled from '@emotion/styled'
import { createPortal } from 'react-dom'
import { chatActions } from '../../../../../actions'
import type { RootState } from '../../../../../reducers'
import * as selectors from '../../../../../reducers/selectors'
import { ShipmentSelector } from './shipment-selector'

type ChatShipmentSelectProps = {
  onAddInput: typeof chatActions.chatSessionUpdate
}

const ChatShipmentSelect = ({ onAddInput }: ChatShipmentSelectProps): JSX.Element => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const prompt = useSelector((state: RootState) => selectors.getChatPrompt(state)) as ShipmentSelectPrompt

  const onSelect = (slot: Slot, value: string | number, outputText?: string): void => {
    dispatch(chatActions.chatPromptClear())
    onAddInput({ slot, value, message: outputText })
  }

  const handleModalOpen = (): void => {
    setIsModalOpen(true)
  }

  const handleModalClose = (): void => {
    setIsModalOpen(false)
  }

  return (
    <>
      <ChatShipmentSelectButtonWrapper>
        <Button
          dataCy="chat-shipment-select-button"
          kind="pillButton"
          size="xxs"
          type="button"
          ariaLabel="Select shipment"
          text="Select shipment"
          onClick={handleModalOpen}
        />
      </ChatShipmentSelectButtonWrapper>

      {createPortal(
        <ModalController isOpen={isModalOpen}>
          <Modal heading="" onDismissRequest={handleModalClose}>
            <ShipmentSelector data-cy="shipment-selector" isLoading={false} onSelect={onSelect} prompt={prompt} />
          </Modal>
        </ModalController>,
        document.body,
      )}
    </>
  )
}

const ChatShipmentSelectButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
})

export { ChatShipmentSelect }
