import { images } from '../../../lib/assets'
import { getOrderProducts } from '../../../lib/ems-utils'
import type { Claim as ApiClientClaim } from '@customers-api-client'
import type { Claim } from '../../../types/claim'
import type { EmsProduct, EmsProductWithShipmentInfo, OrdersListByShipmentsResponse } from '@customers-api-rtk-query'

export const getFilteredOrderAndProductImagesForClaimCard = (
  claim: Claim | ApiClientClaim,
  baseOrder: OrdersListByShipmentsResponse[0],
): {
  order: OrdersListByShipmentsResponse[0]
  productImages: string[] | undefined
  orderProducts: Array<EmsProduct | EmsProductWithShipmentInfo>
} => {
  const { products } = claim

  const order: OrdersListByShipmentsResponse[0] = {
    ...baseOrder,
    untrackedProducts: baseOrder.untrackedProducts.filter((product) =>
      products?.some((cp) => cp.lineItemId === product.lineItemId),
    ),
    trackedProducts: Object.entries(baseOrder.trackedProducts).reduce((acc, [trackingGroupId, trackingGroup]) => {
      const filteredProducts = trackingGroup.products.filter((p) =>
        products?.some((cp) => cp.lineItemId === p.lineItemId),
      )
      if (!filteredProducts.length) return acc
      return {
        ...acc,
        [trackingGroupId]: {
          ...trackingGroup,
          products: filteredProducts,
        },
      }
    }, {}),
  }

  const orderProducts = getOrderProducts(order)
  let validImages = 0
  let productImages: string[] | undefined = orderProducts.map((product) => {
    if (product.imageUrl) {
      validImages++
    }
    return product.imageUrl ?? images.fallbackImage
  })

  if (validImages === 0) {
    productImages = undefined
  }

  return {
    order,
    productImages,
    orderProducts,
  }
}
