import type { ReactElement } from 'react'
import React, { useEffect, useState } from 'react'
import type { Element } from '@customers-api-client'
import { useDispatch, useSelector } from 'react-redux'
import ChatTyping from './chat-typing'
import { ChatBubble } from './chat-bubble'
import ChatBubbleWrapper from './chat-bubble-wrapper'
import ChatHiddenDiv from './chat-hidden-div'
import ChatContent from './chat-content'
import { chatActions } from '../../../actions'
import type { RootState } from '../../../reducers'
import { getChatPrompt } from '../../../reducers/selectors'
import { isMultiselectPrompt } from '../../../lib/chat-utils'
import { useImage, useMeasureRef } from '../../../hooks'

interface ChatMessageProps {
  content: string
  bot: boolean
  imageUrl?: string
  isTyping: boolean
  lastIndex: boolean
  duration: number
  elements?: Element[]
  setMoveHead: (moveHead: boolean) => void
  setChatInputHeight: React.Dispatch<React.SetStateAction<number>>
  type?: string
}

const ChatMessage = ({
  content,
  bot,
  imageUrl,
  isTyping,
  lastIndex,
  duration,
  elements,
  setChatInputHeight,
  type,
}: ChatMessageProps): JSX.Element => {
  // Set animation start and end points to expand from <ChatTyping /> to Kaley's message text
  const { width, height, measureRef } = useMeasureRef()
  const [prevWidth] = useState(0)
  const { status: imageStatus, image } = useImage(imageUrl)

  // Show / hide content based on animation state
  const [textVisible, setTextVisible] = useState(false)
  const [showChatTyping, setShowChatTyping] = useState(false)

  // Whenever the width/height of the chat bubble changes, temporarily hide the text
  // until the animation completes and then show the text and move <KaleyChatHead />
  useEffect(() => {
    let textVisibleTimeout: number

    if (bot) {
      setChatInputHeight(0)
    }

    setTextVisible(false)
    // Wait for the bubble to expand before showing <ChatTyping />
    const showTypingTimeout = window.setTimeout(() => {
      setShowChatTyping(true)
    }, duration)

    if (width && !isTyping) {
      // The user messages are on a delayed entrance of one animation cycle, to prevent jumping.
      // Triple the timeout to show the text to allow the user input animations to complete
      const timeout = duration * (bot ? 1 : 3) + 100
      textVisibleTimeout = window.setTimeout(() => {
        setTextVisible(true)
      }, timeout)
    }

    return () => {
      clearTimeout(showTypingTimeout)
      clearTimeout(textVisibleTimeout)
    }
  }, [width, height, duration, isTyping, bot, setChatInputHeight])

  const showItem = (item: ReactElement, condition: boolean): ReactElement => (
    <div style={{ visibility: condition ? 'visible' : 'hidden' }}>{item}</div>
  )

  const dispatch = useDispatch()

  const isTextSelectMessageType = type === 'textSelect'
  const activePrompt = useSelector((state: RootState) => getChatPrompt(state))
  const isMultiSelectPromptActive = activePrompt && isMultiselectPrompt(activePrompt)
  const handleClick = (): void => {
    //  ensures that clicking on the chat-bubble toggles
    //  "isPromptHidden" only when "multiselect" prompt is active
    if (!(isTextSelectMessageType && isMultiSelectPromptActive)) return
    dispatch(chatActions.chatMultiselectShow())
  }

  return (
    <>
      <ChatBubbleWrapper bot={bot} height={height}>
        <ChatBubble
          handleClick={handleClick}
          role={isTextSelectMessageType ? 'button' : 'none'}
          width={width}
          prevWidth={prevWidth}
          height={height}
          open={showChatTyping}
          duration={duration}
          bot={bot}
          lastIndex={lastIndex}
          isTyping={isTyping}
        >
          {isTyping
            ? showItem(<ChatTyping />, showChatTyping)
            : showItem(
                <ChatContent data-cy="chat-content" elements={elements} content={content} image={image} type={type} />,
                textVisible && !['idle', 'loading'].includes(imageStatus),
              )}
        </ChatBubble>
      </ChatBubbleWrapper>
      {/* Hidden div to measure the width/height points to animate to */}
      <ChatHiddenDiv ref={measureRef} isTyping={isTyping} content={content} elements={elements} image={image} />
    </>
  )
}
export { ChatMessage }
