import React from 'react'
import { useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useLocation, useMatch, Navigate, Route, Routes, type RouteProps } from 'react-router-dom'
import type { ConnectRequest } from '@extend-incredibot/types'
import { customLogger } from '@extend/client-helpers'
import { LDFlag } from '../constants/ld-flags'
import * as pages from './pages'
import type { CustomersRouteProps } from './types'
import { routes as PublicRoutes } from './routes/public-routes'
import { routes as ProtectedRoutes } from './routes/protected-routes'
import { injectParams, determineLocale } from './utils'
import * as selectors from '../reducers/selectors'
import { setItem, getItem } from '../store/persistence'
import { RouterError } from '../components/common'
import { useTheme } from '@emotion/react'
import { SUPPORTED_LANGUAGES } from '../types/localization'

const ProtectedRoute = ({ element }: RouteProps): JSX.Element => {
  const isLoggedIn = useSelector(selectors.getIsLoggedIn)
  const location = useLocation()
  if (location && 'pathname' in location) {
    setItem('prevLocation', location.pathname)
  }

  if (!isLoggedIn) {
    return <pages.MyExtendAuthenticationPage />
  }
  return <>{element}</>
}

const RedirectWithParams = ({ to, path }: { to: string; path: string }) => {
  const match = useMatch(path)
  const location = useLocation()
  const locale = location.pathname.split('/')[1]
  const redirectPath = locale ? `/${locale}${to}${injectParams(match?.params || {}, path)}${location.search}` : to

  return <Navigate to={redirectPath} replace />
}

const WarrantyTermsRedirect = ({ locale }: { locale: string }) => {
  const location = useLocation()
  const planPath = location.pathname.replace(`/${locale}/warranty_terms`, '')

  return (
    <Navigate
      to={{
        pathname: `/${locale}/plan_details${planPath}`,
        search: location.search,
        hash: location.hash,
      }}
    />
  )
}

const ChatPortalRoute = () => {
  const location = useLocation()
  const params = useMatch('/:locale/kaley')?.params
  const state = location.state as Partial<ConnectRequest>
  const locale = params?.locale as string | undefined
  const redirectPath = locale ? `/${locale}/my_plans` : '/my_plans'

  if (!state?.contractId && !state?.orderId) {
    customLogger.warn('ContractId or OrderId missing from state. Redirecting to /my_plans', {
      previousPath: getItem('prevLocation'),
    })
    return <Navigate to={redirectPath} />
  }

  return <pages.ChatPortal />
}

const LocaleRedirect = () => {
  const location = useLocation()
  const currentLocale = determineLocale(location.pathname)
  const path = location.pathname.startsWith('/') ? location.pathname : `/${location.pathname}`
  const search = location.search || ''

  // Split path into segments and remove empty ones
  const segments = path.split('/').filter(Boolean)

  // If first segment is a valid locale, but not the correct one, redirect to correct locale
  if (SUPPORTED_LANGUAGES.includes(segments[0]) && segments[0] !== currentLocale) {
    // Replace incorrect locale with correct one
    segments[0] = currentLocale
    // Remove duplicate segments
    const uniqueSegments = [...new Set(segments)]
    return <Navigate to={`/${uniqueSegments.join('/')}${search}`} replace />
  }

  // If path doesn't start with a locale, add the correct one
  if (!SUPPORTED_LANGUAGES.includes(segments[0])) {
    // Remove duplicate segments
    const uniqueSegments = [...new Set(segments)]
    return <Navigate to={`/${currentLocale}/${uniqueSegments.join('/')}${search}`} replace />
  }

  // If there are duplicate segments in the path, remove them
  const uniqueSegments = [...new Set(segments)]
  if (uniqueSegments.length !== segments.length) {
    return <Navigate to={`/${uniqueSegments.join('/')}${search}`} replace />
  }

  // Path already has correct locale and no duplicates, no redirect needed
  return null
}

const Component = ({ isLoggedIn, locale }: CustomersRouteProps): JSX.Element => {
  const {
    [LDFlag.IsUnauthenticatedWismoEnabled]: FF_IS_UNAUTHENTICATED_WISMO_ENABLED,
    [LDFlag.ClaimIntakeForm]: FF_CLAIM_INTAKE_FORM,
  } = useFlags()
  const theme = useTheme()
  const { isExtend } = theme.merchantConfiguration

  const login = isLoggedIn ? <Navigate to={`/${locale}/my_plans`} /> : <Navigate to={`/${locale}/authentication`} />

  // Filter out returns route if isExtend is true
  const filteredProtectedRoutes = ProtectedRoutes.filter((route) => {
    if (isExtend && route.path.includes('my_returns')) {
      return false
    }
    return true
  })

  return (
    <Routes>
      {/* Locale redirect handler - catches all non-localized routes */}
      <Route path="*" element={<LocaleRedirect />} />

      {/* Root redirect */}
      <Route path="/" element={login} key="root-redirect" />
      <Route path={`/${locale}`} element={login} key="locale-root-redirect" />

      {/* Add review_claim redirect before other routes */}
      <Route
        path="/review_claim"
        element={<Navigate to={`/${locale}/review_claim${location.search}`} replace />}
        key="review-claim-redirect"
      />

      <Route path={'/claims'} element={<Navigate to={`/${locale}/my_claims`} />} key={'claims-redirect'} />
      <Route path={'/claim'} element={<Navigate to={`/${locale}/my_claims`} />} key={'claim-redirect'} />
      <Route path={'/track'} element={<Navigate to={`/${locale}/my_claims`} />} key={'track-redirect'} />

      {/* Only redirect public routes */}
      {PublicRoutes.filter(({ path }) => path !== '/').map(({ path }) => {
        return <Route path={path} key={`${path}-redirect`} element={<RedirectWithParams to={path} path={path} />} />
      })}

      <Route
        path={`/${locale}/sign_up`}
        key={'sign_up-redirect'}
        element={<Navigate to={`/${locale}/authentication`} />}
      />

      {/* Warranty Terms Redirect */}
      <Route
        path={`/${locale}/warranty_terms/:planId?`}
        key={'warranty_terms-redirect'}
        element={<WarrantyTermsRedirect locale={locale} />}
      />

      {/* Path's for chat portal */}
      <Route path="/:locale/kaley" key={'chat-portal-locale-route'} element={<ChatPortalRoute />} />
      <Route path="/kaley" key={'chat-portal-route'} element={<ChatPortalRoute />} />

      {/* Public Routes */}
      {PublicRoutes.map(({ path, element }) => {
        if (path === '/' && !isLoggedIn) {
          return <Route path={path} element={<Navigate to={`/${locale}/authentication`} />} key={path} />
        }

        if (path === '/tracking' && !FF_IS_UNAUTHENTICATED_WISMO_ENABLED) {
          return null
        }

        if (path === '/my_claims/file-claim' && !FF_CLAIM_INTAKE_FORM) {
          return null
        }

        const Element = element
        return <Route path={`/${locale}${path}`} element={<Element />} key={path} />
      })}

      {/* Protected Routes */}
      {filteredProtectedRoutes.map((route) => {
        const path = `/${locale}${route.path}`
        const Element = route.element
        return <Route path={path} key={path} element={<ProtectedRoute element={<Element />} />} />
      })}

      {/* Login Routes */}
      <Route path="/login" key={'login-route'} element={login} />

      {/* Catch all error route - only for truly invalid paths */}
      <Route path={`/${locale}/*`} key={'catch-all-route'} element={<RouterError />} />
    </Routes>
  )
}

export { Component as Routes }
