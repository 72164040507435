import React from 'react'
import type { PropsWithChildren } from 'react'
import styled from '@emotion/styled'

export const ContentSection = ({ children, className }: PropsWithChildren<{ className?: string }>): JSX.Element => {
  return <Section className={className}>{children}</Section>
}

const Section = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
})
