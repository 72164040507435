import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { ProductSelectPrompt, Slot } from '@customers-api-client'
import { Modal, ModalController } from '@extend/zen'
import { createPortal } from 'react-dom'
import { Button } from '@customers-ui'
import styled from '@emotion/styled'
import { chatActions } from '../../../../../actions'
import * as selectors from '../../../../../reducers/selectors'
import { ProductSelector } from './product-selector'
import type { ProductSelectorProps } from './product-selector'

type ChatProductSelectProps = {
  onAddInput: typeof chatActions.chatSessionUpdate
}

const ChatProductSelect = ({ onAddInput }: ChatProductSelectProps): JSX.Element => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const prompt = useSelector(selectors.getChatPrompt) as ProductSelectPrompt

  const onSelect = (slot: Slot, value: string[], title: string): void => {
    dispatch(chatActions.chatPromptClear())

    const outputText = getOutputText(value, title)

    onAddInput({ slot, value, message: outputText })
  }

  const getOutputText = (value: string[], title: string): string => {
    let outputText = `I would like to file a request for ${title}`

    if (value.length > 1) {
      const itemOrItems = value.length === 2 ? 'item' : 'items'
      const outputTextAppend = `and ${value.length - 1} more ${itemOrItems}`
      outputText += ` ${outputTextAppend}`
    }

    return outputText
  }

  const handleModalOpen = (): void => {
    setIsModalOpen(true)
  }

  const handleModalClose = (): void => {
    setIsModalOpen(false)
  }

  return (
    <>
      <ChatProductSelectButtonWrapper>
        <Button
          dataCy="chat-item-select-button"
          kind="pillButton"
          size="xxs"
          type="button"
          ariaLabel="Select item(s)"
          text="Select item(s)"
          onClick={handleModalOpen}
        />
      </ChatProductSelectButtonWrapper>

      {createPortal(
        <ModalController isOpen={isModalOpen}>
          <Modal heading="" onDismissRequest={handleModalClose}>
            <ProductSelector
              data-cy="product-selector"
              isLoading={false}
              onSelect={onSelect as ProductSelectorProps['onSelect']}
              prompt={prompt}
            />
          </Modal>
        </ModalController>,
        document.body,
      )}
    </>
  )
}

const ChatProductSelectButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
})

export { ChatProductSelect }
