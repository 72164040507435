import React from 'react'
import type {
  ConnectRequest,
  EmsProduct,
  EmsProductWithShipmentInfo,
  OrdersListByShipmentsResponse,
} from '@customers-api-rtk-query'
import { useTheme } from '@emotion/react'
import { getIsClaimInProgress, getShipmentIssueTooltip } from '../../../lib/helper-functions'
import type { CardGroupItemProps } from '../../../components/card'
import { Card } from '../../../components/card'
import { getDisplayDetails, mapHeadingText, normalizeCarrier } from '../utils'
import { getHasShippingEntitlement, getIsShippingResolution, getOrderProducts } from '@src/lib/ems-utils'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useGenerateOrderCardListItems } from '@src/hooks/use-generate-order-card-list-items'
import { ShippingProtectionBadgePreHeading } from './shipping-protection-badge-heading'
import { getDataProps, getSubHeading, getTitleHeading } from './order-card-utils'
import { usePrecheckEmsData } from '@src/hooks'
import type { ButtonProps } from '@extend/zen'
import { OpenInNew } from '@extend/zen'

interface OrderCardProps {
  order: OrdersListByShipmentsResponse[0]
  onFileClaimClick: (handlerArgs: {
    contractId?: string
    orderId?: string
    claimType?: ConnectRequest['claimType']
    isMerchantOwnedIntake?: boolean
    lineItemIds?: string[]
    shipmentId?: string
    trackingId?: string
  }) => void
  isMissingRequiredProfileField?: boolean
}

export const OrderCard = ({
  order,
  onFileClaimClick,
  isMissingRequiredProfileField = false,
}: OrderCardProps): JSX.Element => {
  const {
    merchantConfiguration: { isExtend },
  } = useTheme()

  const navigate = useNavigate()
  const { locale } = useIntl()

  const { data: precheckData } = usePrecheckEmsData(order)
  const isLoading = !precheckData

  const { merchantServicingSettings, generateListItems } = useGenerateOrderCardListItems(
    order,
    isMissingRequiredProfileField,
    onFileClaimClick,
    precheckData,
    isLoading,
  )

  const isShippingResolution = getIsShippingResolution(order)
  const hasShippingEntitlement = getHasShippingEntitlement(order)

  const shippingResolutionPrecheck = precheckData ? precheckData[order.id] : undefined
  const shippingProtectionContractId = order.contracts?.find((contract) => contract.type === 'shipping_protection')?.id
  const shippingProtectionPrecheck =
    precheckData && shippingProtectionContractId ? precheckData[shippingProtectionContractId] : undefined

  const shipmentDisplayDetails = getDisplayDetails({
    precheck: shippingProtectionPrecheck?.precheckResponse || shippingResolutionPrecheck?.precheckResponse,
    status: shippingProtectionPrecheck?.contract?.status,
    type: shippingResolutionPrecheck ? 'shipping_resolution' : 'shipping_protection',
  })

  const hasActiveShipmentClaim = getIsClaimInProgress(
    shippingProtectionPrecheck?.precheckResponse || shippingResolutionPrecheck?.precheckResponse,
    getOrderProducts(order),
  )

  const generatePrimaryButtonProps = (products: EmsProductWithShipmentInfo[] | EmsProduct[], trackingId?: string) => {
    const hasActiveClaimOnShipment = getIsClaimInProgress(
      shippingProtectionPrecheck?.precheckResponse || shippingResolutionPrecheck?.precheckResponse,
      products,
    )

    const shipmentIssueTooltip = getShipmentIssueTooltip({
      isMissingRequiredProfileField,
      fileClaimTooltip: shipmentDisplayDetails?.fileClaimTooltip,
      hasActiveClaim: hasActiveClaimOnShipment,
      hasTrackingInfo: !!trackingId,
      isBeforeEta: (shippingProtectionPrecheck?.precheckResponse as any)?.validationError === 'before_eta',
    })
    const hasShippingResolutionAndProtectionEntitlement = shippingResolutionPrecheck && shippingProtectionPrecheck

    return {
      emphasis: 'medium',
      text: 'Report Shipment Issue',
      isDisabled:
        // do not enable if extend domain and a shipping resolution order
        (isExtend && isShippingResolution) ||
        hasShippingResolutionAndProtectionEntitlement ||
        !precheckData ||
        shipmentDisplayDetails?.isClaimFilingDisabled ||
        isMissingRequiredProfileField ||
        hasActiveClaimOnShipment,
      tooltip: shipmentIssueTooltip,
      onClick: () =>
        onFileClaimClick({
          contractId: shippingProtectionContractId,
          orderId: isShippingResolution ? order.id : undefined,
          claimType: isShippingResolution ? 'shipping_resolution' : undefined,
          isMerchantOwnedIntake: merchantServicingSettings?.isMerchantOwnedIntake,
          lineItemIds: products
            .filter((products) => !!products.entitlements?.length)
            .map((product) => product.lineItemId),
          shipmentId: products.find((product) => !!product.tracking)?.tracking?.id,
          trackingId,
        }),
    }
  }

  const generateSecondaryButtonProps = (products: EmsProductWithShipmentInfo[]) => {
    const shipmentInfo = products.find((product) => !!product.shipmentInfo)?.shipmentInfo
    const trackingInfo = products.find((product) => !!product.tracking)?.tracking
    const carrierCode = normalizeCarrier(shipmentInfo?.provider)
    const wismoTrackingUrl = `/${locale}/tracking?orderId=${order.id}&trackingNumber=${shipmentInfo?.trackingId}&carrierCode=${carrierCode}`
    const fallbackTrackingUrl = shipmentInfo?.trackingUrl || trackingInfo?.trackingUrl

    return {
      emphasis: 'medium',
      text: 'Track Package',
      ...(!carrierCode && { icon: OpenInNew }),
      isDisabled: !shipmentInfo,
      dataProps: {
        'data-extend-live': 'track-package-button',
      },
      onClick: () => {
        if (shipmentInfo) {
          if (carrierCode) {
            navigate(wismoTrackingUrl)
          } else if (fallbackTrackingUrl) {
            window.open(fallbackTrackingUrl, '_blank')
          }
        }
      },
      tooltip: 'Track your shipment',
    }
  }

  const trackedGroups = Object.entries(order.trackedProducts || {}).map(
    ([trackingId, { products, shipmentInfo }], index) => {
      const hasShippingEntitlement = products.some((product) =>
        product.entitlements?.some(
          (entitlement) => entitlement.type === 'shipping_protection' || entitlement.type === 'shipping_resolution',
        ),
      )
      const isShippingProtection = products.some((product) =>
        product.entitlements?.some((entitlement) => entitlement.type === 'shipping_protection'),
      )
      const foundShipmentInfo = products.find((product) => product.shipmentInfo?.trackingId === trackingId)

      return {
        preHeading: `Ship ${index + 1} · `,
        heading: mapHeadingText(shipmentInfo, foundShipmentInfo?.tracking),
        primaryButtonProps: hasShippingEntitlement
          ? generatePrimaryButtonProps(products as EmsProductWithShipmentInfo[], trackingId)
          : undefined,
        secondaryButtonProps: generateSecondaryButtonProps(products),
        listItems: generateListItems(products),
        showMoreGroupsText: 'View all shipments',
        showLessGroupsText: 'View less shipments',
        menuItems: isShippingProtection
          ? [
              {
                text: 'Get help with contract',
                onClick: () => navigate(`/${locale}/contact`),
              },
            ]
          : undefined,
      }
    },
  ) as CardGroupItemProps[]

  const hasUntrackedShippingEntitlement = order.untrackedProducts?.some((product) =>
    product.entitlements?.some(
      (entitlement) => entitlement.type === 'shipping_protection' || entitlement.type === 'shipping_resolution',
    ),
  )
  const untrackedGroup =
    order.untrackedProducts?.length &&
    ({
      heading: 'Pending shipment details',
      primaryButtonProps: hasUntrackedShippingEntitlement
        ? generatePrimaryButtonProps(order.untrackedProducts)
        : undefined,
      secondaryButtonProps: generateSecondaryButtonProps(order.untrackedProducts),
      listItems: generateListItems(order.untrackedProducts),
    } as CardGroupItemProps)

  const getHeaderPrimaryButtonProps = (): ButtonProps | undefined => {
    // Get all products in a single array
    const allProducts = [
      ...(order.untrackedProducts || []),
      ...Object.values(order.trackedProducts || {}).flatMap(({ products }) => products),
    ]

    // Find products with return resolution entitlements
    const productsWithReturnResolution = allProducts.filter((product) =>
      product.entitlements?.some((entitlement) => entitlement.type === 'return_resolution'),
    )

    // Filter for valid products (active entitlement with denied claim)
    const validProducts = productsWithReturnResolution.filter((product) =>
      product.entitlements?.some(
        (entitlement) =>
          entitlement.type === 'return_resolution' &&
          entitlement.status === 'active' &&
          (!entitlement.activeClaim || entitlement.activeClaim.status === 'denied'),
      ),
    )

    if (!isExtend && productsWithReturnResolution.length) {
      return {
        text: 'Return Items',
        emphasis: 'medium',
        isDisabled: !validProducts.length,
        tooltip: validProducts.length ? undefined : 'Not eligible for return',
        onClick: () => {
          onFileClaimClick({
            orderId: order.id,
            claimType: 'return_resolution',
            isMerchantOwnedIntake: merchantServicingSettings?.isMerchantOwnedIntake,
            lineItemIds: validProducts.map((product) => product.lineItemId),
          })
        },
      }
    }
  }

  const { dataCy, dataProps } = getDataProps(order)
  return (
    <Card
      data-cy={dataCy}
      dataProps={dataProps}
      primaryButtonProps={getHeaderPrimaryButtonProps()}
      preHeading={
        hasShippingEntitlement && (
          <ShippingProtectionBadgePreHeading
            order={order}
            isLoading={isLoading}
            status={shippingProtectionPrecheck?.contract?.status}
            shipmentDisplayDetails={shipmentDisplayDetails}
            hasActiveShipmentClaim={hasActiveShipmentClaim}
          />
        )
      }
      heading={getTitleHeading(order)}
      subHeading={getSubHeading(order)}
      {...((order.hasShipmentInfo || order.hasShippingProtection) && {
        primaryGroups: trackedGroups || undefined,
        secondaryGroups: untrackedGroup ? [untrackedGroup] : undefined,
      })}
      {...(!order.hasShipmentInfo &&
        !order.hasShippingProtection && {
          primaryGroups: [
            {
              listItems: generateListItems(order.untrackedProducts),
              itemsShowMoreText: 'View all items',
              itemsShowLessText: 'View less items',
            },
          ],
        })}
    />
  )
}
